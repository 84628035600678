// -----------------------------------------------------------------
.c-career_knowledge_share_item {
  --logo_opacity: 1;

  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 5;
  align-content: start;
  gap: 0;

  &__logo {
    display: block grid;
    place-items: center;
    min-block-size: 180px;
    padding-inline: 20px;
    background-color: #fff;
    filter: drop-shadow(0 3px 10px rgba(#000, 0.16));

    img {
      opacity: var(--logo_opacity);
      transition: opacity 0.6s $easeOutCubic;
      @include group-hover {
        --logo_opacity: 0.7;
      }
    }
  }

  &__company {
    inline-size: fit-content;
    margin-inline: auto;
    margin-block-start: 20px;
    margin-block-end: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
  }

  &__title {
    inline-size: fit-content;
    margin-inline: auto;
    margin-block-start: 16px;
    margin-block-end: 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
  }

  &__text {
    margin-block-start: 12px;
    margin-block-end: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.75;

    // [data-category="knowledge"] & {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    contain: paint;
    // }
  }

  &__btn {
    margin-block-start: 20px;
  }
}

// -----------------------------------------------------------------
.c-career_knowledge_side_nav_index {
  position: relative;

  transition: translate 0.6s $easeInOutCubic;
  @include mq-down(1240) {
    translate: 0;
    .is-side_nav_mobile & {
      translate: calc(100% + 10px);
    }
    .is-side_nav_mobile.is-side_nav_show_mobile & {
      translate: 0;
    }
  }

  &__inner {
    padding: 24px 16px 24px 20px;
    border-radius: 10px 0 0 10px;
    background-color: rgba(#fff, 0.6);
    // background-color: rgba(#f00, 0.26);
    // filter: drop-shadow(0 3px 10px rgba(#000, 0.16));
    transition: background-color 0.8s $easeOutQuart, filter 0.8s $easeOutQuart;

    @include mq-down(1240) {
      background-color: #fff;
      filter: drop-shadow(0 3px 10px rgba(#000, 0.16));
    }
  }

  &__title {
    display: block flex;
    align-items: baseline;
    column-gap: 10px;
    margin-block: 0;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: #1cb2c6;
    &::after {
      content: "";
      position: relative;
      inset-block-start: -1px;
      display: block flex;
      inline-size: 100%;
      block-size: 1px;
      border-block-start: 1px solid currentColor;
      opacity: 0.5;
    }
  }

  &__list {
    display: block grid;
    row-gap: 15px;
    margin-block-start: 18px;

    li {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.03em;

      &.is-active {
        a {
          color: #1cb2c6;
        }
      }
    }

    a {
      text-decoration: none;
      transition: color 0.4s $easeOutQuart;
      &:hover {
        color: #1cb2c6;
      }
      @include noOutlineWithoutFocus;
      @include hover {
        color: #1cb2c6;
      }
    }
  }

  &__close {
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 10px;
    transition: opacity 0.6s $easeOutQuart 0.4s;
    @include mq-up(1240) {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.8s $easeOutQuart;
    }
  }
}

// -----------------------------------------------------------------
.c-career_knowledge_side_nav_trigger {
  margin: 0;
  padding: 0;
  border: none;
  outline: 0;

  display: flex;
  align-items: center;
  column-gap: 12px;
  block-size: 32px;
  padding-inline-start: 16px;
  padding-inline-end: 10px;
  border-radius: 999vmax 0 0 999vmax;
  background-color: #000;
  filter: drop-shadow(0 3px 10px rgba(#000, 0.16));
  cursor: pointer;
  @include noOutlineWithoutFocus;
  translate: calc(100% + 10px);
  transition: translate 0.6s $easeInOutCubic;

  .is-side_nav_mobile & {
    translate: 0;
  }
  .is-side_nav_mobile.is-side_nav_show_mobile & {
    translate: calc(100% + 10px);
  }

  svg {
    display: flex;
    align-items: center;
    inline-size: 14px;
    aspect-ratio: 1;
    fill: #20b2c6;
  }

  span {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: #1cb2c6;
  }
}

// -----------------------------------------------------------------
.c-career_knowledge_side_nav_close {
  margin: 0;
  padding: 0;
  border: none;
  outline: 0;

  display: block grid;
  place-items: center;
  inline-size: 38px;
  aspect-ratio: 1;
  cursor: pointer;
  @include noOutlineWithoutFocus;

  svg {
    display: block grid;
    place-items: center;
    inline-size: 16px;
    fill: #aaa;
  }
}

// -----------------------------------------------------------------
.c-career_knowledge_case_questionnaire {
  inline-size: fit-content;
  margin-inline: auto;

  &__head {
    display: block grid;
    row-gap: 5px;
  }

  &__subtitle {
    inline-size: fit-content;
    margin-inline: auto;
    margin-block-end: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.625;

    &._taCenter {
      text-align: center;
    }
  }

  &__title {
    inline-size: fit-content;
    margin-inline: auto;
    margin-block-end: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.625;

    &._taCenter {
      text-align: center;
    }
  }

  &__figure {
    inline-size: fit-content;
    margin-inline: auto;
    margin-block-start: 20px;
  }

  &__caption {
    inline-size: fit-content;
    margin-inline: auto;
    margin-block-start: 20px;
    font-size: 11px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.04em;
    color: #666;
    margin-block-end: 0;

    &._taCenter {
      text-align: center;
    }
  }
}
