.c_btn__wrap {
  text-align: center;
  letter-spacing: -0.4em;

  &.block {
    > *:not(:first-child) {
      margin-top: 14px;
    }
  }

  &.alter {
    @include sp {
      display: flex;
      justify-content: center;
    }

    > * {
      &:not(:first-child) {
        margin-top: 14px;
        @include sp {
          margin-top: 0;
          margin-left: 15px;
        }
      }
    }
  }

  &.inline {
  }

  &.twist {
  }
}

.c_btn {
  @extend %button-chrome;

  &:hover {
    .__label {
      color: $color04;
    }
  }

  .__label {
    color: $color09;
    background-color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 21px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 3px;
    margin-left: 8px;
    letter-spacing: .04em;
    transition: color 300ms;
  }

  &.s100 {
    width: 100%;
    > span {
      min-height: 57px;
      @include ie {
        height: 57px;
      }
    }
  }

  &.s1 > span {
    min-width: 320px;
    min-height: 57px;
    @include ie {
      height: 57px;
    }
    @include sp {
      min-width: 280px;
    }
  }

  &.s2 > span {
    min-width: 320px;
    min-height: 72px;
    font-size: 1.25rem;
    @include ie {
      height: 72px;
    }
    @include sp {
      min-width: 280px;
    }
  }

  &.s3 > span {
    min-width: 400px;
    min-height: 65px;
    font-size: 1.25rem;
    @include ie {
      height: 65px;
    }
    @include sp {
      min-width: 320px;
      font-size: 1rem;
    }
  }

  &.c1 {
    > span {
      background-color: $color09;
    }
    &:hover > span {
      background-color: $color04;
    }
  }
  &.c2 {
    > span {
      background-color: $color11;
    }
    &:hover > span {
      opacity: 0.7;
    }
  }
  &.c3 {
    > span {
      border: 1px solid $color09;
      background-color: #fff;
      color: $color09;
    }
    &:hover > span {
      border: 1px solid $color04;
      background-color: $color04;
      color: #fff;
      i {
        filter: brightness(0) invert(1);
      }
    }
  }
  &.c4 {
    > span {
      position: relative;
      z-index: 0;
      background-image: linear-gradient(90deg, #67beca 0%, #8864b7 100%);
      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(90deg, #8864b7 0%, #67beca 100%);
        opacity: 0;
        transition: 300ms;
      }
    }

    &:hover > span {
      &::before {
        opacity: 1;
      }
    }
  }

  &.i1 > span i {
    // icon
  }

  &[target="_blank"] span::after,
  &._blank span::after {
    @include ico_blank;
    filter: brightness(0) invert(1);
  }

  &.no_icon {
    span::after {
      display: none;
    }
  }
}

.c_btn:not(.disabled):hover {
  @include pc {
    // opacity: .7;
  }
}

.c_btn.disabled {
  cursor: default !important;
  > span {
    // box-shadow: inset 0 -2px 0 0 $color02 !important;
    background-color: $color02 !important;
  }
}
