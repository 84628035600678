// -----------------------------------------------------------------
.c-career_text_basic {
  margin-block-end: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.125;

  @include mq-down(768) {
    &[data-fz-mobile="15"] {
      font-size: 15px;
    }
  }

  &[data-lh="1.875"] {
    line-height: 1.875;
  }

  &[data-lh="2.5"] {
    line-height: 2.5;
  }

  &._center {
    inline-size: fit-content;
    margin-inline: auto;
  }

  &._taCenter {
    text-align: center;
  }

  &[data-tac-mobile="true"] {
    @include mq-up(768) {
      text-align: center;
    }
  }

  &[data-tac-desktop="true"] {
    @include mq-up(768) {
      text-align: center;
    }
  }

  a {
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
    @include hover {
      text-decoration: none;
      color: currentColor;
    }
  }
}
