// -----------------------------------------------------------------
.p-career_knowledge_index {
  margin-block-end: 80px;
  padding-block-start: 60px;
  @include mq-up(768) {
    margin-block-end: 100px;
    padding-block-start: 110px;
  }

  // &__head {
  // }

  &__lead {
    margin-block-start: 30px;
  }

  &__body {
    margin-block-start: 60px;
    @include mq-up(768) {
      margin-block-start: 80px;
    }
  }

  &__cols {
    display: block grid;
    grid-template-columns: 1fr;
    column-gap: 40px;

    @include mq-up(540) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq-up(768) {
      grid-template-columns: repeat(3, 1fr);
    }

    // row-gap: 100px;
    // なぜか subgrid で、gap の解除がうまくできないので…
    > * {
      @include mq-down(540) {
        &:nth-child(n + 2) {
          margin-block-start: 60px;
        }
      }
      @include mq-up(540) {
        &:nth-child(n + 3) {
          margin-block-start: 60px;
        }
      }
      @include mq-up(768) {
        &:nth-child(n + 3) {
          margin-block-start: 0;
        }
        &:nth-child(n + 4) {
          margin-block-start: 100px;
        }
      }
    }

    &[data-cols="2"] {
      @include mq-up(768) {
        // grid-template-columns: repeat(2, unquote("min(calc(328/1024*100vw), 328px)"));
        grid-template-columns: repeat(2, 328px);
        justify-content: center;
      }
    }
  }

  &__bnr {
    max-inline-size: 1068px;
    margin-inline: auto;
    padding-block-start: 100px;

    .p_bnrArea {
      img[style] {
        width: auto !important;
      }
    }
  }
}

// -----------------------------------------------------------------
.p-career_knowledge_case_wrap {
  box-sizing: initial;
  max-inline-size: 900px;
  margin-inline: auto;
  margin-block-end: 80px;
  padding-inline: 20px;
  @include mq-up(768) {
    margin-block-end: 130px;
  }
}

// -----------------------------------------------------------------
.p-career_knowledge_case_hero {
  margin-block-end: 50px;
  padding-block-start: 60px;
  @include mq-up(768) {
    margin-block-end: 70px;
    padding-block-start: 110px;
  }

  // &__head {
  // }

  &__lead {
    margin-block-start: 30px;
  }

  &__body {
    margin-block-start: 60px;
  }

  &__info {
    display: block grid;
    align-items: center;
    gap: 20px;
    margin-block-start: 24px;
    margin-inline: -20px;
    padding: 25px 18px;
    background-color: #eee;
    @include mq-up(768) {
      grid-template-columns: 200px 1fr;
      padding: 30px 40px;
      margin-inline: 0;
    }

    dt {
      display: grid;
      place-items: center;
      inline-size: 200px;
      min-block-size: 130px;
      margin-inline: auto;
      background-color: #fff;
      filter: drop-shadow(0 3px 10px rgba(#000, 0.16));
      @include mq-up(768) {
        inline-size: 100%;
      }
    }

    dd {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.05em;
      line-height: 1.75;
    }
  }

  &__info_list {
    display: block grid;
    row-gap: 5px;

    li {
      display: block grid;
      grid-template-columns: auto 1fr;

      &::before {
        content: "";
        inline-size: 1lh;
        aspect-ratio: 1;
        border-radius: 999vmax;
        background-color: currentColor;
        scale: 0.5;
        // margin-inline: -0.1lh;
      }
    }
  }
}
// -----------------------------------------------------------------
.p-career_knowledge_case_detail {
  display: block grid;
  row-gap: 60px;
}

// -----------------------------------------------------------------
.p-career_knowledge_case_section {
  $_self: &;

  // &__head {
  // }

  &__body {
    display: block grid;
    row-gap: 30px;
    margin-block-start: 20px;

    #{$_self}__info_box {
      &:first-child {
        margin-block-start: 10px;
      }
    }
  }

  &__group {
    display: block grid;
    row-gap: 30px;

    & + & {
      margin-block-start: 20px;
    }
  }

  &__image {
    inline-size: fit-content;
    margin-inline: auto;
    margin-block-start: 10px;
  }

  &__dl {
    display: block grid;
    row-gap: 5px;
    font-size: 16px;
    font-weight: 500;

    dt {
      font-weight: 700;
    }
  }

  &__info_box {
    padding: 25px 20px;
    background-color: #f5f5f5;
    @include mq-up(768) {
      padding: 25px 40px;
    }

    div {
      font-size: 16px;
      font-weight: 500;
      // @include mq-up(768) {
      //   display: block flex;
      // }

      dt {
        display: inline;
        // @include mq-up(768) {
        //   flex-shrink: 0;
        // }
      }
      dd {
        display: inline;
      }
    }
  }

  // &__questionnaire {
  // }
}

// -----------------------------------------------------------------
.p-career_knowledge_case_note {
  margin-block-start: 40px;
}

// -----------------------------------------------------------------
.p-career_knowledge_case_side_nav {
  position: fixed;
  inset-block-start: 50%;
  inset-inline-end: 0;
  z-index: 1;

  inline-size: 212px;
  translate: 0 -50%;

  .is-side_nav_scrolled_desktop & {
    position: absolute;
    inset-block-start: auto;
    inset-block-end: 0;
    translate: 0 0;
  }

  .is-side_nav_mobile & {
    pointer-events: none;
  }
  .is-side_nav_mobile.is-side_nav_show_mobile & {
    pointer-events: auto;
  }

  &__trigger {
    position: fixed;
    inset-block-end: 25px;
    inset-inline-end: 0;
    z-index: 2;
  }
}
