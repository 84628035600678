@mixin pc {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin pc_l {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin tb {
  @media (max-width: $breakpoint_tb) {
    @content;
  }
}

@mixin sp {
  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin dpr2 {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}

@mixin en {
  [lang="en"] & {
    @content;
  }
}

@mixin ie {
  @at-root _:-ms-lang(x),
    & {
    @content;
  }
}

@mixin inline-block-list($letter-spacing: normal, $display: inline-block) {
  letter-spacing: -0.4em;
  > * {
    display: $display;
    letter-spacing: $letter-spacing;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin placeholder {
  &:placeholder-shown {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin input-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  padding: 0;
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  line-height: inherit;
  letter-spacing: inherit;
  box-sizing: inherit;
  border-radius: 0;
  //-webkit-user-select: none;
  //-moz-user-select: none;
  //-ms-user-select: none;
}

@mixin margin-root() {
  > * {
    margin-bottom: 20px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

%button-chrome {
  @include input-reset;
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding: 0;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  text-decoration: none !important;
  letter-spacing: 0.2em;
  transition: 300ms;
  cursor: pointer;
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.375em 1.5em;
    border-radius: 5px;
    line-height: 1.3125;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    transition: 300ms;
    overflow: hidden;
    > i {
      font-size: 0;
    }
  }
}

@mixin ico_blank {
  content: "別ウインドウで開く";
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  background-image: url(../img/ico_blank01.svg);
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 0;
  // transform: translateY(-1px);
}

@mixin font_size01 {
  line-height: 1.857142;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  @include sp {
    line-height: 1.846153;
    font-size: 0.8125rem;
  }
}

// -----------------------------------------------------------------
@mixin mq-up($value) {
  @media (min-width: ($value * 1px)) {
    @content;
  }
}

@mixin mq-down($value) {
  // @media (max-width: ($value * 1px)) {
  @media not all and (min-width: ($value * 1px)) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) {
    &:where(:any-link, :enabled, summary):hover {
      @content;
    }
  }
}

@mixin group-hover {
  @media (hover: hover) {
    &:is(:where(:any-link, :enabled, summary):hover *) {
      @content;
    }
  }
}

@mixin noOutline {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

@mixin noOutlineWithoutFocus {
  &:focus:not(:focus-visible) {
    outline: none;
  }
  -webkit-tap-highlight-color: transparent;
}

@mixin objFitCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
