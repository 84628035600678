.u_pcOnly {
  display: block !important;
  @include sp {
    display: none !important;
  }
}

.u_spOnly {
  display: none !important;
  @include sp {
    display: block !important;
  }
}


.u_lab_spOnly {
  display: inline-block;
  @include pc {
    display: none;
  }
}

.u_lab_pcOnly {
  display: none;
  @include pc {
    display: inline-block;
  }
}