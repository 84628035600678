.c_grid {
  display: flex;
  flex-wrap: wrap;
  $gap: 50px;
  @include sp {
    display: block;
  }

  .c_grid__item {
    width: 100%;
  }

  @for $i from 2 through 4 {
    &.col#{$i} {
      .c_grid__item {
        width: calc((100% - #{$gap * ($i - 1)}) / #{$i});
        margin-left: $gap;
        &:nth-child(#{$i}n+1) { margin-left: 0; }
        &:nth-child(n+#{$i+1}) { margin-top: $gap; }
        @include sp {
          display: block;
          width: 100%;
          margin-left: 0;
          margin-top: 20px;
          &:first-child { margin-top: 0; }
        }
      }
    }
  }

  &.col3 {
    .c_grid__item {
      width: 320px;
      margin-left: 30px;
      @include sp {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  &.col4 {
    .c_grid__item {
      width: 232px;
      margin-left: 30px;
      @include sp {
        width: 100%;
        margin-left: 0;
      }
    }

    .c_grid__item--container1 {
      display: flex;
      justify-content: center;
      position: relative;
      width: 494px;
      margin-left: 30px;
      @include sp {
        display: block;
        width: auto;
        margin-left: 0;
        margin-top: 20px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 100px;
        left: 0;
        width: 100%;
        height: calc(100% - 100px);
        border: 6px solid #B3DEE4;
        background-color: #ffffff;
        @include sp {
          // display: none;
        }
      }

      .c_grid__item + .c_grid__item {
        @include pc {
          margin-left: 0px;
        }
      }
    }
  }

  &.c_grid--center {
    justify-content: center;
  }
}
