$breakpoint: 767px;
$breakpoint_tb: 900px;

$base_width: 1020px;

$header_height_pc : 64px;
$header_height_sp : 60px;
$z-index-header: 5000;

$color01: #5887F9;
$color02: #97999B;
$color03: #F5F5ED;
$color04: #9060BC;
$color05: #C69606;
$color06: #6AB700;
$color07: #1CB2C6;
$color08: #9A7CB6;
$color09: #67BECA;
$color10: #E3B425;
$color11: #ABCD03;
$gradient01: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, $color11 1%, $color11 24%, rgba(255,255,255,0) 24%, rgba(255,255,255,0) 26%, $color10 26%, $color10 49%, rgba(255,255,255,0) 49%, rgba(255,255,255,0) 51%, $color09 51%, $color09 74%, rgba(255,255,255,0) 74%, rgba(255,255,255,0) 76%, $color08 76%, $color08 99%,rgba(255,255,255,0) 99%,rgba(255,255,255,0) 100%);
$gradient02: linear-gradient(to right, $color11 0%, $color11 32%, rgba(255,255,255,0) 32%, rgba(255,255,255,0) 34%, $color10 34%, $color10 66%, rgba(255,255,255,0) 66%, rgba(255,255,255,0) 68%, $color09 68%, $color09 100%);

$sky_blue: $color09 !default;
$pale_yellow: #F5F5ED !default;
$light_black: #444 !default;
$gray: #6F6F6F !default;
$light_gray: #E5E5E5 !default;
$btn_color: $color07 !default;
$table_bg_color: $color07 !default;
$table_txt_color: $color07 !default;
$btn_hover_color: $color04 !default;
$ttl_color: #222 !default;

$letter_spacing_default: .07em !default;
$letter_spacing_small: .05em !default;
$lab_lh_default: 1.75 !default;
$lab_lh_large: 2 !default;