@for $i from 10 through 50 {
  .u_fz#{$i} { font-size: $i * .0625rem; }
}
@for $i from 10 through 50 {
  .u_fz#{$i}sp {
    @include sp {
      font-size: $i * .0625rem;
    }
  }
}

.u_fwN { font-weight: normal;}
.u_fwB { font-weight: bold;}
