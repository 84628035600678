// -----------------------------------------------------------------
.c-view_all_target {
  --block_size: 90px;

  position: relative;
  block-size: var(--block_size);
  contain: paint;

  &[data-block-size="150"] {
    --block_size: 150px;
  }

  [data-view-all-only-mobile="true"] & {
    @include mq-up(768) {
      --block_size: auto;
    }
  }

  &::after {
    content: "";
    position: absolute;
    inset-block-end: 0;
    inline-size: 100%;
    block-size: 1.5lh;
    background: linear-gradient(transparent, #fff);
    pointer-events: none;
    transition: opacity 0.6s $easeInOutQuart;
    .is-view_all & {
      opacity: 0;
    }

    [data-view-all-only-mobile="true"] & {
      @include mq-up(768) {
        display: none;
      }
    }
  }
}

// -----------------------------------------------------------------
.c-view_all {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  margin-inline: auto;
  font-family: "Roboto", sans-serif;
  font-family: 16px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  transition: opacity 0.6s $easeInOutCubic;

  [data-view-all-only-mobile="true"] & {
    @include mq-up(768) {
      display: none;
    }
  }

  .is-view_all & {
    opacity: 0;
    pointer-events: none;
  }

  svg {
    display: grid;
    place-items: center;
    inline-size: 12px;
    aspect-ratio: 1;
    fill: #67beca;
    rotate: 90deg;
  }
}
