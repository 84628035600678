// -----------------------------------------------------------------
.p-career_message_hero {
  padding-block-start: 60px;
  @include mq-up(768) {
    padding-block-start: 110px;
  }

  // &__head {
  // }

  &__body {
    margin-block-start: 40px;
    @include mq-up(768) {
      margin-block-start: 60px;
    }
  }

  &__image {
    position: relative;
    max-block-size: 448px;
    margin-inline: -20px;
    contain: paint;
    img {
      @include objFitCover;
      object-position: right 3%;
    }
    @include mq-up(768) {
      margin-inline: 0;
    }
  }

  &__info {
    position: absolute;
    inset-block-end: 0;
    display: block grid;
    row-gap: 8px;
    inline-size: 100%;
    padding-block: 15px;
    padding-inline: 30px;
    color: #fff;
    background-color: rgba(#000, 0.3);
    @include mq-up(768) {
      inset-block-end: 80px;
      inline-size: auto;
      padding-block: 20px;
      padding-inline-end: 48px;
    }

    dt {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.25;
      @include mq-up(768) {
        font-size: 28px;
      }
    }

    dd {
      font-size: 13px;
      line-height: 1.5;
    }
  }

  &__profile {
    display: block grid;
    row-gap: 10px;
    margin-block-start: 30px;

    dt {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.25;
      color: #999;

      span {
        font-weight: 400;
      }
    }

    dd {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.02em;
      line-height: 1.8;
    }
  }
}

// -----------------------------------------------------------------
.p-career_message_section {
  display: block grid;
  row-gap: 30px;
  margin-block-start: 50px;
  @include mq-up(768) {
    row-gap: 40px;
    margin-block-start: 70px;
  }
}

// -----------------------------------------------------------------
.p-career_message_cta {
  display: block grid;
  row-gap: 60px;
  margin-inline: -20px;
  margin-block-start: 50px;
  margin-block-end: 80px;
  @include mq-up(768) {
    row-gap: 60px;
    margin-inline: 0;
    margin-block-start: 80px;
    margin-block-end: 100px;
  }
}
