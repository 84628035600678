.p-corporate-wrapper {
  font-size: 0;

  img {
    width: 100%;
  }

  .__links {
    position: absolute;
    display: block;
    // background-color: rgba(255, 0, 0, .5);

    &.__ov {
      opacity: 0.2;
      filter: brightness(130%);
      transition: background-color 300ms;

      &:hover {
        background-color: #67BECA;
      }
    }
  }

  &__page1 {
    --width: 2732;
    --height: 19183;
    position: relative;

    .__link1 {
      top: 0;
      left: 0;
      width: calc(516/var(--width) * 100%);
      height: calc(128/var(--height) * 100%);
    }

    .__link2 {
      top: 0;
      left: calc(1016/var(--width) * 100%);
      width: calc(234/var(--width) * 100%);
      height: calc(128/var(--height) * 100%);
    }

    .__link3 {
      top: calc(5903/var(--height) * 100%);
      left: calc(974/var(--width) * 100%);
      width: calc(786/var(--width) * 100%);
      height: calc(146/var(--height) * 100%);
    }

    .__link4 {
      top: calc(9650/var(--height) * 100%);
      left: calc(300/var(--width) * 100%);
      width: calc(656/var(--width) * 100%);
      height: calc(90/var(--height) * 100%);
    }

    .__link5 {
      top: calc(9650/var(--height) * 100%);
      left: calc(1038/var(--width) * 100%);
      width: calc(656/var(--width) * 100%);
      height: calc(90/var(--height) * 100%);
    }

    .__link6 {
      top: calc(9650/var(--height) * 100%);
      left: calc(1776/var(--width) * 100%);
      width: calc(656/var(--width) * 100%);
      height: calc(90/var(--height) * 100%);
    }

    .__link7 {
      bottom: calc(233/var(--height) * 100%);
      left: calc(300/var(--width) * 100%);
      width: calc(406/var(--width) * 100%);
      height: calc(136/var(--height) * 100%);
    }

    .__link8 {
      bottom: calc(70/var(--height) * 100%);
      left: calc(300/var(--width) * 100%);
      width: calc(202/var(--width) * 100%);
      height: calc(44/var(--height) * 100%);
    }

    .__link9 {
      bottom: calc(70/var(--height) * 100%);
      left: calc(557/var(--width) * 100%);
      width: calc(304/var(--width) * 100%);
      height: calc(44/var(--height) * 100%);
    }

    .__link10 {
      bottom: calc(70/var(--height) * 100%);
      left: calc(916/var(--width) * 100%);
      width: calc(304/var(--width) * 100%);
      height: calc(44/var(--height) * 100%);
    }

    .__link11 {
      bottom: calc(190/var(--height) * 100%);
      left: calc(2496/var(--width) * 100%);
      width: calc(120/var(--width) * 100%);
      height: calc(142/var(--height) * 100%);
    }

    .__link12 {
      bottom: calc(596/var(--height) * 100%);
      left: calc(974/var(--width) * 100%);
      width: calc(786/var(--width) * 100%);
      height: calc(147/var(--height) * 100%);
    }
  }

  &__page2 {
    --width: 2732;
    --height: 18805;
    position: relative;

    .__link1 {
      top: 0;
      left: 0;
      width: calc(516/var(--width) * 100%);
      height: calc(128/var(--height) * 100%);
    }

    .__link2 {
      top: 0;
      left: calc(1016/var(--width) * 100%);
      width: calc(234/var(--width) * 100%);
      height: calc(128/var(--height) * 100%);
    }

    .__link3 {
      top: calc(5931/var(--height) * 100%);
      left: calc(974/var(--width) * 100%);
      width: calc(786/var(--width) * 100%);
      height: calc(146/var(--height) * 100%);
    }

    .__link4 {
      top: calc(9242/var(--height) * 100%);
      left: calc(1314/var(--width) * 100%);
      width: calc(494/var(--width) * 100%);
      height: calc(90/var(--height) * 100%);
    }

    .__link7 {
      bottom: calc(233/var(--height) * 100%);
      left: calc(300/var(--width) * 100%);
      width: calc(406/var(--width) * 100%);
      height: calc(136/var(--height) * 100%);
    }

    .__link8 {
      bottom: calc(70/var(--height) * 100%);
      left: calc(300/var(--width) * 100%);
      width: calc(202/var(--width) * 100%);
      height: calc(44/var(--height) * 100%);
    }

    .__link9 {
      bottom: calc(70/var(--height) * 100%);
      left: calc(557/var(--width) * 100%);
      width: calc(304/var(--width) * 100%);
      height: calc(44/var(--height) * 100%);
    }

    .__link10 {
      bottom: calc(70/var(--height) * 100%);
      left: calc(916/var(--width) * 100%);
      width: calc(304/var(--width) * 100%);
      height: calc(44/var(--height) * 100%);
    }

    .__link11 {
      bottom: calc(190/var(--height) * 100%);
      left: calc(2496/var(--width) * 100%);
      width: calc(120/var(--width) * 100%);
      height: calc(142/var(--height) * 100%);
    }

    .__link12 {
      bottom: calc(596/var(--height) * 100%);
      left: calc(974/var(--width) * 100%);
      width: calc(786/var(--width) * 100%);
      height: calc(147/var(--height) * 100%);
    }
  }
}

.p-corporate {

  &-container {
    color: #222;

    img {
      max-width: 100%;
    }
  }

  &__hero {

    &__ttl {
      margin: 0;

      img {
        width: 100%;
      }
    }
  }

  &__section {
    position: relative;
    padding: 60px 0 80px;
    @include sp {
      padding: 40px 0 60px;
    }

    &__inner {
      max-width: 1070px;
      margin: 0 auto;
      @include sp {
        padding: 0 20px;
      }
    }

    &__ttl {
      margin-top: 0;
      margin-bottom: 60px;
      line-height: 1.75;
      text-align: center;
      @include sp {
        margin-bottom: 40px;
      }

      > span {
        display: inline-block;
        position: relative;
        padding: 0.5em 20px;
        font-size: 2.25rem;
        @include sp {
          padding: 1em 10px;
          font-size: 1.5rem;
        }

        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 50%;
          bottom: 0;
          width: 250px;
          height: 4px;
          background-image: $gradient01;
          transform: translateX(-50%);
          @include sp {
            width: 160px;
            height: 3px;
          }
        }
      }

      .__smaller {
        font-size: 1.75rem;
        @include sp {
          font-size: 1.25rem;
        }
      }
    }
  }

  &__intro {
    padding-bottom: 140px;
    background-color: #F5F5ED;
    @include sp {
      padding-bottom: 110px;
    }

    &__list {
      font-size: 1.375rem;
      font-weight: bold;
      @include sp {
        font-size: 1rem;
      }

      &__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__about {
    padding: 0;

    .p-corporate__section__ttl {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: translateY(-50%);

      span {
        width: 600px;
        padding: 24px 20px;
        background-color: #444444;
        color: #fff;
        @include sp {
          width: 250px;
        }

        &::before {
          bottom: 16px;
        }
      }
    }

    &__pic {
      height: 600px;
      @include sp {
        height: 272px;
      }

      img {
        width: 100%;
        height: 100%;
        object-position: 100% 10%;
        object-fit: cover;
      }
    }

    &__cont {
      position: relative;
      margin-top: -163px;
      padding: 70px 78px 62px;
      background-color: #F5F5ED;
      @include sp {
        margin: 0 -20px;
        padding: 40px 20px;
      }
    }

    &__sector {

      &:not(:last-child) {
        margin-bottom: 80px;
        @include sp {
          margin-bottom: 60px;
        }
      }
    }

    &__txt1 {
      margin-bottom: 70px;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      @include sp {
        margin-bottom: 40px;
        font-size: 1.125rem;
      }
    }

    &__txt2 {
      font-size: 1.375rem;
      font-weight: bold;
      text-align: center;
      @include sp {
        font-size: 1.125rem;
      }
    }

    &__txt3 {
      margin: 30px 0;
      font-size: 1rem;
      @include sp {
        font-size: .875rem;
      }
    }

    &__obj {
      margin-bottom: 30px;
      text-align: center;
    }

    .c_btn span {
      @include sp {
        min-height: 50px;
        font-size: 1rem !important;
        letter-spacing: 0.05em;
      }
    }
  }

  &__obtain {

    &__list {
      display: flex;
      @include sp {
        display: block;
      }

      &__item {
        font-size: 16px;
        @include pc {
          width: calc((100% - 40px * 2) / 3);
          &:not(:nth-child(3n)) {
            margin-right: 40px;
          }
        }
        @include sp {
          font-size: 0.875rem;
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }

        &__pic {
          margin-bottom: 10px;
        }

        &__ttl {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          line-height: 1;

          .__num {
            margin-right: 10px;
            color: rgba(#1CB2C6, .25);
            // font-family: 'Muli', sans-serif;
            font-size: 5.625rem;
            font-weight: bold;
            transform: scaleX(.75);
            @include sp {
              font-size: 4.375rem;
            }
          }

          .__txt {
            font-size: 1.375rem;
            font-weight: bold;
            @include sp {
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }

  &__case {
    background-color: #F5F5ED;
    background-image: url(../img/bg_pattern01.png);
    background-repeat: repeat-x;
    background-position: center 330px;
    -webkit-animation: sectionBg 30000ms linear infinite;
    animation: sectionBg 30000ms linear infinite;
    @include sp {
      background-image: none;
    }

    &__lede {
      margin-bottom: 80px;
      font-size: 1rem;
      text-align: center;
    }

    &__list {
      display: flex;
      @include sp {
        display: block;
      }

      &__item {
        display: flex;
        flex-direction: column;
        @include pc {
          width: calc((100% - 40px * 2) / 3);
          &:not(:nth-child(3n)) {
            margin-right: 40px;
          }
        }
        @include sp {
          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }

        &__top {
          flex: 1 1 auto;
          margin-bottom: 24px;
        }

        &__pic {
          margin-bottom: 8px;
        }

        &__name {
          position: relative;
          margin-bottom: 16px;
          padding-bottom: 16px;
          text-align: center;

          &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 40px;
            height: 2px;
            margin: auto;
            background-color: #67BECA;
          }
        }

        &__ttl {
          margin-bottom: 10px;
          font-size: 1.375rem;
          font-weight: bold;
          text-align: center;
        }

        &__txt {
          font-size: 1rem;
        }

        &__btn {
          flex: 0 0 auto;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 45px;
            border: 1px solid #D0D0D0;
            font-size: 1rem;
            text-decoration: none;
            transition: background-color 300ms, color 300ms;

            &:hover {
              background-color: #000;
              color: #fff;
            }
          }
        }
      }
    }
  }

  &__scene {

    &__lede {
      margin-bottom: 30px;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      @include pc {
        margin-bottom: 60px;
        font-size: 1.25rem;
      }
    }

    &__list {
      display: flex;
      @include sp {
        flex-direction: column;
        align-items: center;
      }

      &__item {
        display: flex;
        flex-direction: column;
        @include pc {
          width: calc((100% - 40px * 4) / 5);
          &:not(:nth-child(5n)) {
            margin-right: 40px;
          }
        }
        @include sp {
          width: 191px;
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }

        &__pic {
          margin-bottom: 20px;
        }

        &__txt {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 3.5em;
          line-height: 1.75;
          font-size: 1.125rem;
          font-weight: bold;
          text-align: center;
          @include sp {
            height: auto;
          }
        }
      }
    }
  }

  &__reason {
    background-color: #F5F5ED;
    @include pc {
      background-image: url(../img/bg_pattern01.png);
      background-repeat: repeat-x;
      background-position: center 320px;
      -webkit-animation: sectionBg 30000ms linear infinite;
      animation: sectionBg 30000ms linear infinite;
    }

    &__list {
      display: flex;
      @include sp {
        display: block;
        margin: 0 -20px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        @include pc {
          width: calc((100% - 40px * 2) / 3);
          &:not(:nth-child(3n)) {
            margin-right: 40px;
          }
        }
        @include sp {
          padding: 0 20px;
          background-image: url(../img/bg_pattern01.png);
          background-repeat: repeat-x;
          background-position: center 15px;
          -webkit-animation: sectionBg 30000ms linear infinite;
          animation: sectionBg 30000ms linear infinite;
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }

        &__pic {
          margin-bottom: 30px;
          text-align: center;

          img {
            @include sp {
              width: 260px;
            }
          }
        }

        &__txt {
          font-size: 1rem;

          .c_markList {
            font-size: .8125rem;
          }
        }


      }
    }
  }

  &__table__wrap {
    overflow: auto;

    .p-corporate__table {
      @include sp {
        width: 480px;
      }
    }
  }

  &__table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 8px;
    @include sp {
      border-spacing: 6px;
    }

    col:nth-child(1) {
      width: 28%;
    }
    col:nth-child(2) {
      width: 28%;
    }
    col:nth-child(3) {
      width: 44%;
    }

    th, td {
      border-radius: 5px;
      line-height: 1.5;
      font-weight: bold;
      text-align: center;
    }

    th {
      height: 80px;
      font-size: 1.375rem;
      color: #fff;
      @include sp {
        height: 60px;
        font-size: 1rem;
      }

      &.__lv1 { background-color: rgba(#97999B, .5);}
      &.__lv2 { background-color: #97999B;}
      &.__lv3 { background-color: #67BECA;}
    }

    td {
      padding: 20px 16px;
      font-size: 1rem;
      @include sp {
        padding: 12px 10px;
        font-size: 0.6875rem;
      }

      &.__lv1 { background-color: #EEEEEE;}
      &.__lv3 { background-color: #D9ECEF;}

      .__hdg {
        margin-bottom: 0.25em;
        font-size: 1.125rem;
        @include sp {
          font-size: 0.8125rem;
        }
      }

      .__smaller {
        font-size: .875rem;
        @include sp {
          font-size: 0.625rem;
        }
      }

      .c_markList {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        font-size: .875rem;
        font-weight: normal;
        text-align: left;
        @include sp {
          font-size: .625rem;
        }
      }
    }
  }

  &__flow {
    background-color: #F5F5ED;
    @include pc {
      background-image: url(../img/bg_pattern01.png);
      background-repeat: repeat-x;
      background-position: center 240px;
      -webkit-animation: sectionBg 30000ms linear infinite;
      animation: sectionBg 30000ms linear infinite;
    }

    &__list {
      display: flex;
      @include sp {
        display: block;
        margin: 0 -20px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        @include pc {
          width: calc((100% - 40px * 2) / 3);
          &:not(:nth-child(3n)) {
            margin-right: 40px;
          }
        }
        @include sp {
          padding: 0 20px;
          background-image: url(../img/bg_pattern01.png);
          background-repeat: repeat-x;
          background-position: center 0px;
          background-size: auto 236px;
          -webkit-animation: sectionBg 30000ms linear infinite;
          animation: sectionBg 30000ms linear infinite;
          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }

        &__pic {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 270px;
          height: 270px;
          margin: 0 auto;
          border-radius: 50%;
          background-color: #fff;
          @include sp {
            width: 200px;
            height: 200px;
          }

          img {
            @include sp {
              width: 40%;
            }
          }
        }

        &__txt {
          margin-top: -2.1875rem;

          .__num {
            margin: 0;
            line-height: 1;
            color: #58B0C3;
            font-size: 5.625rem;
            font-weight: bold;
            text-align: center;
            transform: scaleX(0.75);
            @include sp {
              font-size: 4.375rem;
            }
          }

          .__hdg {
            margin-bottom: 0.25em;
            font-size: 1.375rem;
            font-weight: bold;
            text-align: center;
            @include sp {
              font-size: 1.25rem;
            }
          }
        }
      }
    }
  }

  &__media {

    &__list {
      display: flex;
      align-items: center;
      justify-content: center;
      @include sp {
        flex-direction: column;
      }

      &__item {
        display: flex;
        flex-direction: column;
        @include pc {
          &:not(:nth-child(4n)) {
            margin-right: 80px;
          }
        }
        @include sp {
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  &__contact {
    padding-top: 80px;
    background-color: #EEEEEE;
    background-image: url(../img/bg_pattern01.png);
    background-repeat: repeat-x;
    background-position: center 300px;
    -webkit-animation: sectionBg 30000ms linear infinite;
    animation: sectionBg 30000ms linear infinite;
    @include sp {
      padding-top: 60px;
    }

    &__logo {
      width: 345px;
      margin: 0 auto 99px;
      @include sp {
        width: 260px;
        margin-bottom: 80px;
      }
    }

    &__box {
      padding: 40px;
      background-color: #fff;
      text-align: center;
      @include sp {
        padding: 30px 15px;
        font-size: 0.75rem;
      }
    }

    &__btn {
      margin-bottom: 30px;
      @include sp {
        margin-bottom: 20px;
      }

      span {
        width: 500px;
        height: 70px;
        font-size: 1.5rem;
        @include sp {
          width: auto;
          height: 50px;
          font-size: 1rem;
        }

        img {
          width: 36px;
          margin-right: 20px;
          @include sp {
            width: 21px;
            margin-right: 10px;
          }
        }

        &::after {
          display: none !important;
        }
      }
    }
  }





}

.scroll-hint-icon {
  line-height: 1.5;
}
