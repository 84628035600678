// -----------------------------------------------------------------
.c-career_hdg_variable {
  margin-block: 0;
  font-weight: 700;
  line-height: 1.75;
  color: #000;

  @include mq-down(768) {
    font-size: 24px;

    &[data-fz-mobile="18"] {
      font-size: 18px;
    }

    &[data-fz-mobile="20"] {
      font-size: 20px;
    }

    &[data-fz-mobile="22"] {
      font-size: 22px;
    }
  }

  @include mq-up(768) {
    font-size: 32px;

    &[data-fz-desktop="18"] {
      font-size: 18px;
    }

    &[data-fz-desktop="20"] {
      font-size: 20px;
    }

    &[data-fz-desktop="22"] {
      font-size: 22px;
    }

    &[data-fz-desktop="24"] {
      font-size: 24px;
    }

    &[data-fz-desktop="26"] {
      font-size: 26px;
    }

    &[data-fz-desktop="28"] {
      font-size: 28px;
    }

    &[data-fz-desktop="36"] {
      font-size: 36px;
    }
  }

  &[data-lh="1.6"] {
    line-height: 1.6;
  }

  &[data-lh="1.8"] {
    line-height: 1.8;
  }

  &[data-lh="1.875"] {
    line-height: 1.875;
  }

  &._center {
    inline-size: fit-content;
    margin-inline: auto;
  }

  &._taCenter {
    text-align: center;
  }
}

// -----------------------------------------------------------------
.c-career_hdg_group {
  display: block grid;
  row-gap: 24px;

  &__title {
    display: block grid;
    row-gap: 12px;
  }
}
