
.p_termsWrapper {
  padding: 72px 0;
}

.p_terms {
  line-height: calc(30/14);
  letter-spacing: $letter_spacing_small;
  padding: 0 20px;
  max-width: calc(860px + 40px);
  margin: 0 auto;

  &__sec_group {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  &__sec_title {
    margin: 0;
    font-size: 20px;
    border-bottom: 1px solid #D0D0D0;
    padding-bottom: 10px;
    color: $ttl_color;
    letter-spacing: $letter_spacing_default;
  }

  &__sec_item {
    p {
      margin: 0;
    }

    > p + ol {
      padding-left: 20px;
      margin-top: 10px;
    }

    > ol {
      counter-reset: li;
    }

    > ol li {
      display: flex;
      &::before {
        display: inline-block;
        content: counter(li)".";
        counter-increment: li;
        width: 20px;
        flex-shrink: 0;
      }
    }

    > ol li ol {
      counter-reset: sub_li;
      margin: 30px 0;
    }

    > ol li ol li {
      display: flex;
      &::before {
        display: inline-block;
        content: "("counter(sub_li)")";
        counter-increment: sub_li;
        width: 30px;
        flex-shrink: 0;
      }
    }
  }

  &__sec_item_title {
    margin: 25px 0 10px;
    line-height: 1.5;
    font-size: 16px;
    color: $ttl_color;
    letter-spacing: $letter_spacing_default;
  }

  &__date {
    margin-top: 40px;
  }

  &__end {
    text-align: right;
    font-size: 18px;
  }
}