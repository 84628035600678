
.c_form_label {
  font-size: 12px;
  font-weight: bold;
  color: #1CB2C6;
  border: 1px solid #1CB2C6;
  border-radius: 2px;
  width: 40px;
  height: 20px;
  line-height: 18px;
  display: inline-block;
  text-align: center;
  margin: 0;
  text-indent: 0;
}

.p_formWrapper {
  padding: 72px 0;
}

.p_form {
  padding: 0 20px;
  max-width: calc(860px + 40px);
  margin: 0 auto;

  &__first_txt_area {
    text-align: center;
    font-size: 16px;
    padding-bottom: 40px;
  }

  &__group {
    margin-top: 40px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include pc {
      flex-direction: row;
    }

    & + & {
      margin-top: 40px;
    }

    #err {
      color: red;
    }
  }

  &__item_ttl {
    font-weight: bold;
    margin: 0;
    flex-shrink: 0;
    line-height: 2;
    width: 100%;

    @include pc {
      margin-top: 8px;
      margin-right: 17px;
      max-width: 330px;
      display: flex;
      justify-content: space-between;
    }

    &.--confirm {
      margin-top: 0;
    }

    &.--radio {
      @include pc {
        max-width: 342px;
      }
    }

    &.--num {
      text-indent: -28px;
      padding-left: 28px;
    }

    &.--thin {
      @include pc {
        margin-top: 2px;
      }
    }
  }

  &__item_detail {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    margin-top: 8px;

    @include pc {
      margin-top: 0;
    }
  }

  &__item_label {
    flex-shrink: 0;
    margin-left: 8px;

    @include pc {
      margin-left: 0;
      margin-top: 4px;
    }
  }

  //radio, checkbox
  &__input_group {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &.--row {
      gap: 16px;
      @include pc {
        flex-direction: row;
        gap: 44px;
      }
    }
  }

  &__input_wrap {
    flex-grow: 1;

    textarea {
      display: block;
    }
  }

  &__input_note {
    margin-top: 20px;
    font-size: 12px;
  }

  //type="text"
  &__input {
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    background-color: #F4F4F4;
    height: 45px;
    padding: 8px 16px;
    width: 100%;

    &.--textarea {
      height: 130px;
    }
  }

  &__radio {
    input[type="radio"]+label {
      padding-left: 30px;

      &:before {
        width: 22px;
        height: 22px;
        top: -2px;
      }

      &:after {
        top: 2px;
        left: 4px;
        width: 14px;
        height: 14px;
      }
    }

    &.--check {
      input[type="checkbox"] {
        position: relative;
        top: 9px;
        left: 3px;
        width: 0;
        height: 0;
        visibility: hidden;
      }

      input[type="checkbox"]+label {
        padding-left: 30px;
        position: relative;

        &:before {
          position: absolute;
          content: "";
          display: inline-block;
          width: 22px;
          height: 22px;
          border-radius: 5px;
          border: 1px solid #DBDBDB;
          top: -3px;
          left: 0;
        }
      }

      input[type="checkbox"]:checked+label:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 6px;
        width: 12px;
        height: 6px;
        border-left: 2px solid #949494;
        border-bottom: 2px solid #949494;
        transform: rotate(-45deg);
      }
    }
  }

  &__radio_txt {
    span {
      font-size: 12px;
      color: #666;
      display: block;
      padding-left: 30px;
    }
  }

  &__check_ttl {
    margin-bottom: 8px;
  }

  &__btn_area {
    margin: 70px auto 42px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;

    button {
      //user agentの初期化
      font-family: inherit;
    }
  }

  &__btn {
    max-width: 298px;
    width: 100%;
    border: none;
    height: 57px;
    line-height: 57px;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .15em;
    color: #fff;
    background-color: #444;
    border-radius: 8px;
    box-shadow: none;
    position: relative;
    transition: background-color .3s;

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border-top: solid 2px #fff;
      border-right: solid 2px #fff;
      position: absolute;
      right: 22px;
      top: 24px;
      transform: rotate(45deg);
    }

    &:hover {
      background-color: rgba(#444, .7);
    }
  }

  &__link {
    img {
      margin: 0 4px;
    }
  }
}