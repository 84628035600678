.p_fixed_button {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444;
  z-index: 100;
  transition: all .3s;
  padding: 8px 16px;

  @include sp {
    height: 60px;
  }

  a,
  .c_btn > span {
    width: 100%;
    max-width: 388px;
    height: 60px;
    font-size: 20px;

    @include sp {
      height: 40px;
      font-size: 18px;
      max-width: 333px;
      margin: 0 auto;
    }
  }

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }
}
