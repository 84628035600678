@for $i from 0 through 20 {
  .u_w#{$i * 5} { width: $i * 5%; }
}

@include sp {
  @for $i from 0 through 20 {
    .u_w#{$i * 5}sp { width: $i * 5%; }
  }
}

.u_wauto { width: auto; }
@include sp {
  .u_wautosp { width: auto; }
}
