.p-tagSelector {
  display: flex;
  flex-wrap: wrap;
  max-width: $base_width;
  margin: 0 auto 50px;
  @include sp {
    margin-left: 20px;
    margin-right: 20px;
  }

  &__item {
    margin-bottom: 10px;
    @include pc {
      width: calc((100% - 10px * 4)/5);
      &:not(:nth-child(5n)) {
        margin-right: 10px;
      }
    }
    @include sp {
      width: calc((100% - 10px)/2);
      &:not(:nth-child(2n)) {
        margin-right: 10px;
      }
    }

    &.hidden {
      display: none;
    }

    label {
      display: block;
      cursor: pointer;

      input[type="checkbox"] {
        display: none;
      }

      span {
        display: block;
        position: relative;
        padding: .5em 0;
        background-color: $color11;
        border-radius: 4px;
        color: #ffffff;
        text-align: center;
        letter-spacing: .2em;
        @include sp {
          font-size: 0.8125rem;
          letter-spacing: 0;
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background-color: #ffffff;
          background-repeat: no-repeat;
          background-size: contain;
          transition: opacity 300ms;
        }
      }

      input[type="checkbox"]:checked + span {
        background-color: $color08;
      }

      &:hover {
        span::before {
          opacity: .3;
        }
      }
    }

    &[data-category="5"] label span {
      background-color: #97999b;
    }
  }
}

.p-caseIndex {
  max-width: $base_width;
  margin: 0 auto 50px;
  @include sp {
    margin-left: 20px;
    margin-right: 20px;
  }

  &__list {
    min-height: 84px;
    transition: height 400ms;
    @include sp {
      overflow: hidden;
    }

    &:last-child .p-caseIndex__list__col:last-child {
      @include sp {
        margin-bottom: 0;
      }
    }

    &__col {
      width: 320px;
      margin-bottom: 50px;
      line-height: 1.75;
      @include sp {
        width: 100%;
      }

      &__cont {
        flex: 1 1 auto;
        margin-bottom: 1.25em;
        @include ie {
          min-height: 0%;
        }
      }

      a {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        padding: 28px 28px 15px;
        background-color: #fff;
        border: 5px solid #E5E5E5;
        color: inherit;
        text-decoration: none;
        opacity: 0;
        animation: col__animate--appear 300ms 300ms forwards;

        &:hover {
          .p-case__thumb img {
            opacity: 0.7;
            transform: scale(1.03);
          }
        }
      }

      .p-case {

        &__tag {
          display: none; // !
          position: absolute;
          top: -5px;
          right: -5px;
        }

        &__ttl {
          margin-bottom: 1em;
          font-size: 1rem;
          font-weight: bold;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          /*! autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          overflow: hidden;
          @include ie {
            height: 5.25em;
          }
        }

        &__thumb {
          margin-bottom: 1em;
          text-align: center;
          overflow: hidden;

          img {
            vertical-align: bottom;
            transition: opacity 300ms, transform 300ms;
          }
        }

        &__subject {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          /*! autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          overflow: hidden;
          @include ie {
            height: 7em;
          }
        }

        &__date {
          color: #666666;
          font-size: 0.75rem;
          text-align: right;
        }
      }

      &.js-hide {
        display: none;
      }
    }
  }

  &__btn {
    margin-top: 50px;
    text-align: center;
  }

  .js-empty {
    margin: 0;
    padding: 2em 0;
    text-align: center;
  }
}

@keyframes col__animate--appear {
  0% {
    opacity: 0;
    transform: scale(.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.p-caseIndex__list:not([data-category=""]) .p-caseIndex__list__col {
  display: none;
}

.p-case__tag {
  display: none; // !

  i {
    display: inline-block;
    vertical-align: top;
    min-width: 80px;
    margin-left: 1px;
    padding: 0 14px;
    background-color: $color11;
    line-height: 1.75;
    color: #ffffff;
    font-size: 0.75rem;
    font-style: normal;
    text-align: center;

    &[data-category="5"] {
      background-color: #97999b;
    }

    .c_catHeader & {
      border-radius: 2px;
    }
  }
}

.p-case__article {
  max-width: 850px;
  margin: 0 auto 120px;
  @include sp {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 80px;
  }

  .p-case__date {
    margin-bottom: 40px;
    font-size: 0.75rem;
    text-align: right;
    @include sp {
      margin-bottom: 20px;
    }
  }

  .c_colbox {
    @include sp {
      margin-left: -20px;
      margin-right: -20px;
    }
    &__ttl {
      background-color: #444444;
      @include sp {
        left: 20px;
      }
    }
  }
}

.p-case__outline {
  margin-bottom: 50px;

  &__ttl {
    margin-bottom: .5em;
    color: #000000;
    font-size: 1.125rem;
    font-weight: bold;
  }

  &__cont {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 40px 30px;
    background-color: #eeeeee;
    @include sp {
      display: block;
      margin-left: -20px;
      margin-right: -20px;
      padding: 30px 20px;
    }
  }

  &__data {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: calc(100% - 210px);
    font-size: 0.75rem;
    @include sp {
      width: auto;
      margin-bottom: 30px;
    }
    dt {
      width: 100px;
    }
    dd {
      width: calc(100% - 100px);
    }
  }

  &__logo {
    flex: 0 0 auto;
    width: 170px;
    margin-left: 40px;
    @include sp {
      width: 150px;
      margin: 0 auto;
    }
  }
}

.p-case__gallery {
  display: flex;
  margin: 60px 0 80px;
  @include sp {
    display: block;
    margin: 50px 0;
    margin-left: -20px;
    margin-right: -20px;
  }

  img {
    @include pc {
      width: calc((100% - 20px)/2);
      &:nth-child(odd) {
        margin-right: 20px;
      }
    }
    @include sp {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}

.p-case__section {
  margin: 50px 0;
  @include sp {
    margin: 40px 0;
  }

  &__ttl {
    position: relative;
    margin-bottom: 40px;
    padding: 0 10px 15px 75px;
    background-image: $gradient01;
    background-repeat: repeat-x;
    background-position: left bottom;
    background-size: 256px 3px;
    line-height: 1.75;
    color: #000000;
    font-size: 1.125rem;
    font-weight: bold;
    @include sp {
      padding-left: 60px;
    }

    i {
      display: inline-block;
      position: absolute;
      bottom: 3px;
      left: 30px;
      width: 34px;
      height: 38px; // ie
      margin-right: 15px;
      font-size: 0;
      @include sp {
        left: 15px;
      }
    }
  }
}

.p-case__pager {
  position: relative;
  min-height: 57px;
  @include sp {
    padding-top: 50px;
  }

  &__prev, &__next {
    position: absolute;
    top: 14px;
    @include sp {
      top: 0;
    }

    a {
      display: inline-block;
      position: relative;
      line-height: 1.8571428;
      color: #000;
      font-size: 0.875rem;
      font-weight: bold;
      text-decoration: none;
      @include sp {
        text-decoration: underline;
      }
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: calc(.8125rem - 5.656854px);
        width: 8px;
        height: 8px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__prev {
    left: 0;
    a {
      padding-left: 1.25em;
      &::before {
        left: 0;
        border-top: 2px solid $color09;
        border-left: 2px solid $color09;
        transform: rotate(-45deg);
        transform-origin: right top;
      }
    }
  }
  &__next {
    right: 0;
    a {
      padding-right: 1.25em;
      &::before {
        right: 0;
        border-top: 2px solid $color09;
        border-right: 2px solid $color09;
        transform: rotate(45deg);
        transform-origin: left top;
      }
    }
  }
  &__return {
    text-align: center;
  }
}

.p-notelist {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  @include sp {
    display: block;
  }

  &__item {
    border-radius: 4px;
    overflow: hidden;
    @include pc {
      width: calc((100% - 28px) / 2);
      &:nth-child(odd) {
        margin-right: 28px;
      }
      &:nth-child(n+3) {
        margin-top: 10px;
      }
    }
    @include sp {
      margin-top: 10px;
    }

    iframe {
      width: 100%;
      border: none;
    }
  }
}

.p-case__note-unit {
  margin: 60px 0 80px;
  @include sp {
    margin: 50px 0;
  }

  &__ttl {
    font-weight: bold;
  }

  &__inner {
    padding: 25px 20px 15px;
    background-color: #EEEEEE;
    text-align: center;
    @include sp {
      margin-left: -20px;
      margin-right: -20px;
    }

    iframe {
      width: 100%;
      border: none;
      @include pc {
        width: 494px;
      }
    }
  }
}
