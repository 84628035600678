.c_labBtn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 388px;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background-color: $btn_color;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  letter-spacing: .07em;
  transition: all 300ms;

  &:hover {
    background-color: $btn_hover_color;
    color: #fff;
    text-decoration: none;

    .__label {
      color: $color04;
    }
  }

  @include pc {
    height: 70px;
    font-size: 24px;
  }

  .__label {
    color: $color09;
    background-color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 21px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 3px;
    margin-left: 8px;
    margin-right: 8px;
    letter-spacing: .04em;
    transition: color 300ms;
  }
}