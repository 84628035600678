.p_labHeader {
  background-image: url(../../assets/img/bg_lab_mv.png);
  background-size: cover;
  background-position: center;
  position: relative;
  height: 446px;

  @include pc {
    height: 550px;
    background-image: url(../../assets/img/bg_lab_mv@pc.png);
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000,.25);
  }

  svg {
    overflow: visible;
  }
  .title {
    opacity: 0;
    .js-animsition-end & {
      animation: logoFont 750ms forwards;
    }
  }
  .lab {
    opacity: 0;
    .js-animsition-end & {
      animation: logoFont 750ms 200ms forwards;
    }
  }
  .cube {
    opacity: 0;
    .js-animsition-end & {
      animation: logoTagline 750ms 400ms forwards;
    }
  }
  .txt {
    opacity: 0;
    .js-animsition-end & {
      animation: logoFont 750ms 600ms forwards;
    }
  }
  .flag {
    opacity: 0;
    .js-animsition-end & {
      animation: logoFlag 360ms 1500ms forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 20px;
  }

  &__box {
    background-color: #fff;
    z-index: 1;
    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -24px;

    @include pc {
      margin-top: 0;
    }
  }

  &__txt {
    background-color: #434343;
    width: 100%;
    text-align: center;
    font-size: 14px;
    padding: 10px 0;
    color: #fff;
    margin: 0;
    letter-spacing: $letter_spacing_small;

    @include pc {
      font-size: 18px;
      padding: 18px 0;
    }
  }

  &__title {
    margin: 20px 24px 28px;
    
    @include pc {
      margin: 44px 0 56px;
    }

    img {
      width: 388px;
    }

    svg {
      max-width: 100%;
    }
  }
}