// -----------------------------------------------------------------
.c-career_button_basic {
  --block_size: 56px;
  --txt_color: #222;
  --txt_hover_color: #222;
  --chevron_color: #1eb2c6;
  --chevron_hover_color: #1eb2c6;
  --bg_color: rgb(255 255 255/0.8);
  --bg_hover_color: #fff;

  &[data-block-size="45"] {
    --block_size: 45px;
  }
  &[data-bg-opacity="0.6"] {
    --bg_color: rgb(255 255 255/0.6);
  }

  &[data-color="dark"] {
    --txt_color: #fff;
    --txt_hover_color: #fff;
    --chevron_color: #fff;
    --chevron_hover_color: #fff;
    --bg_color: #777;
    --bg_hover_color: #555;
  }

  @include mq-down(768) {
    &[data-block-size-mobile="45"] {
      --block_size: 45px;
    }
  }

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-block-size: var(--block_size);
  padding-inline: 20px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.04em;
  text-decoration: none;
  border-radius: 5px;
  color: var(--txt_color);
  background-color: var(--bg_color);
  contain: paint;
  transition: color 0.4s $easeOutQuart, background-color 0.4s $easeOutQuart;
  &:hover {
    color: var(--txt_hover_color);
  }
  @include hover {
    text-decoration: none;
    color: var(--txt_hover_color);
    background-color: var(--bg_hover_color);
    &::after {
      translate: 8px;
      background-color: var(--chevron_hover_color);
    }
  }

  &::after {
    content: "";
    inline-size: 12px;
    aspect-ratio: 1;
    background-color: var(--chevron_color);
    mask-image: url("/assets/img/career/common/icon/chevron.svg");
    transition: background-color 0.4s $easeOutQuart, translate 0.4s $easeOutBack;
  }
}

// -----------------------------------------------------------------
.c-career_button_back {
  --block_size: 60px;
  --txt_color: #222;
  --txt_hover_color: #222;
  --chevron_color: #222;
  --chevron_hover_color: #222;
  --bg_color: transparent;
  --bg_hover_color: transparent;
  @include mq-up(768) {
    --block_size: 50px;
    --txt_color: #fff;
    --txt_hover_color: #fff;
    --chevron_color: #fff;
    --chevron_hover_color: #fff;
    --bg_color: #666;
    --bg_hover_color: #000;
  }

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  min-block-size: var(--block_size);
  padding-inline-start: 20px;
  padding-inline-end: 32px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.04em;
  text-decoration: none;
  color: var(--txt_color);
  background-color: var(--bg_color);
  contain: paint;
  transition: color 0.4s $easeOutQuart, background-color 0.4s $easeOutQuart;
  &:hover {
    color: var(--txt_hover_color);
  }
  @include hover {
    --txt_color: #fff;
    --chevron_color: #fff;
    text-decoration: none;
    color: var(--txt_hover_color);
    background-color: var(--bg_hover_color);
    &::before {
      background-color: var(--chevron_hover_color);
      translate: -8px;
    }
  }
  @include mq-up(768) {
    font-size: 16px;
  }

  &::before {
    content: "";
    inline-size: 12px;
    aspect-ratio: 1;
    background-color: var(--chevron_color);
    mask-image: url("/assets/img/career/common/icon/chevron.svg");
    scale: -1 1;
    transition: background-color 0.4s $easeOutQuart, translate 0.4s $easeOutBack;
  }
}
