// -----------------------------------------------------------------
.p-career_cta_basic {
  position: relative;
  z-index: 1;
  display: block grid;
  gap: 10px;

  @include mq-up(1024) {
    grid-template-columns: 1fr 1fr;
  }
}
