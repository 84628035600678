.p_lcTop {
  position: relative;
  max-width: 740px;
  margin: 50px auto 0;
  padding: 47px 85px 42px;
  border: 8px solid #B3DEE4;
  background-color: #ffffff;
  font-size: 1rem;
  @include sp {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }
  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -150px;
    left: 70px;
    right: 0;
    width: 130px;
    height: 193px;
    margin: auto;
    background-image: url(../img/ico_lc01.svg);
    background-repeat: no-repeat;
    background-size: contain;
    @include sp {
      top: -90px;
      left: 30px;
      width: 83px;
      height: 123px;
    }
  }
  &__ttl {
    margin: 0 0 1em;
    color: $color07;
    font-size: 1.625rem;
    text-align: center;
    @include sp {
      font-size: 1.125rem;
    }
  }
  .c_markList {
    font-size: 0.875rem;
  }
}

.p_colorMedia {
  height: 100%;
  padding: 10px;
  color: #000000;
  &.c1 { background-color: $color11;}
  &.c2 { background-color: $color08;}
  &.c3 { background-color: $color10;}
  &__ttl {
    margin-bottom: 0.75em;
    font-size: 1rem;
    font-weight: bold;
  }
  &__inner {
    padding: 25px;
  }
}

.p_profileBox {
  @include pc {
    height: 100%;
  }
  @include sp {
    margin-top: 30px;
  }
  &__pic {
    position: relative;
    z-index: 1;
    margin-bottom: -32px;
    font-size: 0;
    text-align: center;
    img {
      width: 182px;
      height: 182px;
      border-radius: 50%;
    }
  }
  &__inner {
    height: calc(100% - 150px);
    a, > span {
      display: block;
      position: relative;
      height: 100%;
      padding: 22px;
      padding-top: 30px;
      background-color: #ffffff;
      border: 8px solid #C0ADD1;
      color: inherit;
      text-align: center;
      text-decoration: none;
      transition: border-color 300ms;

      &.--flex {
        display: flex;
        flex-direction: column;

        > p {
          flex: 0 0 auto;

          &.p_profileBox__theme {
            // flex: 1 1 auto;
          }
        }
      }

      &.disabled {
        pointer-events: none;
      }
    }
    a:hover {
      border-color: #9A7CB6;
    }
  }
  &__name {
    position: relative;
    margin-bottom: .625rem;
    padding-bottom: .5em;
    line-height: 1.75;
    font-size: 1.25rem;
    font-weight: bold;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 90px;
      height: 3px;
      margin: auto;
      background-image: $gradient02;
    }
    &--left {
      &::before {
        right: auto;
      }
    }
  }
  &__theme {
    margin-bottom: 0.5em;
    line-height: 1.8333333333333333;
    color: #666666;
    font-size: 0.75rem;
  }
  &__job {
    margin-bottom: 0.5em;
    line-height: 1.8333333333333333;
    color: #666666;
    font-size: 0.75rem;
  }
  &__lede {
    line-height: 1.7142857142857142;
    font-weight: bold;
  }

  &.c3 {
    .p_profileBox__pic {
      img {
        width: 132px;
        height: 132px;
      }
    }
    .p_profileBox__inner {
      @include pc {
        height: calc(100% - 100px);
      }
      a, > span {
        padding-left: 10px;
        padding-right: 10px;
        border: 6px solid #B3DEE4;
      }
      a:hover {
        border-color: #67BECA;
      }
    }
  }

  .c_grid__item--container1 & {
    .p_profileBox__inner > span {
      border-color: transparent;
      background-color: transparent;
    }
  }
}

.c_grid.col4 .c_grid__item:nth-child(-n+2) .p_profileBox {
  @include sp {
    margin-top: 0;
  }
}

.p_lclede {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  @include pc {
    .c_section__inner > & {
      margin-top: -2em;
      margin-bottom: 3em;
    }
  }
}

.p_profileModal {
  display: none;

  &__item {
    // position: fixed;
    // z-index: 1;
    // bottom: 0;
    // left: 0;
    padding-top: 37px;
  }

  &__pic {
    position: relative;
    z-index: 1;
    margin-bottom: -99px;
    font-size: 0;
    text-align: center;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: -37px;
      left: 31px;
      right: 0;
      width: 33px;
      height: 37px;
      margin: auto;
      background-image: url(../img/ico_flag04.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
    img {
      width: 182px;
      height: 182px;
      border: 8px solid #c0add1;
      border-radius: 50%;
    }
  }

  &__itemBody {
    position: relative;
    border: 8px solid #c0add1;
    background-color: #c0add1;
    .__close {
      position: absolute;
      z-index: 2;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      &::before, &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: calc(16px * 1.41421356);
        width: 100%;
        height: 3px;
        margin: auto;
        background-color: #ffffff;
      }
      &::before { transform: rotate(45deg);}
      &::after { transform: rotate(-45deg);}
    }
    &__head {
      margin: 100px 0 20px;
      text-align: center;
    }
    &__inner {
      padding: 40px 55px;
      background-color: #ffffff;
      @include sp {
        padding: 30px;
      }
      .__ttl {
        position: relative;
        margin-bottom: 1rem;
        padding-bottom: .75em;
        line-height: 1.5;
        font-size: 1rem;
        font-weight: bold;
        text-align: center;
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 90px;
          height: 3px;
          margin: auto;
          background-image: $gradient02;
        }
      }
    }
  }
}

.p_profileModal__item.c3 {
  .p_profileModal__pic {
    &::before {
      background-image: url(../img/ico_flag03.svg);
    }
    img {
      border-color: #67BECA;
    }
  }
  .p_profileModal__itemBody {
    border-color: #67BECA;
    background-color: #67BECA;
  }
}

.p_profileModal__lede {
  margin-bottom: 0.5em;
  color: #ffffff;
  font-size: 1rem;
}

.p_profileModal__name {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: bold;
  &__en {
    margin-bottom: 0.5em;
    color: #ffffff;
    font-family: 'Muli', sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    font-weight: 300;
  }
}

.p_profileModal__job {
  margin-bottom: 0;
  line-height: 1.75;
}

#cboxLoadedContent {
  -ms-overflow-style: none !important;
  &::-webkit-scrollbar {
    display: none !important;
  }
}
