.p_lab_faq {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include pc {
    gap: 40px;
  }

  p, h3 {
    margin: 0;
  }
  
  &__item {
    background-color: #fff;
    border: 3px solid $sky_blue;
  }

  &__question, &__answer {
    padding: 20px;
    display: flex;

    @include pc {
      padding: 20px 40px;
    }

    &:before {
      content: "";
      display: inline-block;
      margin-right: 24px;
      flex-shrink: 0;
      width: 16px;
      height: 22px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 5px;
      margin-left: 5px;
    }
  }
  
  &__question {
    color: $ttl_color;
    font-size: 16px;
    min-height: 73px;
    background-color: rgba(#EEE, .4);
    line-height: $lab_lh_default;
    padding-bottom: 15px;

    &:before {
      background-image: url(../../assets/img/lab_txt_q.svg);
    }
  }
  
  &__answer {
    padding-top: 16px;
    padding-bottom: 24px;
    line-height: $lab_lh_default;

    @include pc {
      padding-top: 26px;
      padding-bottom: 34px;
    }

    &:before {
      background-image: url(../../assets/img/lab_txt_a.svg);
    }
  }
}