.c-search-select {
  @extend %button-chrome;
  margin: auto;
  padding-right: 50px;
  background: #fff url(../img/icon_pulldown.png) no-repeat top 50% right 18px;
  &::-ms-expand { display: none; }
  @include sp {
    background-image: url(../img/icon_pulldown@2x.png);
    background-size: 11px 17px;
  }
}

.c-search-textfield {
  @extend %button-chrome;
}

.c-textarea,
.c-textfield {
  @include input-reset;
  padding: 0 10px;
  background: #f2f2f2;

  @include placeholder {
    color: #aeaeae;
  }

  &.err { background-color: #ffe0e5; }
}

.c-textarea {
  width: 100%;
  box-sizing: border-box;
}

.c-select {
  @include input-reset;
  &::-ms-expand { display: none; }
  padding: 0 40px 0 10px;
  background: #f2f2f2 url(../img/icon_select.png) no-repeat right 10px top 50%;
  max-width: 100%;

  &.err { background-color: #ffe0e5; }
}

.c-radio {
  position: relative;
  display: inline-block;

  input[type="radio"] {
    position: absolute;
    top: 9px;
    left: 3px;
    width: 0;
    height: 0;
    visibility: hidden;
  }

  input[type="radio"] + label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      background: #fff;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #ccc;
    }

    &:after {
      content: '';
      position: absolute;
      top: 8px;
      left: 4px;
      background: #949494;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      //border: 1px solid #d9d9d9;
      transition: all 100ms ease;
      transform: scale(.1);
      opacity: 0;
    }
  }

  &.err input[type="radio"] + label {
    &:before {
      background-color: #ffe0e5;
    }
  }

  input[type="radio"]:checked + label {
    &:after {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.c-input-group {
  > * {
    margin-right: 1em;
    &:last-child { margin-right: 0; }
  }
}
