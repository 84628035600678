.p_ninkai {
  .c_section__inner+.c_section__ttl {
    margin-top: 30px;
  }
}

.p_ninTop {
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
  font-size: 1rem;
  font-weight: bold;
  @include sp {
    padding-bottom: 20px;
    font-size: 0.875rem;
  }
  > p {
    margin-bottom: 2em;
  }
  &.--l {
    background-color: #eeeeee;
    padding-block: 50px;
    max-width: 960px;

    @include sp {
      padding-block: 60px;
    }
  }
  &__ttl {
    margin-top: 0;
    margin-bottom: 1.5em;
    font-size: 1.625rem;
    text-align: center;
    @include sp {
      margin-bottom: 1.25em;
      font-size: 1.25rem;
    }
    img, svg {
      display: block;
      width: 105px;
      margin: 0 auto 65px;
      @include sp {
        margin-bottom: 22px;
      }
    }
  }
  .c_btn__wrap {
    margin-top: 3.25em;
  }
}

.p_ninTop__ttl {
  svg {
    overflow: visible;
  }
  .js-flag {
    transform-origin: center 40%;
  }
}

.p_ninCont {
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  &__ttl {
    margin-top: 0;
    font-size: 1.625rem;
    font-weight: bold;
    text-align: center;
    @include sp {
      margin-bottom: 1.5em;
      font-size: 1.25rem;
    }
  }
}

.p_profileUnit {
  display: flex;
  margin-top: -32px;
  padding: 8px;
  @include sp {
    display: block;
  }
  &.c1 { background-color: #C4DB54;}
  &.c3 { background-color: #67BECA;}
  &.c4 { background-color: #c0add1;}
  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 246px;
    text-align: center;
    @include sp {
      width: auto;
      padding: 40px 0 20px;
    }
  }
  &__pic {
    margin-bottom: 15px;
    font-size: 0;
    text-align: center;
    img {
      width: 182px;
      height: 182px;
      border-radius: 50%;
    }
    &.s1 {
      img {
        width: 132px;
        height: 132px;
      }
    }
  }
  &__name {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 1.5rem;
    font-weight: bold;
    @include sp {
      font-size: 1.25rem;
    }
    &__en {
      margin-bottom: 0.5em;
      color: #ffffff;
      font-family: 'Muli', sans-serif;
      font-size: 0.75rem;
      letter-spacing: .5em;
      font-weight: 300;
      line-height: calc(22/12);
    }
  }
  &__body {
    flex: 1;
    padding: 50px 40px 40px;
    background-color: #ffffff;
    @include sp {
      padding: 30px;
    }
  }
  &__lede {
    position: relative;
    margin-bottom: 1.75rem;
    padding-bottom: 1.25em;
    font-size: 1.125rem;
    font-weight: bold;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 90px;
      height: 3px;
      background-image: $gradient02;
    }
  }
}

.p_ninkaiBox {
  @extend .p_eventBox__Large;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  .p_ninkaiBox__head {
    @extend .p_eventBox__head;
    padding: 0 0 10px;
    background-size: 33.333333% 3px;
    line-height: inherit;
    color: #000000;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    @include sp {
      background-size: 50% 3px;
    }
  }
  .p_ninkaiBox__inner {
    @extend .p_eventBox__inner;
    padding-bottom: 35px;
    color: #000000;
    font-size: 1rem;
    @include sp {
      font-size: 0.8125rem;
    }
    > :last-child {
      margin-bottom: 0;
    }
    &.white {
      padding-left: 0;
      padding-right: 0;
      background-color: #fff;
      color: inherit;
    }
  }
  .p_ninkaiBox__terms {
    @extend .p_eventBox__terms;
    .__item {
      &:not(:first-child) {
        margin-top: 0.75em;
      }
      > span {
        &:first-child {
          flex: 0 0 6em;
          font-weight: bold;
        }
      }
    }
  }
  + .p_ninkaiBox {
    margin-top: 60px;
  }
}

.p_ninkaiSche {
  font-size: 0.875rem;
  font-weight: bold;
  @include sp {
    font-size: 0.8125rem;
  }
  &:not(:last-child) {
    margin-bottom: 2.5em;
  }
  .__item {
    display: flex;
    margin-top: -1px;
    padding: 11px 0;
    border-top: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
    > span:first-child {
      flex: 0 0 10em;
    }
  }

  &__ttl {
    font-weight: bold;
    text-align: center;
    @include sp {
      font-size: 1rem;
    }
  }

  &--smart {
    .__item {
      > span:first-child {
        @include sp {
          flex: 0 0 6em;
        }
      }
    }
  }
}

.p_ninkaiPast {

  &:not(:first-child) {
    margin-top: 60px;
  }

  &__ttl {
    margin-bottom: 2em;
    text-align: center;

    .__main {
      display: block;
      font-size: 1.125rem;
      font-weight: bold;
    }
    .__sub {
      display: block;
      font-size: 0.75rem;
    }
  }
}

.p_sideSns {
  position: fixed;
  z-index: 10000;
  right: -120px;
  bottom: 52px;
  width: 120px;
  padding: 10px 5px;
  background-color: #ffffff;
  line-height: 1.5;
  transition: 300ms 2s;
  .js-animsition-end & {
    right: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, .10);
  }
  .__list {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    > li {
      &:nth-child(2) {
        margin-left: 8px;
      }
      svg, img {
        width: 34px;
        height: 34px;
        @include sp {
          width: 32px;
          height: 32px;
        }
        .cls-1 {
          // fill: #aaaaaa;
          transition: fill 300ms;
        }
      }
      a:hover .cls-1 {
        fill: #000;
      }
    }
  }
  .__txt {
    font-size: .6875rem;
    text-align: center;
    @include sp {
      font-size: 0.625rem;
    }
  }
}


.p_ninlogo {
  display: grid;
  grid-template-columns: 154px 60px 145px 1fr;
  gap: 40px;
  max-width: 670px;
  margin-inline: auto;
  align-items: center;

  @include sp {
    margin-top: 50px;
    grid-template-columns: 1fr;
    max-width: 180px;
    justify-content: center;
  }

  img {
    object-fit: contain;
    margin-inline: auto;

    &.--small {
      @include sp {
        width: 50px;
      }
    }
  }
}