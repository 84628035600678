// -----------------------------------------------------------------
.c-career_about_numbering_item {
  display: block grid;
  @include mq-up(768) {
    grid-template-rows: subgrid;
    grid-row: span 4;
  }

  &__num {
    display: block grid;
    place-items: center;
    inline-size: 32px;
    aspect-ratio: 1;
    margin-inline: auto;
    margin-block-end: 0;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    border-radius: 999vmax;
    background-color: #67beca;
  }

  &__title {
    margin-inline: auto;
    margin-block-start: 10px;
    margin-block-end: 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;
    color: #000;
  }

  &__image {
    margin-block-start: 15px;
    @include mq-up(768) {
      margin-block-start: 25px;
    }

    img {
      inline-size: 100%;
      block-size: auto;
    }
  }

  &__text {
    margin-block-start: 20px;
    margin-block-end: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 2;
  }
}
