.c_section {
  padding: 60px 0;
  @include sp {
    padding: 50px 0;
  }
  &.mgn1 {
    margin: 40px 0;
    @include sp {
      margin: 30px 0;
    }
  }
  &.pdn_b0 {
    padding-block-end: 0;
    @include sp {
      padding-block-end: 0;
    }
  }
  &.pdn1 {
    padding: 100px 0;
    @include sp {
      padding: 80px 0 60px;
    }
  }

  &__inner {
    max-width: $base_width;
    margin: 0 auto;
    @include sp {
      margin-left: 20px;
      margin-right: 20px;
    }
    + .c_section__ttl,
    + .c_section__inner {
      margin-top: 100px;
    }
  }

  &__ttl {
    margin: 0 0 50px;
    text-align: center;
    @include sp {
      margin-bottom: 40px;
    }
    .__ico {
      display: block;
      width: 34px;
      height: 38px; // ie
      margin: 0 auto;
      font-size: 0;
      transform: translate(16px, 2px);
      img {
        margin-right: -32px;
      }
    }
    .__txt {
      display: block;
      color: #ffffff;
      font-size: 0;
      .__block {
        line-height: 1.75;
        letter-spacing: 0.1em;
        @include sp {
          line-height: 2;
          font-size: 1.1875rem;
        }
        @include pc {
          display: inline-flex;
          justify-content: center;
          position: relative;
          min-width: 340px;
          margin-bottom: 10px;
          font-size: 1.625rem;
          overflow: hidden;
          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background-color: #444;
            transition: left 300ms;
          }
          .__bar + .__bar {
            margin-left: -0.8125em;
            padding-left: 0;
          }
        }
        .__bar {
          display: inline-block;
          position: relative;
          padding: 0 0.8125em;
          overflow: hidden;
          @include sp {
            display: block;
            margin: 0 20px 10px;
            &.fz1 {
              padding: 0 0.625em;
              font-size: 1.125rem;
              letter-spacing: 0.03em;
            }
            &::before {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: -100%;
              width: 100%;
              height: 100%;
              background-color: #444;
              transition: left 300ms;
            }
          }
        }
      }
      b {
        display: inline-block;
        position: relative;
        opacity: 0;
        transition: opacity 300ms 300ms;
      }
      em {
        font-weight: bold;
      }
      &.js-ttl-animate {
        .__block {
          &::before {
            left: 0;
          }
          .__bar {
            &::before {
              left: 0;
            }
            b {
              opacity: 1;
            }
          }
        }
      }
    }
    &.c1 {
      .__txt {
        em {
          color: $color11;
        }
      }
    }
    &.c2 {
      .__txt {
        em {
          color: $color10;
        }
      }
    }
    &.c4 {
      .__txt {
        em {
          color: #c0add1;
        }
      }
    }
  }

  &.bg1 {
    background-image: url(../img/bg_pattern01.png);
    background-repeat: repeat;
    animation: sectionBg 30000ms linear infinite;
    @include sp {
      background-size: 256px auto;
    }
  }
  &.bg3 {
    background-color: #eeeeee;
    background-image: url(../img/bg_pattern01.png);
    background-repeat: repeat;
    animation: sectionBg 30000ms linear infinite;
    @include sp {
      background-size: 256px auto;
    }
  }
  &.bgtype1 {
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url(../img/pic_lc01.jpg);
    background-position: center;
    background-size: cover;
    color: #ffffff;
    @include sp {
      background-position: center top;
      background-size: contain;
    }
  }

  &.bg4 {
    background-color: #f5f5ed;
    background-position-y: 20px;
  }
}

// animation
@keyframes sectionBg {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -380px;
  }
}

@include sp {
  @keyframes sectionBg {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: -256px;
    }
  }
}
