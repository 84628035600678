.l_main {

  > :last-child {
    // margin-bottom: 100px;
  }

  // 包含要素の上下マージンを貫通させない
  &:before,
  &:after {
    content: '';
    display: table;
  }
}
