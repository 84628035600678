.l-cta_footer {
  padding-block-start: 55px;
  background-color: #eeeeee;

  &__head {
    box-sizing: initial;
    max-inline-size: 1068px;
    margin-inline: auto;
    padding-inline: 20px;

    .c-career_text_basic {
      font-weight: 700;
    }
  }

  &__contact {
    max-inline-size: 340px;
    inline-size: 100%;
    margin-block-start: 24px;
    margin-inline: auto;

    //
    .c_btn > span {
      min-block-size: 60px !important;
      padding-block: 0;
      font-size: 20px;
      letter-spacing: 0.05em;
      &::after {
        display: none !important;
      }
    }
  }

  &__faq {
    margin-block-start: 20px;
    .c-career_text_basic {
      font-size: 15px;
    }
  }

  &__body {
    display: grid;
    align-content: center;
    block-size: 110px;
    margin-block-start: 10px;
    margin-block-end: 8px;
    padding-inline: 20px;
    padding-block-start: 6px;

    background-image: url("/assets/img/bg_pattern01.png");
    background-position-y: 10px;
    background-repeat: repeat;
    animation: sectionBg 30000ms linear infinite;

    .c-career_text_basic {
      font-size: 15px;
    }
  }
}
