.p_dlWrapper {
  padding: 40px 0 80px;

  @include pc {
    padding: 72px 0 100px;
  }
}

.p_dlContainer {
  padding: 0 20px;
  max-width: calc(860px + 40px);
  margin: 0 auto;

  &__first_txt_area {
    font-size: 16px;
    padding-bottom: 50px;

    @include pc {
      text-align: center;
    }
  }

  &__concept_book {
    margin-top: 60px;

    @include pc {
      margin-top: 80px;
    }
  }

  &__format {
    margin-block-start: 80px;
  }
}

.p_dl {
  margin-top: 32px;

  &__index {
    position: relative;
    font-weight: bold;
  }

  &__index_ttl {
    margin: 0;
    background-color: $color07;
    width: 172px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;

    @include pc {
      width: 230px;
    }
  }

  &__index_date {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    font-weight: normal;
    margin: 0;

    @include pc {
      top: -3px;
      right: -2px;
    }
  }

  &__index_box {
    background-color: #f8f8f8;
    line-height: 1;
    padding: 30px 28px;

    @include pc {
      padding: 32px 44px;
    }

    &.--pc-center {
      ul {
        @include pc {
          justify-content: center;
        }
      }
    }

    ul {
      display: flex;
      gap: 20px;
      flex-direction: column;

      @include pc {
        gap: 40px;
        flex-direction: row;
      }

      li a {
        display: inline-block;
        text-decoration: none;
        transition: opacity 0.3s;
        &:before {
          display: inline-block;
          content: "";
          width: 8px;
          height: 11px;
          transform: rotate(90deg);
          background-size: contain;
          background-image: url(../img/corporate/ico_arw01.svg);
          margin-right: 10px;
        }
        &:hover {
          color: inherit;
          opacity: 0.5;
        }
      }
    }
  }

  &__section_group {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include pc {
      margin-top: 86px;
      gap: 60px;
    }
  }

  &__section {
    border: 3px solid $color07;
  }

  &__section_ttl {
    margin: 0;
    padding: 18px 24px;
    font-size: 18px;
    background-color: #f8f8f8;

    @include pc {
      padding: 18px 46px;
    }
  }

  &__section_box {
    padding: 25px 25px 40px;
    word-break: break-all;

    @include pc {
      padding: 30px 46px 36px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
      line-height: 1.5;
    }
  }

  &__section_list {
    [data-download="false"] & {
      a {
        text-decoration: none;
        pointer-events: none;
      }
    }
  }

  &__section_btn_area {
    margin-top: 37px;

    [data-download="false"] & {
      display: none;
    }
  }
}

.p_dl_concept_book {
  &__head {
    + * {
      margin-block-start: 30px;
    }
  }

  &__image {
    filter: drop-shadow(0 3px 10px rgba(#000, 0.16));
  }

  &__btn {
    max-inline-size: 340px;
    inline-size: 100%;
    margin-block-start: 60px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;

    > p {
      font-size: 15px;
    }

    //
    .c_btn > span {
      min-block-size: 60px !important;
      font-size: 20px;
      letter-spacing: 0.05em;
    }
  }
}
