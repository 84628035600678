.c_toNote {

  &:not(:first-child) { margin-top: 40px;}
  &:not(:last-child) { margin-bottom: 40px;}

  &__inner {
    display: flex;
    align-items: center;
    padding: 24px;
    border: 5px solid #41C9B4;
    background-color: #fff;
    transition: 300ms;
    @include sp {
      display: block;
      padding: 27px;
    }
  }

  &__head {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    padding: 0 38px;
    @include sp {
      flex-direction: column;
      padding: 0 0 20px;
    }

    &__logo {
      width: 93px;
      margin-right: 27px;
      transition: 300ms;
      @include sp {
        margin: 0 0 5px;
      }
    }

    &__ttl {
      font-size: 1.125rem;
      font-weight: bold;
      @include sp {
        margin-left: -0.5em;
      }
    }
  }

  &__cont {
    flex: 1 1 auto;
    padding: .875em 50px .875em 38px;
    transition: border 300ms;
    @include pc {
      border-left: 1px solid #41C9B4;
    }
    @include sp {
      padding: 20px 0 0;
      border-top: 1px solid #41C9B4;
    }

    &__txt {
      font-size: 0.875rem;
      letter-spacing: .05em;
    }
  }

  a {
    display: block;
    position: relative;
    color: inherit;
    text-decoration: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 32px;
      width: 8px;
      height: 8px;
      margin: auto;
      border-top: 2px solid #67BECA;
      border-right: 2px solid #67BECA;
      transform-origin: center;
      transform: rotate(45deg);
      transition: 300ms;
      @include sp {
        top: 58px;
        bottom: auto;
      }
    }

    &::after {
      display: none !important;
    }

    &:hover {
      &::before {
        filter: brightness(0) invert(1);
      }
      .c_toNote__inner {
        background-color: #41C9B4;
        color: #fff;
      }
      .c_toNote__head__logo {
        filter: brightness(0) invert(1);
      }
      .c_toNote__cont {
        border-color: rgba(#fff, .5);
      }
    }
  }
}
