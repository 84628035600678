.p_labFooter {
  position: relative;
  overflow: hidden;
  padding: 56px 20px 110px;

  @include pc {
    padding-top: 156px;
    padding-bottom: 80px;
  }

  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 138px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    background-image: url(../../assets/img/bg_pattern01.png);
    background-repeat: repeat;
    animation: sectionBg 30000ms linear infinite;
    background-size: 270px;
    z-index: 0;

    @include pc {
      height: 200px;
      bottom: -5px;
      background-size: 372px;
    }
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 78px;
    z-index: 1;
  }

  &__logo {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;

    @include pc {
      max-width: 388px;
    }
  }
}