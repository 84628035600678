.p_fanmeetingPast {
  margin-top: 80px;
  
  &__ttl {
    font-size: 1.125rem;
    font-weight: bold;
    text-align: center;
  }
}

.p-fanmeeting__grid {
  display: flex;
  flex-wrap: wrap;
  @include sp {
    .c_section__inner & {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  &__item {
    @include pc {
      width: calc((100% - 20px * (3 - 1)) / 3);
      margin-left: 20px;
      &:nth-child(3n+1) { margin-left: 0; }
      &:nth-child(n+4) { margin-top: 20px; }
    }
    @include sp {
      width: calc((100% - 10px) / 2);
      &:nth-child(even) { margin-left: 10px;}
      &:nth-child(n+3) { margin-top: 10px;}
    }
  }
}

.p_ninkaiSche--fanmeeting {
  .__item {
    > span:first-child {
      @include sp {
        flex: 0 0 7em;
      }
    }
  }
}