.p_eventBox__Large {
  @include pc {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  @include sp {
    margin: 50px -20px 0;
  }
  .p_eventBox__head {
    position: relative;
    padding: 0 0 20px 75px;
    background-image: $gradient01;
    background-repeat: repeat-x;
    background-position: left bottom;
    background-size: 50% 3px;
    line-height: 0;
    @include sp {
      padding-left: 60px;
      background-size: 66.666666% 3px;
    }
    i {
      content: '';
      display: inline-block;
      position: absolute;
      bottom: 3px;
      left: 30px;
      width: 34px;
      height: 38px; // ie
      background-image: url(../img/ico_flag02.svg);
      background-repeat: no-repeat;
      background-size: contain;
      @include sp {
        left: 20px;
      }
    }
    .__place {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.1666667;
      color: #000;
      font-size: 1.125rem;
      font-weight: bold;
      @include sp {
        font-size: 1rem;
      }
    }
    .__tag {
      display: inline-block;
      vertical-align: middle;
      width: 68px;
      margin-left: 5px;
      line-height: 1.75;
      color: #ffffff;
      font-size: 0.75rem;
      font-weight: bold;
      text-align: center;
      @include sp {
        width: 60px;
        margin-left: 2px;
      }
      &.c1 { background-color: $color10;}
      &.c2 { background-color: #888;}
    }
    .__date {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      line-height: 1.5;
      @include sp {
        line-height: 1.75;
        font-size: 0.75rem;
      }
    }
  }
  .p_eventBox__inner {
    padding: 35px 50px 50px;
    background-color: $color03;
    @include pc {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
    }
    @include sp {
      padding: 35px 20px 40px;
    }
  }
  .p_eventBox__ttl {
    line-height: 1.888888;
    color: #000;
    font-size: 1.125rem;
    font-weight: bold;
    @include sp {
      line-height: 1.875;
      font-size: 1rem;
    }
  }
  .p_eventBox__lede {
    @include font_size01;
    color: #000;
    @include sp {
      font-size: 0.8125rem;
    }
  }
  .p_eventBox__info {
    @include pc {
      flex: 1 0 auto;
    }
    @include sp {
      font-size: 0.8125rem;
    }
  }
  .p_eventBox__terms {
    margin-bottom: 35px;
    @include sp {
      margin-bottom: 20px;
    }
    .__item {
      display: flex;
      > span {
        // &:nth-child(1) { flex: 0 0 5.35em;}
        &:nth-child(2) { flex: 1;}
      }
    }
  }

  &.closed {
    .p_eventBox__head {
      i {
        background-image: url(../img/ico_flag05.svg);
      }
    }
    .p_eventBox__inner {
      background-color: #eeeeee;
    }
  }
}
