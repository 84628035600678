.c_localNav {
  padding: 25px 0;
  background-color: #f5f5ed;
  @include sp {
    text-align: center;
  }

  &__list {
    display: flex;
    justify-content: center;
    @include sp {
      display: inline-flex;
      flex-direction: column;
    }
  }

  &__item {
    @include sp {
      text-align: left;
    }
    &:not(:last-child) {
      margin-right: 30px;
      @include sp {
        margin-right: 0;
        margin-bottom: 5px;
      }
    }

    a {
      position: relative;
      display: inline-block;
      padding-left: 25px;
      font-weight: bold;
      text-decoration: none;
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: calc(0.8125rem - 5.656854px);
        top: calc(0.8125rem);
        left: 0;
        width: 8px;
        height: 8px;
        border-bottom: 2px solid $color09;
        border-left: 2px solid $color09;
        transform-origin: left top;
        transform: rotate(-45deg);
      }
    }
  }
}

// -----------------------------------------------------------------
.c_localNav2 {
  max-inline-size: 850px;
  margin-inline: auto;
  padding-block-start: 50px;
  padding-block-end: 20px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 30px;
    row-gap: 10px;
  }

  &__item {
    a {
      position: relative;
      display: inline-block;
      padding-inline-end: 16px;
      font-weight: bold;
      text-decoration: none;
      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: calc(0.8125rem - 5.656854px);
        top: calc(0.8125rem);
        right: 0;
        width: 8px;
        height: 8px;
        border-bottom: 2px solid $color09;
        border-left: 2px solid $color09;
        transform-origin: left top;
        transform: rotate(-45deg);
      }
    }
  }
}
