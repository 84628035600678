.c_hdttl3 {
  color: #000000;
  font-size: 1.125rem;
  text-align: center;
}

.c_manualTtl {
  display: flex;
  align-items: center;
  margin: 0 0 40px;
  padding-bottom: 1em;
  background-image: $gradient01;
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: 50% 3px;
  color: #000;
  @include sp {
    margin-top: 40px;
    padding-bottom: 0.75em;
    background-size: 66.666666% 3px;
    line-height: 1.666666;
  }
  .__num {
    margin-right: 20px;
    line-height: 1;
    font-family: 'Muli', sans-serif;
    font-size: 2.25rem;
    font-weight: 300;
  }
  .__txt {
    flex: 1;
    padding-top: 0.125em;
    font-size: 1.125rem;
  }
  .__badge {
    margin-left: 20px;
    b {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 55px;
      height: 55px;
      margin-top: -0.5em;
      padding: 10px;
      border-radius: 50%;
      background-color: $color09;
      line-height: 1.25;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: normal;
      text-align: center;
      letter-spacing: .05em;
    }
  }
}

.p_attractBlock {
  @extend .c_section;
  background-color: rgba(0, 0, 0, .5);
  background-image: url(../img/pic_attractBlock01.jpg);
  background-position: center;
  background-size: cover;
  color: #ffffff;
  @include sp {
    background-position: center top;
    background-size: contain;
  }
  &__ttl {
    margin: 0 0 2em;
    font-size: 1.5rem;
    text-align: center;
    letter-spacing: .1em;
    @include sp {
      font-size: 1.25rem;
    }
  }
  &__inner {
    @extend .c_section__inner;
  }
  &__lede {
    margin-bottom: 30px;
    text-align: center;
    @include sp {
      font-size: 0.8125rem;
      text-align: left;
    }
  }
}

.p_attractBox {
  height: 100%;
  background-color: #ffffff;
  color: #333333;
  &__inner {
    padding: 30px;
  }
  &__ico {
    text-align: center;
    img {
      width: 34px;
    }
  }
  &__btn {
    @extend %button-chrome;
    width: 100%;
    > span {
      min-height: 60px;
      border-radius: 0;
      box-shadow: 0 -1px 0 #D9D9D9;
      color: #333333;
      font-size: 0.875rem;
      @include ie {
        height: 60px;
      }
      i {
        margin-right: 10px;
      }
    }
    &:hover {
      > span {
        opacity: 0.7;
      }
    }
  }
  &__btnContent {
    background-color: $color03;
    .__inner {
      padding: 40px 50px 30px;
      @include sp {
        padding: 30px 20px;
        font-size: 0.75rem;
      }
      h4 {
        margin-top: 0;
        font-size: 1rem;
        text-align: center;
        @include sp {
          font-size: 0.875rem;
        }
      }
    }
    .__btn {
      @extend %button-chrome;
      width: 100%;
      > span {
        min-height: 60px;
        border-radius: 0;
        box-shadow: 0 -1px 0 #D9D9D9;
        background-color: #ffffff;
        color: #333333;
        @include ie {
          height: 60px;
        }
        i {
          margin-right: 10px;
          transform-origin: center;
          transform: rotate(45deg);
        }
      }
      &:hover {
        > span {
          opacity: 0.7;
        }
      }
    }
  }
}

.p_inquiryBlock {
  @extend .c_section;
  background-color: #eee;
  background-image: url(../img/bg_pattern01.png);
  background-repeat: repeat;
  animation: sectionBg 30000ms linear infinite;
  @include sp {
    background-size: 256px auto;
  }
  &__inner {
    @extend .c_section__inner;
  }
  &__lede {
    margin-bottom: 2em;
    color: #000000;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    @include sp {
      font-size: 0.875rem;
      text-align: left;
    }
  }
}

.p_creditArea {
  @extend .c_section__inner;
  padding-top: 60px;
  padding-bottom: 30px;
  font-size: 0.75rem;
  @include sp {
    padding-top: 45px;
    padding-bottom: 15px;
  }
}

.c_fullsize {
  @include sp {
    margin-left: -20px;
    margin-right: -20px;
  }
}
