.c_colbox {
  @include margin-root;
  position: relative;
  margin: 30px 0;

  &__ttl {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 1em;
    background-color: $color02;
    color: #ffffff;
    font-size: 0.875rem;
    transform: translateY(-50%);
  }

  &__inner {
    padding: 30px;
    font-size: .875rem;
    @include sp {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 0.8125rem;
    }
  }

  &.c1 { background-color: #eeeeee;}
  &.c2 { background-color: #F5F5ED;}
  &.c3 { background-color: #fff;}
  &.type1 {
    margin-top: 40px !important;
    .c_colbox__inner { padding-top: 36px;}
  }
  &.comment {
    margin-top: 60px;
    .c_colbox__ttl {
      padding-top: 2px;
      padding-bottom: 2px;
      font-size: 1rem;
      i {
        display: inline-block;
        margin-right: 12px;
      }
    }
    .c_colbox__inner {
      padding-top: 40px;
    }
  }
}
