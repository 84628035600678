.p_hero {
  position: relative;
  // margin-bottom: 30px;
  background-color: #eff3fe;
  background-image: url(../img/hero01@pc.jpg);
  background-repeat: no-repeat;
  background-position: center bottom;
  overflow: hidden;
  @include sp {
    // margin-bottom: 30px;
    background-image: url(../img/hero01@sp.jpg), url(../img/bg_hero01@sp.jpg);
    background-size: 575px auto, 1px auto;
    background-repeat: no-repeat, repeat-x;
  }

  &__inner {
    position: relative;
    top: 0;
    left: 50%;
    width: 1280px;
    height: 777px;
    margin-left: -640px;
    @include sp {
      width: 375px;
      height: 607px;
      margin-left: calc(-375px / 2);
    }
  }

  &__logo {
    position: absolute;
    top: 74px;
    left: 0;
    right: 0;
    width: 400px;
    margin: auto;
    @include sp {
      top: 52px;
      width: 256px;
    }
    @include ie {
      .logo_cube__a {
        fill: #f2f2f2;
      }
      .logo_cube__b {
        fill: #a3a3a3;
      }
      .logo_cube__c {
        fill: #cecece;
      }
      .d {
        fill: #040000;
      }
      .e {
        fill: #97989a;
      }
      .f {
        fill: #fff;
      }
      .g {
        clip-path: url(#a);
      }
      .h {
        fill: #5dc2d0;
      }
      .i {
        fill: #9a85bd;
      }
      .j {
        fill: #9dc815;
      }
      .k {
        fill: #e3b425;
      }
    }
  }

  &__lede {
    position: absolute;
    top: 290px;
    left: 0;
    right: 0;
    width: 400px;
    margin: auto;
    line-height: 2;
    color: #2b2b2b;
    font-size: 0.9375rem;
    font-weight: bold;
    text-align: center;
    // text-shadow: 1px 1px 0 rgba(255, 255, 255, .75), -1px 1px 0 rgba(255, 255, 255, .75), 1px -1px 0 rgba(255, 255, 255, .75), -1px -1px 0 rgba(255, 255, 255, .75);
    @include sp {
      top: 189px;
      width: 248px;
      line-height: 2.166666;
      font-size: 0.75rem;
      text-align: left;
    }
  }

  &__ico {
    position: absolute;
    width: 31px;
    height: 34px; // ie
    @include sp {
      width: 25px;
      height: 26px; // ie
    }
    &.ico01 {
      top: 413px;
      left: 212px;
      @include sp {
        top: 315px;
        left: 45px;
      }
      img {
        transform: rotate(-6deg);
        @include sp {
          transform: rotate(0deg);
        }
      }
    }
    &.ico02 {
      top: 424px;
      left: 499px;
      @include sp {
        top: 331px;
        left: 166px;
      }
      img {
        transform: rotate(5deg);
        @include sp {
          transform: rotate(17deg);
        }
      }
    }
    &.ico03 {
      top: 395px;
      left: 769px;
      @include sp {
        top: 307px;
        left: 233px;
      }
    }
    &.ico04 {
      top: 417px;
      left: 1063px;
      @include sp {
        top: 350px;
        left: 308px;
      }
      img {
        transform: rotate(-14deg);
        @include sp {
          transform: rotate(-10deg);
        }
      }
    }
  }

  // &__noteLink {
  //   position: fixed;
  //   z-index: 1;
  //   top: (74px + 64px);
  //   right: 0;
  //   width: 240px;
  //   border-radius: 10px 0 0 10px;
  //   background-color: #41C9B4;
  //   filter: drop-shadow(6px 6px 6px rgba(#000, .16));
  //   overflow: hidden;
  //   opacity: 0;
  //   transform: translateX(30px);
  //   transition: opacity 400ms 2500ms, transform 400ms 2500ms;
  //   @include sp {
  //     top: auto;
  //     bottom: 0;
  //     width: 100%;
  //     border-radius: 0;
  //     transform: translateX(0);
  //   }

  //   .js-animsition-end & {
  //     opacity: 1;
  //     transform: translateX(0);
  //   }

  //   &.is-show {
  //     opacity: 1;
  //     transform: translateX(0);
  //     transition: opacity 400ms, transform 400ms;
  //   }

  //   &.is-hide {
  //     opacity: 0;
  //     transform: translateX(30px);
  //     transition: opacity 400ms, transform 400ms;
  //     pointer-events: none;
  //     @include sp {
  //       transform: translateX(0);
  //     }
  //   }

  //   &__inner {
  //     display: block;
  //     padding: 25px 10px 10px;
  //     color: inherit;
  //     text-decoration: none !important;
  //     @include sp {
  //       padding: 15px 20px 10px;
  //     }

  //     &:hover {
  //       .p_hero__noteLink__btn::before {
  //         background-color: rgba(#000, 1);
  //       }
  //     }
  //   }

  //   &__head {
  //     margin-bottom: 12px;
  //     line-height: 1.625;
  //     color: #000;
  //     font-size: 1.125rem;
  //     font-weight: bold;
  //     letter-spacing: .02em;
  //     text-align: center;
  //     @include sp {
  //       display: flex;
  //       align-items: flex-end;
  //       justify-content: center;
  //       font-size: 0.875rem;
  //     }

  //     &__logo {
  //       margin-bottom: 8px;
  //       @include sp {
  //         margin: 0 4px 3px 0;
  //       }
  //     }
  //   }

  //   &__txt {
  //     margin: 0 20px 15px;
  //     line-height: 1.75;
  //     color: #fff;
  //     @include sp {
  //       margin: 0 0 15px;
  //       font-size: .75rem;
  //     }
  //   }

  //   &__btn {
  //     position: relative;
  //     padding: 2px;
  //     border-radius: 6px;
  //     font-weight: bold;
  //     text-align: center;
  //     overflow: hidden;
  //     @include sp {
  //       width: fit-content;
  //       padding: 2px 20px;
  //       margin: 0 auto;
  //     }

  //     &::before {
  //       content: '';
  //       display: block;
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       width: 100%;
  //       height: 100%;
  //       background-color: rgba(#000, .15);
  //       mix-blend-mode: multiply;
  //       transition: 300ms;
  //     }

  //     .c_linkList > li > span {
  //       color: #fff;
  //       text-decoration: none !important;
  //       &::before {
  //         border-top-color: #fff;
  //         border-right-color: #fff;
  //       }
  //       &::after {
  //         filter: brightness(0) invert(1);
  //       }
  //     }
  //   }
  // }
}

.p_aboutBlock {
  @extend .c_section;
  background-image: url(../img/bg_pattern01.png);
  background-repeat: repeat;
  animation: sectionBg 30000ms linear infinite;
  @include sp {
    margin-bottom: 30px;
    padding-bottom: 0;
    background-size: 256px auto;
  }

  &__inner {
    @extend .c_section__inner;
    position: relative;
    display: flex;
    justify-content: flex-end;
  }

  &__ttl {
    position: absolute;
    top: 0;
    left: 0;
    width: 350px;
    height: 350px;
    padding: 50px 16px;
    margin: 0;
    color: #ffffff;
    @include sp {
      right: 0;
      width: 230px;
      height: 230px;
      margin: auto;
      padding: 30px 10px;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #444444;
      transform-origin: center;
      transform: scale(0.85);
      opacity: 0;
      transition: transform 300ms, opacity 300ms;
    }
    .__inner {
      display: block;
      position: relative;
      opacity: 0;
      transition: opacity 300ms 300ms;
    }
    .__ico {
      display: block;
      text-align: center;
      img,
      svg {
        width: 105px;
        @include sp {
          width: 69px;
        }
        @include ie {
          .cube_a {
            fill: #f2f2f2;
          }
          .cube_b {
            fill: #a3a3a3;
          }
          .cube_c {
            fill: #cecece;
          }
          .cube_d {
            fill: #040000;
          }
          .cube_e {
            clip-path: url(#a);
          }
          .cube_f {
            fill: #5dc2d0;
          }
          .cube_g {
            fill: #9a7cb6;
          }
          .cube_h {
            fill: #abcd03;
          }
          .cube_i {
            fill: #e3b425;
          }
        }
      }
    }
    .__txt {
      display: block;
      padding: 10px 0 4px;
      background-image: $gradient01;
      background-position: left bottom;
      background-size: 100% 4px;
      font-size: 2.0625rem;
      text-align: center;
      @include sp {
        font-size: 1.375rem;
      }
    }
    &.js-ttl-animate {
      &::before {
        transform: scale(1);
        opacity: 1;
      }
      .__inner {
        opacity: 1;
      }
    }
  }

  &__txt {
    width: 720px;
    margin-top: 50px;
    padding: 57px 50px 52px 104px;
    background-color: $color09;
    font-size: 1rem;
    color: #ffffff;
    font-weight: bold;
    @include pc {
      line-height: 2.25;
    }
    @include sp {
      padding: 58px 20px 40px;
      margin: 200px -20px 0;
      font-size: 0.875rem;
    }
    p:not(:last-child) {
      margin-bottom: 2em;
    }
  }
}

.p_meritBox {
  height: 100%;
  padding: 28px;
  border: 8px solid #c0add1;
  background-color: #ffffff;
  color: #444;
  &.c1 {
    border-color: #b5dee4;
  }
  &__ttl {
    margin: 0 0 1.25em;
    line-height: 1.888888;
    color: $color04;
    font-size: 1.125rem;
    text-align: center;
  }
  &__num {
    margin-bottom: 0.5em;
    line-height: 1;
    font-family: "Muli", sans-serif;
    font-size: 2.25rem;
    text-align: center;
  }
}

// .c_section.bg2 {
//   padding-bottom: 280px;
//   background-image: url(../img/pic_index01@pc.jpg);
//   background-position: center bottom;
//   background-size: contain;
//   @include sp {
//     padding-bottom: 200px;
//     background-image: url(../img/pic_index01@sp.jpg);
//   }
// }

.p_commentBlock {
  margin-bottom: 60px;
  @include sp {
    margin-bottom: 0;
  }
  &__ttl {
    margin: 0 0 30px;
    text-align: center;
    span {
      display: inline-block;
      padding: 0 0.8125em;
      background-color: $color08;
      color: #ffffff;
      font-size: 1.125rem;
    }
  }
  &__grid {
    display: grid;
    gap: 20px;
    @include pc {
      grid-template-columns: repeat(4, 1fr);
    }
    // display: flex;
    // flex-wrap: wrap;
    // @include sp {
    //   display: block;
    //   margin-left: -20px;
    //   margin-right: -20px;
    // }
  }
  &__gridItem {
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 3;
    align-content: start;
    gap: 0;

    padding: 32px 20px 24px;
    border: 5px solid #c0add1;
    background-color: #fff;

    svg {
      display: flex;
      inline-size: 51px;
      margin-inline: auto;
      fill: #c0add1;
    }

    .__profile {
      margin-block-start: 12px;
      margin-block-end: 0;
      font-size: 12px;
      line-height: 1.6;
      letter-spacing: 0.04em;
      text-align: center;
    }

    .__text {
      margin-block-start: 12px;
      margin-block-end: 0;
      font-size: 14px;
      line-height: 1.7;
      letter-spacing: 0.04em;
    }
  }
}

.p_stemBlock__wrap {
  padding: 64px 0;
  background-image: url(../img/pic_index02@pc.jpg);
  background-position: center top;
  background-size: cover;
  color: #ffffff;
  text-align: center;
  @include sp {
    padding: 0;
    background-image: url(../img/pic_index02@sp.jpg);
    font-size: 0.8125rem;
  }
  .p_stemBlock {
    max-width: 730px;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.5);
    &__ttl {
      margin: 0;
      padding: 0.25em 0;
      background-color: #444;
      font-size: 1.5rem;
      letter-spacing: 0.1em;
      @include sp {
        padding: 0.75em 0;
        font-size: 1.25rem;
      }
      em {
        color: $color09;
        font-weight: bold;
      }
    }
    &__inner {
      padding: 30px 30px 45px;
      @include sp {
        padding: 30px 20px 55px;
        background-image: url(../img/pic_index02@sp.jpg);
        background-position: center top;
        background-size: cover;
        text-align: left;
      }
      > p {
        margin-bottom: 2.5em;
      }
    }
    &__subTtl {
      margin-bottom: 0.625em;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      @include sp {
        margin-top: 2em;
      }
    }
  }
}

.p_eventBox__Small {
  position: relative;
  padding: 30px 30px 20px;
  border: 5px solid #e5e5e5;
  background-color: #ffffff;
  @include pc {
    height: 100%;
  }
  @include sp {
    padding-left: 25px;
    padding-right: 25px;
  }
  .p_eventBox__inner {
    @include pc {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  .p_eventBox__ttl {
    margin-bottom: 0.75em;
    color: #000;
    line-height: 1.875;
    font-size: 1rem;
    font-weight: bold;
  }
  .p_eventBox__lede {
    margin-bottom: 0.75em;
    @include font-size01;
    @include pc {
      flex: 1 0 auto;
    }
  }
  .p_eventBox__info {
    color: #666666;
    font-size: 0.75rem;
    text-align: right;
  }
  .p_eventBox__tag {
    display: inline-block;
    position: absolute;
    top: -5px;
    right: -5px;
    width: 68px;
    line-height: 1.75;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
    &.c1 {
      background-color: $color10;
    }
    &.c2 {
      background-color: #888;
    }
  }
}

.p_reasonBlock {
  @extend .c_section;
  padding: 90px 0 100px;
  background-color: $color02;
  color: #ffffff;
  @include sp {
    padding: 80px 0 90px;
  }
  &__ttl {
    margin: 0 0 2em;
    text-align: center;
    @include sp {
      margin: 0 20px 1.5em;
    }
    .__txt {
      display: inline-block;
      width: 440px;
      padding: 0.375em 0;
      background-color: #ffffff;
      line-height: 1.75;
      color: $color02;
      font-size: 1.125rem;
      letter-spacing: 0.1em;
      @include sp {
        display: block;
        width: auto;
        padding: 0.625em 0;
        font-size: 0.875rem;
      }
    }
  }
  &__inner {
    @extend .c_section__inner;
    font-weight: bold;
    @include sp {
      font-size: 0.8125rem;
    }

    .c_linkList {
      > li a {
        color: #fff;
        &::before {
          border-top-color: #fff;
          border-right-color: #fff;
        }
        &::after {
          filter: brightness(150%);
        }
      }
    }
  }
}

// animation
.p_hero__logo {
  svg {
    overflow: visible;
  }
  .logo_lede {
    opacity: 0;
    .js-animsition-end & {
      animation: logoTagline 600ms 0ms forwards;
    }
  }
  .logo_cube {
    opacity: 0;
    .js-animsition-end & {
      animation: logoFont 750ms 900ms forwards;
    }
  }
  .logo_font01 {
    opacity: 0;
    .js-animsition-end & {
      animation: logoFont 750ms 1100ms forwards;
    }
  }
  .logo_font02 {
    opacity: 0;
    .js-animsition-end & {
      animation: logoFont 750ms 1300ms forwards;
    }
  }
  .logo_font03 {
    opacity: 0;
    .js-animsition-end & {
      animation: logoFont 750ms 1500ms forwards;
    }
  }
  .logo_font04 {
    opacity: 0;
    .js-animsition-end & {
      animation: logoFont 750ms 1700ms forwards;
    }
  }
  .logo_tagline {
    opacity: 0;
    .js-animsition-end & {
      animation: logoTagline 300ms 2500ms forwards;
    }
  }
  .logo_flag {
    opacity: 0;
    // transform-origin: center 30%; // logo01
    transform-origin: center 50%; // logo02
    .js-animsition-end & {
      animation: logoFlag 360ms 2800ms forwards
        cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }
}

.p_hero__lede {
  opacity: 0;
  .js-animsition-end & {
    animation: logoTagline 300ms 2500ms forwards;
  }
}

.p_hero__ico {
  opacity: 0;
  .js-animsition-end & {
    animation: logoFlag 360ms 2800ms forwards
      cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

.p_aboutBlock__ttl {
  .__ico {
    svg {
      overflow: visible;
    }
    .js-flag {
      transform-origin: center 40%;
    }
  }
}

.p_lcledeTtl {
  position: relative;
  margin: 0 0 2em;
  padding-top: 160px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  @include sp {
    padding-top: 130px;
    font-size: 1.125rem;
  }
  .__ico {
    display: block;
    position: absolute;
    top: 0;
    left: 36px;
    right: 0;
    width: 296px;
    height: 137px;
    margin: auto;
    @include sp {
      left: 22px;
      height: 119px;
    }
    i {
      opacity: 0;
      display: inline-block;
      width: 92px;
      height: 137px;
      background-image: url(../img/ico_lc01.svg);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: contain;
      transform-origin: center bottom;
      @include sp {
        width: 80px;
        height: 119px;
      }
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}

.js-flag {
  opacity: 0;
  transform-origin: center bottom;
  &-fall {
    .js-animsition-end & {
      animation: logoFlag 450ms 150ms forwards
        cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }
}

.js-flagman {
  transform-origin: center bottom;
  &-fall {
    .js-animsition-end & {
      i:nth-child(1) {
        animation: logoFlag 450ms 0ms forwards
          cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
      i:nth-child(2) {
        animation: logoFlag 450ms 300ms forwards
          cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
      i:nth-child(3) {
        animation: logoFlag 450ms 600ms forwards
          cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
  }
}

@keyframes logoFont {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes logoTagline {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes logoFlag {
  0% {
    opacity: 0;
    transform: translateY(-20px) scaleY(1);
  }
  70% {
    opacity: 1;
    transform: translateY(0px) scaleY(0.85);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scaleY(1);
  }
}

.p_bnrArea {
  margin-bottom: 60px;
  text-align: center;
  @include sp {
    margin-bottom: 30px;
  }
  a {
    display: inline-block;
    transition: opacity 300ms;
    &:hover {
      opacity: 0.7;
    }
  }
}

.p-newsArea {
  max-width: 1020px;
  margin: 0 auto 50px;
  @include sp {
    margin: 0 20px 50px;
  }

  &__ttl {
    margin-bottom: 1.25em;
    font-weight: bold;
    @include sp {
      text-align: center;
    }
  }

  &__cont {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 80px;
    @include sp {
      grid-template-columns: auto;
      grid-template-rows: repeat(2, auto);
      gap: 40px;
    }
  }

  &__list {
    flex: 1 1 auto;
    margin-bottom: 0.5em;
    @include sp {
      margin-bottom: 0;
    }

    > li {
      display: flex;
      line-height: 1.75;
      font-size: 0.8125rem;
      text-decoration: none;
      @include sp {
        display: block;
      }
      &:not(:last-child) {
        margin-bottom: 1em;
      }
      a {
        display: block;
        width: calc(100% - 100px);
        text-decoration: none;
        @include sp {
          width: auto;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      .__date {
        width: 100px;
        letter-spacing: 0;
        @include sp {
          display: block;
          width: auto;
        }
      }
      .__txt {
      }
    }
  }

  &__link {
    text-align: right;
    a {
      display: inline-block;
      position: relative;
      padding-left: 1.25em;
      line-height: 1.8571428;
      font-size: 0.75rem;
      text-decoration: none;

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: calc(50% - 5.656854px);
        left: 0;
        width: 8px;
        height: 8px;
        border-top: 2px solid $color02;
        border-right: 2px solid $color02;
        transform-origin: left top;
        transform: rotate(45deg);
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__bnr {
    display: flex;
    justify-content: space-between;
    width: 335px;
    @include sp {
      width: 272px;
      margin: 0 auto;
    }

    a {
      transition: opacity 300ms;
      &:hover {
        opacity: 0.7;
      }

      @include sp {
        &:nth-child(1) img {
          width: 129px;
        }
        &:nth-child(2) img {
          width: 113px;
        }
      }
    }
  }
}

.p_developerBlock {
  padding: 80px 0 100px;

  @include sp {
    padding: 37px 0 80px;
  }

  &__container {
    max-width: 750px;
    margin: 0 auto;
    display: grid;
    gap: 42px;
    grid-template-columns: 200px 1fr;

    @include sp {
      grid-template-columns: 1fr;
      margin: 0 20px;
    }
  }

  &__img_group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include sp {
      gap: 10px;
      align-items: center;
    }

    img {
      border: 1px solid rgba(#ddd, 0.8);
      max-width: 200px;
    }
  }

  &__profile {
    font-size: 14px;
    line-height: calc(22 / 14);

    p {
      margin-bottom: 0;

      @include sp {
        text-align: center;
      }
    }
  }

  &__name {
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    margin-top: 12px;
  }

  &__ttl {
    color: #97999b;
    font-size: 16px;
    margin-bottom: 25px;
    margin-top: 0;

    @include sp {
      font-size: 15px;
      text-align: center;
    }
  }
}

// -----------------------------------------------------------------
.p-index_career_notice {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 460px;
  min-block-size: 70px;
  margin-block-end: 50px;
  @include sp {
    grid-template-columns: 1fr;
    margin-block-start: -124px;
    margin-block-end: 40px;
  }

  &__start {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-block-end: 0;
    padding-inline: 20px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.75;
    letter-spacing: 0.04em;
    background-color: #e6e6e6;
    @include sp {
      justify-content: center;
      block-size: 60px;
      font-size: 13px;
      text-align: center;
    }
  }

  &__end {
    --chevron_color: #fff;

    a {
      display: flex;
      align-items: center;
      column-gap: 10px;
      block-size: 100%;
      margin-block-end: 0;
      padding-inline-start: 30px;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.6;
      text-decoration: none;
      color: #fff;
      background-color: #222;
      transition: background-color 0.4s $easeOutQuart;
      &:hover {
        color: #fff;
      }
      @include hover {
        --txt_color: #fff;
        text-decoration: none;
        background-color: #444;
        &::after {
          translate: 6px;
        }
      }
      @include sp {
        justify-content: center;
        block-size: 64px;
        padding-inline: 20px;
        font-size: 15px;
        text-align: center;
      }

      &::after {
        content: "";
        inline-size: 12px;
        aspect-ratio: 1;
        background-color: var(--chevron_color);
        mask-image: url("/assets/img/career/common/icon/chevron.svg");
        transition: background-color 0.4s $easeOutQuart,
          translate 0.4s $easeOutBack;
        @include sp {
          position: absolute;
          inset-inline-end: 20px;
        }
      }
    }
  }
}

// -----------------------------------------------------------------
.p-index_career_link {
  position: fixed;
  z-index: 2;
  inset-inline-end: 0;

  @include pc {
    inset-block-start: 136px;
    opacity: 0;
    transform: translateX(30px);
    transition: opacity 400ms 2500ms, transform 400ms 2500ms;

    .js-animsition-end & {
      opacity: 1;
      transform: translateX(0);
    }

    &.is-show {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 400ms, transform 400ms;
    }

    &.is-hide {
      opacity: 0;
      transform: translateX(30px);
      transition: opacity 400ms, transform 400ms;
      pointer-events: none;
    }
  }

  @include sp {
    inset-block-end: 0;
    inset-inline-start: 0;

    opacity: 0;
    pointer-events: none;
    transition: opacity 400ms, transform 400ms;
    // .js-animsition-end & {
    //   opacity: 1;
    // }

    &.is-show {
      opacity: 1;
      pointer-events: auto;
    }

    &.is-hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  a {
    display: grid;
    row-gap: 20px;
    inline-size: 196px;
    padding: 24px 15px 15px;
    text-decoration: none;
    border-radius: 10px 0 0 10px;
    color: #fff !important;
    background-color: #fff;
    filter: drop-shadow(6px 6px 12px rgba(#000, 0.16));

    @include sp {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 20px;
      inline-size: 100%;
      block-size: 70px;
      padding: 0 20px;
      border-radius: 0;
      background-color: rgba(#fff, 0.7);
      backdrop-filter: blur(32px);
    }
  }

  &__logo {
    text-align: center;
    // transition: opacity 0.4s $easeOutQuart;
    // @include group-hover {
    //   opacity: 0.8;
    // }
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    block-size: 34px;
    padding-inline-start: 4px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.05em;
    border-radius: 6px;
    background-color: #222;
    transition: background-color 0.4s $easeOutQuart;
    @include sp {
      inline-size: 150px;
      font-size: 13px;
    }

    @include group-hover {
      background-color: #444;
      &::after {
        translate: 4px;
      }
    }

    &::after {
      content: "";
      inline-size: 11px;
      aspect-ratio: 1;
      background-color: #fff;
      mask-image: url("/assets/img/career/common/icon/chevron.svg");
      transition: background-color 0.4s $easeOutQuart,
        translate 0.4s $easeOutBack;
      @include sp {
        background-color: #1eb2c6;
      }
    }
  }
}

// -----------------------------------------------------------------
.p-merit_wrap {
  position: relative;
  margin-block-start: 120px;
  @include sp {
    margin-block-start: 0;
  }

  &__inner {
    position: absolute;
    inset-block-start: -70px;
    display: grid;
    grid-template-columns: repeat(3, 318px);
    align-items: start;
    column-gap: 30px;
    @include sp {
      position: static;
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }
}

// -----------------------------------------------------------------
.p-merit_bg {
  block-size: 460px;
  @include sp {
    block-size: revert;
    margin-block-start: 30px;
  }

  img {
    @include objFitCover;
  }
}

// -----------------------------------------------------------------
.p-merit_accordion {
  --bg_color: #abcd03;

  &[data-merit="1"] {
    --bg_color: #abcd03;
  }
  &[data-merit="2"] {
    --bg_color: #e3b425;
  }
  &[data-merit="3"] {
    --bg_color: #9a7cb6;
  }

  background-color: var(--bg_color);
  cursor: pointer;
  @include noOutlineWithoutFocus;

  &__head {
    padding: 36px 20px 30px;
  }

  &__title {
    margin-block: 0;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    color: #fff;
  }

  &__body {
    display: none;
  }

  &__text {
    padding: 0 30px 20px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
  }

  &__trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      inset-block-start: 0;
      inline-size: 102px;
      block-size: 1px;
      background-color: rgba(#000, 0.5);
    }

    button {
      margin: 0;
      padding: 0;
      border: none;
      outline: 0;
      font-family: "dnp-shuei-gothic-gin-std",sans-serif;

      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      inline-size: 100%;
      block-size: 44px;
      font-size: 15px;
      font-weight: 700;
      color: #222;
      cursor: pointer;

      .__icon {
        position: relative;
        display: flex;
        inline-size: 15px;
        aspect-ratio: 1;
        &::before,
        &::after {
          content: "";
          position: absolute;
          inset-block-start: 50%;
          translate: 0 -50%;
          inline-size: inherit;
          block-size: 3px;
          background-color: #222;
        }
        &::after {
          rotate: 90deg;
          transition: scale 0.4s $easeOutQuad;
          .is-active_accordion & {
            scale: 0;
          }
        }
      }
    }
  }
}
