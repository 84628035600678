.l_wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: $header_height_pc;
  @include pc {
    min-width: $base_width + 20;
  }
  @include sp {
    padding-top: $header_height_sp;
  }
}
