// -----------------------------------------------------------------
.c-career_cta_basic {
  position: relative;

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      @include objFitCover;
      object-position: center top;
      ._start & {
        object-position: center 20%;
      }
      ._end & {
        object-position: center 15%;
      }
    }
  }

  &__inner {
    display: block flex;
    flex-direction: column;
    justify-content: space-between;
    block-size: 324px;
    padding-block-start: 30px;
    padding-block-end: 30px;
    padding-inline: 20px;
    // background-color: rgba(red, 0.3);

    @include mq-up(768) {
      block-size: 462px;
      padding-block-start: 60px;
      padding-block-end: 66px;
      ._start & {
        margin-inline-start: auto;
        padding-inline-start: clamp(20px, calc(78 / 1366 * 100vw), 78px);
      }
    }
  }

  &__info {
    display: block grid;
    row-gap: 20px;

    ._end & {
      margin-inline: auto;
    }
  }

  &__label {
    inline-size: fit-content;
    ._end & {
      margin-inline: auto;
    }
  }

  &__subtitle {
    margin-block-end: 10px;
    margin-block-end: 5px;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    color: #000;
    @include mq-up(768) {
      font-size: 16px;
      margin-block-end: 0;
    }
  }

  &__btn {
    max-inline-size: 335px;
    inline-size: 100%;
    margin-inline: auto;
    margin-block-start: 10px;
    @include mq-up(480) {
      ._start & {
        margin-inline: 0;
      }
    }
    @include mq-up(768) {
      inline-size: 310px;
      ._start & {
        margin-inline: 0;
      }
    }
  }
}

// -----------------------------------------------------------------
.c-career_cta_message {
  position: relative;

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      @include objFitCover;
      object-position: right 15%;
      @include mq-up(768) {
        object-position: center 15%;
      }
    }
  }

  &__inner {
    display: block flex;
    flex-direction: column;
    justify-content: space-between;
    block-size: 324px;
    padding-block-start: 30px;
    padding-block-end: 30px;
    padding-inline: 20px;
    // background-color: rgba(red, 0.3);

    @include mq-up(768) {
      flex-direction: row;
      column-gap: 10px;
      block-size: 282px;
      padding-block-start: 40px;
      padding-block-end: 30px;
      padding-inline-start: clamp(30px, calc(60 / 1366 * 100vw), 60px);
      padding-inline-end: 40px;
    }
  }

  &__info {
    display: block grid;
    align-content: start;
    row-gap: 15px;
  }

  &__label {
    inline-size: fit-content;
  }

  &__btn {
    max-inline-size: 335px;
    inline-size: 100%;
    margin-inline: auto;
    @include mq-up(480) {
      margin-inline: 0;
    }
    @include mq-up(768) {
      align-self: flex-end;
      inline-size: 266px;
    }
  }
}

// -----------------------------------------------------------------
.c-career_cta_talk {
  position: relative;

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      @include objFitCover;
      object-position: center 15%;
    }
  }

  &__inner {
    display: block flex;
    flex-direction: column;
    justify-content: space-between;
    block-size: 324px;
    padding-block-start: 30px;
    padding-block-end: 30px;
    // background-color: rgba(red, 0.3);
    @include mq-up(768) {
      block-size: 282px;
      padding-block-start: 40px;
      padding-block-end: 30px;
    }
  }

  &__info {
    display: block grid;
    // row-gap: 20px;
    margin-inline: auto;
  }

  &__label {
    inline-size: fit-content;
    margin-inline: auto;
  }

  &__subtitle {
    margin-block-start: 10px;
    margin-block-end: 5px;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    color: #000;
    @include mq-up(768) {
      margin-block-start: 5px;
      font-size: 16px;
      margin-block-end: 0;
    }
  }

  &__btn {
    max-inline-size: 335px;
    inline-size: 100%;
    margin-inline: auto;
    margin-block-start: 10px;
    @include mq-up(480) {
      inline-size: 266px;
    }
    @include mq-up(768) {
      inline-size: 266px;
    }
  }
}
