
.c_labLink {
  text-decoration: underline;
  transition: text-decoration 300ms;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}