// -----------------------------------------------------------------
.p-career_index_hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  block-size: 440px;
  padding-block-start: 150px;
  text-align: center;
  @include mq-up(768) {
    block-size: 560px;
    padding-block-start: 90px;
  }

  &__title {
    padding-inline: 20px;
  }

  &__logo {
    display: block grid;
    justify-items: center;
    row-gap: 14px;
    margin-block: 0;
    @include mq-up(768) {
      row-gap: 18px;
    }

    .__cube {
      inline-size: 58px;
      @include mq-up(768) {
        inline-size: 76px;
      }

      --delay: 0s;
      opacity: 0;
      translate: 0 10px;
      scale: 0.78;
      .js-animsition-end & {
        opacity: 1;
        translate: 0 0;
        scale: 1;
        transition: opacity 0.6s $easeOutCubic var(--delay),
          translate 0.6s $easeOutBack var(--delay),
          scale 0.6s $easeOutBack var(--delay);
      }

      .js-flag {
        opacity: 0;
        .js-animsition-end & {
          animation: logoFlag 360ms 1600ms forwards
            cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
      }
    }
    .__logo {
      inline-size: 220px;
      @include mq-up(768) {
        inline-size: 290px;
      }

      .char {
        --base_delay: 0.4s;
        --delay: 0s;
        opacity: 0;
        // translate: 0 10px;
        scale: 0.82;
        transform-origin: center;
        .js-animsition-end & {
          opacity: 1;
          translate: 0 0;
          scale: 1;
          transition: opacity 0.6s $easeOutCubic
              calc(var(--base_delay) + var(--delay)),
            translate 0.6s $easeOutCubic calc(var(--base_delay) + var(--delay)),
            scale 0.6s $easeOutCubic calc(var(--base_delay) + var(--delay));
        }
      }

      .char1 {
        --delay: 0.1s;
      }

      .char2 {
        --delay: 0.2s;
      }

      .char3 {
        --delay: 0.3s;
      }

      .char4 {
        --delay: 0.4s;
      }
    }
    .__text {
      inline-size: 132px;
      @include mq-up(768) {
        inline-size: 172px;
      }

      --delay: 1.1s;
      opacity: 0;
      translate: 0 10px;
      .js-animsition-end & {
        opacity: 1;
        translate: 0 0;
        scale: 1;
        transition: opacity 0.8s $easeOutCubic var(--delay),
          scale 0.8s $easeOutCubic var(--delay),
          translate 0.8s $easeOutCubic var(--delay);
      }
    }
  }

  &__btn {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    inline-size: 188px;
    @include mq-up(768) {
      inline-size: 260px;
    }
  }
}

// -----------------------------------------------------------------
.p-career_index_career_mentoring {
  --opacity: 0.07;
  --svg_fill: #000;

  &.is-intersection_effect {
    --opacity: 0.5;
    --svg_fill: #fff;
  }

  &.is-intersection_cta {
    --opacity: 0;
  }

  @include mq-down(768) {
    display: none;
  }

  position: fixed;
  z-index: 1;
  inset-block-start: 480px;
  inline-size: 100%;
  padding-inline: 40px;
  pointer-events: none;
  opacity: var(--opacity);
  transition: opacity 0.4s $easeOutCubic;

  &__inner {
    --delay: 1.8s;
    opacity: 0;
    translate: 0 10px;
    // scale: 1.1;
    .js-animsition-end & {
      opacity: 1;
      translate: 0 0;
      scale: 1;
      transition: opacity 0.6s $easeOutCubic var(--delay),
        translate 0.6s $easeOutCubic var(--delay),
        scale 0.6s $easeOutCubic var(--delay);
    }

    @include mq-up(768) {
      display: block flex;
      align-items: flex-start;
      justify-content: center;
      column-gap: 25px;
    }

    svg {
      fill: var(--svg_fill);
      opacity: var(--svg_opacity);
      transition: fill 0.4s $easeOutCubic;

      &.__career {
        inline-size: 454px;
      }
      &.__mentoring {
        inline-size: 680px;
        margin-block-start: 3px;
      }
    }
  }
}

// -----------------------------------------------------------------
.p-career_index_aiming {
  position: relative;
  z-index: 1;
  max-inline-size: 1366px;
  margin-inline: auto;
  padding-block-end: 80px;
  padding-inline: 20px;
  box-sizing: initial;

  @include mq-up(768) {
    padding-block-end: 100px;
  }

  &__outer {
  }

  &__head {
    .c-career_dots {
      margin-block-start: 20px;
    }

    @include mq-up(768) {
      br {
        display: block;
      }
      .c-career_dots {
        margin-block-start: 35px;
      }
    }
    @include mq-up(1024) {
      br {
        display: none;
      }
    }
  }

  &__logo {
    display: grid;
    place-items: center;
    row-gap: 5px;
    margin-block-start: 50px;
    @include mq-up(768) {
      display: none;
    }

    svg {
      fill: #000;
      opacity: 0.07;

      &.__career {
        inline-size: 217px;
      }
      &.__mentoring {
        inline-size: 325px;
      }
    }
  }

  &__body {
    margin-block-start: 50px;
    @include mq-up(768) {
      margin-block-start: 60px;
    }
  }

  &__side {
    @include mq-down(768) {
      display: none;
    }
    position: absolute;
    display: block grid;
    row-gap: 10px;

    &._start {
      inset-block-start: 190px;
      inset-inline-end: 20px;
    }
    &._end {
      inset-block-end: -90px;
      inset-inline-start: 20px;
    }

    li {
      // max-inline-size: 260px;
      aspect-ratio: 1;
      contain: paint;
      // overflow: hidden;
      clip-path: inset(50% round 10px);
      transition: clip-path 0.8s $easeOutCubic;
      @include mq-up(768) {
        max-inline-size: calc(260 / 1280 * 100vw);
      }
      @include mq-up(1280) {
        max-inline-size: 260px;
      }

      &.is-show_active {
        clip-path: inset(0% round 10px);
        img {
          scale: 1;
        }
      }
    }

    img {
      @include objFitCover;
      scale: 1.2;
      transition: scale 0.8s $easeOutCubic;
    }
  }

  &__center {
    // margin-block-start: 60px;
    margin-inline: -20px;
    @include mq-up(768) {
      display: none;
    }
    &.is-show_active li {
      clip-path: inset(0% round 10px);
      img {
        scale: 1;
      }
    }

    li {
      max-inline-size: 260px;
      aspect-ratio: 1;
      contain: paint;
      clip-path: inset(50% round 10px);
      transition: clip-path 0.8s $easeOutCubic;
    }

    img {
      @include objFitCover;
      scale: 1.2;
      transition: scale 0.8s $easeOutCubic;
    }
  }

  &__center_list {
    inline-size: 140px;
    aspect-ratio: 1;
  }

  &__text {
    display: block grid;
    row-gap: 1lh;
    font-size: 18px;
    font-weight: 700;
    line-height: calc(42/18);
    @include mq-up(768) {
      max-inline-size: calc(750 / 1280 * 100vw);
      margin-inline: auto;
      padding-inline: 25px;
      text-align: center;
    }
    @include mq-up(1280) {
      max-inline-size: initial;
    }

    > p {
      margin-block-end: 0;
    }
  }

  &__awards {
    display: block grid;
    row-gap: 20px;
    margin-block-start: 100px;
  }

  &__awards_title {
    display: block flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    margin-block-end: 0;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    &::before,
    &::after {
      content: "";
      inline-size: 100px;
      block-size: 1px;
      background-color: rgba(#000, 0.2);
    }
  }

  &__awards_list {
    display: block grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    column-gap: 50px;
    padding-inline: 10px;
    @include mq-up(768) {
      grid-template-columns: repeat(2, 150px);
    }
  }
}

// -----------------------------------------------------------------
.p-career_index_effect {
  position: relative;
  padding-block-start: 40px;
  padding-block-end: 60px;
  @include mq-up(768) {
    padding-block-start: 110px;
    padding-block-end: 100px;
  }

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: 0.1;

    img {
      @include objFitCover;
    }
  }

  &__head {
    position: relative;
    z-index: 1;
  }

  &__lead {
    position: relative;
    z-index: 1;
    box-sizing: initial;
    max-inline-size: 800px;
    margin-inline: auto;
    margin-block-start: 40px;
    padding-inline: 20px;
  }

  &__body {
    position: relative;
    z-index: 1;
    box-sizing: initial;
    max-inline-size: 1068px;
    margin-inline: auto;
    padding-inline: 20px;
  }

  &__cols {
    display: block grid;
    row-gap: 50px;
    margin-block-start: 50px;
    @include mq-up(768) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 40px;
      margin-block-start: 80px;
    }
  }

  &__btn {
    position: relative;
    z-index: 1;
    max-inline-size: 330px;
    margin-inline: auto;
    margin-block-start: 50px;
    @include mq-up(768) {
      margin-block-start: 40px;
    }
  }
}

// -----------------------------------------------------------------
.p-career_index_diverse {
  padding-block-start: 80px;
  padding-block-end: 70px;
  @include mq-up(768) {
    padding-block: 110px;
  }

  &__head {
    position: relative;
    z-index: 1;
  }

  &__lead {
    position: relative;
    z-index: 1;
    box-sizing: initial;
    max-inline-size: 800px;
    margin-inline: auto;
    margin-block-start: 30px;
    padding-inline: 20px;
    @include mq-up(768) {
      margin-block-start: 40px;
    }
  }

  &__body {
    position: relative;
    z-index: 1;
    box-sizing: initial;
    max-inline-size: 912px;
    margin-inline: auto;
    padding-inline: 20px;
  }

  &__cols {
    display: block grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px 20px;
    margin-block-start: 30px;
    @include mq-up(768) {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 50px;
      margin-block-start: 48px;
    }
  }
}

// -----------------------------------------------------------------
.p-career_index_knowledge_share {
  position: relative;
  margin-block-start: 10px;
  padding-block-start: 40px;
  padding-block-end: 30px;
  @include mq-up(768) {
    padding-block-start: 110px;
    padding-block-end: 40px;
  }

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      opacity: 0.1;
      @include objFitCover;
      object-position: center top;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: rgba(#000, 0.05);
      mix-blend-mode: multiply;
    }
  }

  &__body {
    box-sizing: initial;
    max-inline-size: 1068px;
    margin-inline: auto;
    margin-block-start: 40px;
    padding-inline: 20px;
  }

  &__cols {
    display: block grid;
    grid-template-columns: 1fr;
    column-gap: 40px;
    margin-block-start: 60px;

    @include mq-up(540) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq-up(768) {
      grid-template-columns: repeat(3, 1fr);
    }

    // row-gap: 100px;
    // なぜか subgrid で、gap の解除がうまくできないので…
    > * {
      @include mq-down(540) {
        &:nth-child(n + 2) {
          margin-block-start: 60px;
        }
      }
      @include mq-up(540) {
        &:nth-child(n + 3) {
          margin-block-start: 60px;
        }
      }
      @include mq-up(768) {
        &:nth-child(n + 3) {
          margin-block-start: 0;
        }
        &:nth-child(n + 4) {
          margin-block-start: 100px;
        }
      }
    }

    &[data-cols="2"] {
      @include mq-up(768) {
        // grid-template-columns: repeat(2, unquote("min(calc(328/1024*100vw), 328px)"));
        grid-template-columns: repeat(2, 328px);
        justify-content: center;
      }
    }
  }

  &__bnr {
    max-inline-size: 1068px;
    margin-inline: auto;
    padding-block-start: 60px;

    @include mq-up(768) {
      padding-block-start: 80px;
    }


    .p_bnrArea {
      img[style] {
        width: auto !important;
      }
    }
  }
}

// -----------------------------------------------------------------
.p-career_index_possibility {
  padding-block-start: 80px;
  padding-block-end: 80px;
  @include mq-up(768) {
    padding-block-start: 100px;
    padding-block-end: 110px;
  }

  &__inner {
    box-sizing: initial;
    max-inline-size: 1068px;
    margin-inline: auto;
    padding-inline: 20px;
  }

  &__cols {
    display: block grid;
    grid-template-columns: repeat(2, 156px);
    justify-content: center;
    column-gap: 20px;
    row-gap: 24px;
    margin-block-start: 60px;
    @include mq-down(768) {
      > * {
        &:nth-child(3) {
          grid-area: 2/1/3/3;
          inline-size: 156px;
          margin-inline: auto;
        }
      }
    }
    @include mq-up(768) {
      grid-template-columns: repeat(3, 190px);
      column-gap: 40px;
      margin-block-start: 90px;
    }
  }

  &__lead {
    margin-block-start: 30px;
    @include mq-up(768) {
      margin-block-start: 48px;
    }
  }
}

// -----------------------------------------------------------------
.p-career_index_operating_company {
  box-sizing: initial;
  max-inline-size: 1068px;
  margin-inline: auto;
  margin-block-end: 80px;
  padding-inline: 20px;
  @include mq-up(768) {
    margin-block-end: 100px;
  }

  &__outer {
    border: 4px solid #eee;
    border-radius: 20px;
    @include mq-up(768) {
      border-width: 8px;
    }
  }

  &__inner {
    max-inline-size: 880px;
    margin-inline: auto;
    padding: 35px 24px 30px;
    @include mq-up(768) {
      padding: 60px 40px 68px;
    }
  }

  &__head {
    display: block grid;
    row-gap: 15px;
  }

  &__body {
    display: block grid;
    row-gap: 30px;
    margin-block-start: 30px;
    @include mq-up(768) {
      row-gap: 42px;
      margin-block-start: 50px;
    }
  }

  &__lead {
    margin-block-end: 0;
    font-size: 15px;
    font-weight: 500;
  }

  &__logos {
    display: block flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    column-gap: 24px;
    row-gap: 32px;
    margin-block-end: 0;
    @include mq-up(768) {
      display: block grid;
      align-items: end;
      justify-items: center;
      grid-template-columns: repeat(4, auto);
      column-gap: 40px;
      padding-inline: 20px;
    }

    li {
      block-size: 26px;
      @include mq-up(768) {
        block-size: auto;
      }

      img {
        block-size: 100%;
        inline-size: auto;
      }
    }
  }

  &__info {
    display: block grid;
    row-gap: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.75;
  }
}
