.c_catHeader {
  $this: &;
  margin-bottom: 70px;
  background-position: center;
  background-size: cover;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    @include sp {
      height: 200px;
    }
  }
  &__ttl {
    text-align: center;
    @include sp {
      margin-top: 0;
    }
    > span {
      display: inline-block;
      position: relative;
      padding: 0.375em 20px;
      color: #ffffff;
      font-size: 2.25rem;
      letter-spacing: 0.2em;
      @include sp {
        padding: 0.25em 10px;
        font-size: 1.5rem;
      }
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 160px;
        height: 4px;
        margin: auto;
        background-image: $gradient01;
        @include sp {
          height: 3px;
        }
      }
    }
  }
  &.bg1 {
    background-image: url(../img/pic_catHeader01@pc.jpg);
    @include sp {
      background-image: url(../img/pic_catHeader01@sp.jpg);
    }
  }
  &.bg2 {
    background-image: url(../img/pic_catHeader02@pc.jpg);
    @include sp {
      background-image: url(../img/pic_catHeader02@sp.jpg);
    }
  }
  &.bg3 {
    background-image: url(../img/pic_catHeader03@pc.jpg);
    @include sp {
      background-image: url(../img/pic_catHeader03@sp.jpg);
    }
  }
  &.bg4 {
    background-image: url(../img/pic_catHeader04@pc.jpg);
    @include sp {
      background-image: url(../img/pic_catHeader04@sp.jpg);
    }
  }
  &.bg5 {
    background-image: url(../img/pic_catHeader05@pc.jpg);
    @include sp {
      // margin-bottom: 25px;
      background-image: url(../img/pic_catHeader05@sp.jpg);
    }
  }

  &.--form {
    background-color: #f5f5ed;

    #{$this}__inner {
      height: 192px;
    }

    #{$this}__ttl > span {
      padding-top: 0;
      padding-bottom: 24px;
      color: #444;
      font-size: 28px;
      line-height: calc(50 / 28);
      letter-spacing: 0.05em;

      @include sp {
        font-size: 24px;
      }

      &:before {
        width: 250px;

        @include sp {
          width: 163px;
        }
      }
    }
  }

  &.--center {
    #{$this}__ttl {
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        row-gap: 20px;
        padding-block-start: 27px;
        @include sp {
          padding-block-start: 42px;
        }
        &::before {
          position: static;
        }
      }
    }
  }
}

.c_catMv {
  &__inner {
    position: relative;
    .c_btn__wrap.--absolute {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      transform: translateY(50%);
    }
  }
  &__ttl {
    margin: 0;
    @include sp {
      position: relative;
      padding-top: calc((453 / 375 * 100vw));
      overflow: hidden;
    }
    img {
      width: 100%;
      @include sp {
        position: absolute;
        top: 0;
        left: 50%;
        width: auto;
        max-width: none;
        height: calc((453 / 375 * 100vw));
        transform: translateX(-50%);
      }
    }
  }

  &.--fes2023 .c_catMv__ttl {
    margin-bottom: 72px;
    @include sp {
      margin-bottom: 36px;
      padding-top: calc((423 / 375 * 100vw));
      img {
        height: calc((423 / 375 * 100vw));
      }
    }
  }

  &.--fes2024 .c_catMv__ttl {
    margin-bottom: 72px;
    @include sp {
      margin-bottom: 36px;
      padding-top: calc((383 / 375 * 100vw));
      img {
        height: calc((383 / 375 * 100vw));
      }
    }
  }
}

.c_catHeader--case {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  margin-bottom: 20px;
  background-color: #ff0000;
  overflow: hidden;

  .p-case__mv {
    width: 100%;
    height: 100%;
    margin: 0;
    text-align: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .c_catHeader__inner {
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    @include sp {
      height: 100%;
      padding: 0 20px;
    }
  }

  .c_catHeader__ttl {
    margin: 0 0 1.5em;
    @include sp {
      margin-bottom: 1em;
    }

    > span {
      font-size: 1.25rem;
    }
  }

  .p-case__ttl {
    margin-bottom: 0.75em;
    color: #ffffff;
    font-size: 1.875rem;
    font-weight: bold;
    text-align: center;
    @include sp {
      line-height: 1.75;
      font-size: 1.5rem;
    }
  }

  .p-case__tag i {
    padding: 2px 0;
    font-size: 0.875rem;
  }
}
