.c-section-ttl {
  margin-top: 0;
  margin-bottom: 60px;
  line-height: 1.75;
  text-align: center;
  @include sp {
    margin-bottom: 40px;
  }

  > span {
    display: inline-block;
    position: relative;
    padding: 0.5em 20px;
    font-size: 2.25rem;
    @include sp {
      padding: 1em 0;
      font-size: 1.5rem;
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 250px;
      height: 4px;
      background-image: $gradient01;
      transform: translateX(-50%);
    }
  }

  .__smaller {
    font-size: 1.75rem;

    @include sp {
      font-size: 1.375rem;
    }
  }
}