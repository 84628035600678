.u_alL { text-align: left !important; }
.u_alC { text-align: center !important; }
.u_alR { text-align: right !important; }
@include sp {
  .u_alLsp { text-align: left !important; }
  .u_alCsp { text-align: center !important; }
  .u_alRsp { text-align: right !important; }
}

.u_valT { vertical-align: top !important; }
.u_valM { vertical-align: middle !important; }
.u_valB { vertical-align: bottom !important; }
@include sp {
  .u_valTsp { vertical-align: top !important; }
  .u_valMsp { vertical-align: middle !important; }
  .u_valBsp { vertical-align: bottom !important; }
}
