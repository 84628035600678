// -----------------------------------------------------------------
.l-main_basic {
  position: relative;

  &__inner {
    box-sizing: initial;
    max-inline-size: 1068px;
    margin-inline: auto;
    padding-inline: 20px;
  }
}

// -----------------------------------------------------------------
.l-main_article {
  position: relative;

  &__inner {
    box-sizing: initial;
    max-inline-size: 900px;
    margin-inline: auto;
    padding-inline: 20px;
  }
}

// -----------------------------------------------------------------
.l-main_talk {
  position: relative;

  &__shapes {
    @include mq-up(768) {
      display: none;
    }
  }

  &__inner {
    box-sizing: initial;
    max-inline-size: 900px;
    margin-inline: auto;
    padding-inline: 20px;
    margin-block-start: 70px;
  }
}

// -----------------------------------------------------------------
.l-main_knowledge_case {
  position: relative;
  display: grid;
  align-items: start;
}
