.p-fes2021 {

  &__schCol {

    &__item {
      display: flex;
      @include sp {
        display: block;
      }

      .__dt {
        flex: 0 0 auto;
        display: flex;
        justify-content: space-between;
        width: 7.25em;
        @include sp {
          display: inline;
          width: auto;
        }

        &::after {
          content: '：';
        }
      }

      .__dd {
        flex: 1 1 auto;
      }
    }
  }

  &__table {

    th, td {
      padding: 11px 0;
      border-top: 1px solid #D0D0D0;
      border-bottom: 1px solid #D0D0D0;
      font-size: 0.875rem;
      font-weight: bold;
      vertical-align: top;

      &:not(:last-child) {
        @include sp {
          padding-right: 1em;
        }
      }
    }

    .__lv1 {
      background-color: #F5F5ED;
    }
  }

  &__mention {

    &__ttl {
      margin-bottom: 1em;
      font-size: 1rem;
      font-weight: bold;
    }

    &__upper {

      &:not(:last-child) {
        margin-bottom: 1em;
        padding-bottom: 2em;
        border-bottom: 1px solid #D0D0D0;
      }
    }
  }

  &__catch {
    padding: 40px;
    background-color: #ffffff;
    @include sp {
      padding: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    &__ttl {
      font-size: 1.125rem;
      font-weight: bold;
      text-align: center;
      @include sp {
        font-size: 1rem;
        text-align: left;
      }
    }

    &__tape {
      text-align: center;
      > span {
        display: inline-block;
        align-items: center;
        padding: 6px 1em;
        background-color: #ABCD03;
        line-height: 1.5em;
        color: #ffffff;
        font-size: 1rem;
        @include sp {
          font-size: 0.875rem;
        }
      }
    }

    &__hd {
      margin-bottom: 1.25em;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
    }

    &__list {
      display: inline-block;
      max-width: 816px; // !

      &__wrap {
        display: flex;
        justify-content: center;
      }

      > li {
        display: flex;
        align-items: flex-start;

        &:not(:last-child) {
          margin-bottom: 1.125em;
        }

        i {
          display: inline-block;
          flex: 0 0 auto;
          width: 1em;
          height: 2em;
          margin-right: 1em;
          background-image: url(../img/ico_hito01.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }

        span {
          display: block;
          flex: 1 1 auto;
        }
      }
    }
  }
}

.p-fes {

  &__hdg2 {
    font-size: 1.625rem;
    text-align: center;

    &:first-child {
      margin-top: 0;
    }
  }

  &__award {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    gap: 60px;
    @include sp {
      grid-template-columns: 47% 44%;
      justify-content: space-between;
      gap: 0;
    }

    &__item {

      &__inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        text-decoration: none;
        text-align: center;

        &:hover {
          color: inherit;
          text-decoration: none;

          .p-fes__award__item__pic {
            opacity: 0.7;
          }
        }
      }

      &__pic {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 176px;
        transition: opacity 300ms;
        @include sp {
          height: 136px;
        }
      }

      &__txt {
        flex: 0 0 auto;
        margin-top: 24px;
        font-size: 1rem;
        @include sp {
          font-size: 0.6875rem;
        }
      }
    }
  }
}

.p-fes__colorClm {
  display: flex;
  align-items: center;
  padding: 11px 16px;
  background-color: #F5F5ED;
  line-height: calc(22/14);
  @include sp {
    flex-direction: column;
    align-items: flex-start;
  }

  + .p-fes__colorClm {
    margin-top: 10px;
  }

  &__dt {
    flex: 0 0 auto;
  }

  &__dd {
    margin-left: 28px;
    @include sp {
      margin-top: 10px;
      margin-left: 0;
    }
  }
}
