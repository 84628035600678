// -----------------------------------------------------------------
.p-career_anchor_nav {
  margin-inline: auto;
  padding: 20px 20px 30px;
  background-color: #eee;
  @include mq-up(768) {
    padding-block: 35px;
    padding-inline-start: unquote("min(calc(90/1366*100vw), 90px)");
    padding-inline-end: 35px;
  }

  &__index {
    margin-block-end: 0;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    color: #1cb2c6;
  }

  &__list {
    display: block grid;
    row-gap: 5px;
    margin-block-start: 10px;
    @include mq-up(768) {
      row-gap: 10px;
    }

    a {
      display: block flex;
      column-gap: 15px;
      line-height: 1.75;
      text-decoration: none;

      .__num {
        margin-block-end: 0;
        font-family: "Hind", sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: #1cb2c6;
      }

      .__text {
        margin-block-end: 0;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.05em;
        color: #222;
        @include group-hover {
          text-decoration: underline;
          text-decoration-thickness: 1px;
          text-underline-offset: 3px;
        }
      }
    }
  }
}
