// * {
//   outline: 1px solid tomato;
// }

:root {
  --header-block-size: 60px;
  @include mq-up(1024) {
    --header-block-size: 64px;
  }
}

.l-career_wrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-block-size: 100vh;
  // overflow-x: clip;
  color: #222;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}
