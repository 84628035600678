// -----------------------------------------------------------------
.c_movie {
  position: relative;
  &__icon {
    position: absolute;
    margin: auto;
    inset: 0;
    z-index: 1;
    border: none;
    cursor: pointer;
  }
  &:after {
    content: "";
    background-color: rgba(#444, .6);
    position: absolute;
    margin: auto;
    inset: 0;
  }

  &__modal {
    position: fixed;
    background-color: rgba(#444, .9);
    opacity: 0;
    pointer-events: none;
    transition: all .3s;
    margin: auto;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    z-index: 100000;
    justify-content: center;
    align-items: center;
    &.is-open {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__modal_container {
    iframe {
      width: 100%;
      height: 100%;
    }
    margin: auto;
    inset: 0;
    max-width: 740px;
    width: 100%;
    max-height: 414px;
    aspect-ratio: 740/414;
    // position: relative;
    padding: 20px;
  }

  &__modal_close {
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    transform: rotate(45deg);

    @include sp {
      top: 20px;
      right: 20px;
      width: 35px;
      height: 35px;
    }
  }
}