.p_lab {
  $this: &;

  &__sec {
    padding: 100px 20px;

    &.--about {
      position: relative;
      padding: 80px 0 60px;

      @include pc {
        padding: 120px 20px 80px;
        background-image: url(../../assets/img/bg_lab_about.png);
        background-size: cover;
      }

      &:before {
        @include pc {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(#000,.1);
        }
      }
    }

    &.--pale_yellow {
      background-color: $pale_yellow;
      padding-top: 40px;
      padding-bottom: 60px;

      @include pc {
        padding-top: 80px;
        padding-bottom: 90px;
      }
    }
  }

  &__sec_inner {
    max-width: 920px;
    margin: 0 auto;

    &.--large {
      max-width: 1070px;
    }

    &.--small {
      max-width: 850px;
    }
  }

  &__sec_title_wrap {
    text-align: center;
  }

  &__sec_title {
    color: $ttl_color;
    position: relative;
    margin: 0;
    padding-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: $letter_spacing_small;
    line-height: 1.5;

    @include pc {
      padding-bottom: 28px;
      font-size: 36px;
    }

    .__bar {
      display: block;
      margin: -14px auto 0;
    }

    .__bar_inner {
      display: inline-block;

      &:before {
        content: "";
        display: inline-block;
        width: 168px;
        height: 3px;
        background-image: $gradient01;

        @include pc {
          height: 4px;
          width: 255px;
        }
      }
    }
  }

  &__sec_title_sub {
    font-size: 20px;
    display: block;
    margin-bottom: 8px;

    @include pc {
      font-size: 28px;
    }
  }

  &__about_box {
    background-color: #fff;
    position: relative;

    & + & {
      margin-top: 20px;
    }

    &.--first {
      padding: 0;

      @include pc {
        padding-top: 96px;
        padding-bottom: 80px;
      }

      #{$this}__about_box_inner {
        padding: 0 20px;

        @include pc {
          max-width: 648px;
          padding: 0;
        }
      }
    }

    &.--second {
      padding: 20px;

      @include pc {
        padding: 40px 20px;
      }

      #{$this}__about_box_inner {
        max-width: 732px;

        @include pc_l {
          display: grid;
          grid-template-columns: 387px 1fr;
          gap: 60px;
        }
      }
    }
  }

  &__about_box_inner {
    margin: 0 auto;
  }

  &__about_box_title {
    letter-spacing: .1em;
    width: 100%;
    text-align: center;
    font-size: 22px;
    margin: 0;

    @include pc {
      font-size: 26px;
      position: absolute;
      top: -67px;
    }

    .__ico {
      height: 34px;
    }

    .__bar {
      width: 100%;
      margin: 0 !important;
    }

    .__txt .__block {
      height: 80px;

      @include pc {
        min-width: 450px;
        max-width: 450px;
        height: 65px;
      }

      b {
        line-height: 80px;

        @include pc {
          line-height: 65px;
        }
      }
    }
  }

  &__about_box_top_img {
    margin: 0 -20px;
    width: 100vw;
    max-width: inherit;

    @include pc {
      display: none;
    }
  }

  &__about_box_desc {
    color: $ttl_color;
    margin: 0;
    margin-top: 45px;
    line-height: 2;
    letter-spacing: .03em;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: $sky_blue;
    text-decoration-thickness: 3px;
    text-underline-offset: 10px;

    @include pc {
      margin-top: 0;
      line-height: inherit;
      letter-spacing: .08em;
      font-size: 24px;
      text-align: left;
    }

    span {
      display: inline-block;
      text-decoration: underline;
      text-decoration-color: $sky_blue;
      text-decoration-thickness: 3px;
      text-underline-offset: 10px;
    }
  }

  &__about_box_desc_sub {
    color: $ttl_color;
    letter-spacing: .08em;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
    margin-top: 40px;
    line-height: $lab_lh_large;

    @include pc {
      margin-top: 18px;
      font-size: 20px;
      text-align: left;
      line-height: inherit;
    }
  }

  &__about_box_txt {
    font-size: 15px;
    line-height: 2.4;
    letter-spacing: .08em;
    margin-top: 30px;
    margin-bottom: 0;

    @include pc {
      margin-top: 40px;
    }
  }

  &__about_box_img_group {
    display: grid;
    gap: 20px;
    margin-top: 34px;
    justify-content: center;

    img:first-child {
      display: none;

      @include pc {
        display: block;
      }
    }

    @include pc_l {
      margin-top: 53px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__about_box_award_group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-left: 14px;
    margin-right: 8px;


    @include pc_l {
      grid-template-columns: 160px 1fr;
      gap: 60px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__about_box_award {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    @include pc_l {
      gap: 12px;
    }

    &.--award01 {
      img {
        height: 140px;
        object-fit: contain;
      }
    }

    &.--award02 {
      img {
        height: 114px;
        object-fit: contain;
        vertical-align: top;
        margin-top: 19px;
        margin-bottom: 7px;
      }
    }

    p {
      font-size: 11px;
      letter-spacing: $letter_spacing_small;
      line-height: $lab_lh_default;
      text-align: center;

      @include pc {
        font-size: 14px;
      }
    }
  }

  &__about_box_award_txt_group {
    line-height: $lab_lh_default;
    color: $ttl_color;
  }

  &__about_box_award_txt_group h3 {
    margin-top: 29px;
    margin-bottom: 16px;
    font-size: 16px;
    text-align: center;

    @include pc_l {
      text-align: left;
      margin-top: 14px;
      margin-bottom: 18px;
    }
  }

  &__merit {
    text-align: center;
  }

  &__merit_label {
    background-color: $gray;
    width: 100%;
    height: 40px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: .1em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 47px;

    @include pc {
      font-size: 22px;
      max-width: 200px;
      margin-bottom: 36px;
    }
  }

  &__merit_box_group {
    display: grid;
    gap: 32px;
    position: relative;

    @include pc_l {
      gap: 16px;
      grid-template-columns: repeat(3, 1fr);
    }

    &:after {
      @include pc_l {
        content: "";
        display: inline-block;
        position: absolute;
        width: 100vw;
        height: 300px;
        left: 50%;
        transform: translateX(-50%);
        background-image: url(../../assets/img/bg_pattern01.png);
        background-repeat: repeat;
        animation: sectionBg 30000ms linear infinite;
        z-index: 0;
      }
    }
  }

  &__merit_box {
    z-index: 1;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 100vw;
      height: 208px;
      position: absolute;
      left: 50%;
      top: 17%;
      transform: translateX(-50%);
      background-image: url(../../assets/img/bg_pattern01.png);
      background-repeat: repeat;
      animation: sectionBg 30000ms linear infinite;
      background-size: contain;
      z-index: -1;

      @include pc_l {
        display: none;
      }
    }
  }

  &__merit_box > p {
    font-size: 17px;
    font-weight: bold;
    line-height: $lab_lh_large;
    letter-spacing: $letter_spacing_small;
    margin-top: 14px;

    @include pc {
      font-size: 20px;
      margin-top: 22px;
    }
  }

  &__merit_img {
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @include pc {
      width: 300px;
      height: 300px;
    }
  }

  &__activity_txt {
    width: 100%;
    min-height: 40px;
    line-height: $lab_lh_default;
    letter-spacing: $letter_spacing_default;
    margin: 0;
    color: #fff;
    font-weight: bold;
    background-color: $gray;
    text-align: center;
    font-size: 16px;
    padding: 20px 14px;

    @include pc {
      font-size: 18px;
      padding: 5px 16px;
    }
  }

  &__activity_box_top_group {
    border: 3px solid $light_gray;
    border-radius: 5px;
    padding: 23px 28px;

    @include pc {
      border: none;
      padding: 0;
    }
  }

  &__activity_box_area {
    margin-top: 42px;

    @include pc {
      margin-top: 78px;
    }

    picture {
      width: 100%;
      display: block;
      text-align: center;
    }
  }

  &__activity_box {
    text-align: center;
    position: relative;

    &:not(:last-child):after {
      content: "";
      display: inline-block;
      width: 16px;
      height: 40px;
      background-image: url(../../assets/img/lab_arrow_down.svg);
      background-size: contain;
      position: absolute;
      bottom: -36px;
      left: 50%;
      transform: translateX(-50%);

      @include pc {
        background-image: url(../../assets/img/lab_arrow_left.svg);
        width: 65px;
        height: 16px;
        top: 16%;
        bottom: inherit;
        right: -16%;
        transform: inherit;
        left: inherit;
      }
    }

    img {
      width: 190px;
      height: 190px;
      border-radius: 50%;

      @include pc {
        width: 210px;
        height: 210px;
      }
    }
  }

  &__activity_box_txt_group {
    color: $ttl_color;
    background-color: $pale_yellow;
    border-radius: 5px;
    padding: 75px 16px 20px;
    margin-top: -64px;

    @include pc {
      height: 259px;
      padding: 64px 36px 45px;
      margin-top: -40px;
    }

    h3, p {
      margin: 0;
    }

    h3 {
      font-size: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: $letter_spacing_default;
      line-height: $lab_lh_default;

      @include pc {
        font-size: 20px;
        height: 60px;
      }
    }

    p {
      text-align: left;
      font-size: 15px;
      line-height: $lab_lh_default;
      letter-spacing: $letter_spacing_default;

      @include pc {
        font-size: 16px;
      }
    }
  }

  &__activity_box_top_txt {
    color: $ttl_color;
    font-size: 15px;
    font-weight: bold;
    margin: 0 auto;
    position: relative;
    text-align: center;

    @include pc {
      font-size: 18px;
      background-image: url(../../assets/img/lab_arrow_group_top@pc.svg);
      background-size: contain;
      width: 740px;
      height: 40px;
    }

    p {
      @include pc {
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        width: 547px;
        background-color: #fff;
        padding: 0 12px;
        letter-spacing: $letter_spacing_default;
        text-align: center;
      }
    }
  }

  &__activity_box_group {
    display: grid;
    gap: 44px;
    position: relative;
    margin: 64px auto 0;
    max-width: 278px;

    @include pc {
      margin-top: 80px;
      max-width: inherit;
    }

    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 100%;
      width: 166px;
      top: -40px;
      right: -19px;
      background-image: url(../../assets/img/lab_arrow_group_top.svg);

      @include pc {
        display: none;
      }
    }

    @include pc {
      grid-template-columns: repeat(3, 1fr);
      gap: 35px;
      margin-top: 11px;
    }
  }

  &__activity_box_txt {
    &.--mt {
      margin-top: 16px;
    }
  }

  &__activity_txt_box {
    border: 3px solid $light_gray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 28px 26px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: $letter_spacing_default;

    @include pc {
      height: 94px;
      border-width: 5px;
      font-size: 20px;
      padding: 16px;
    }
  }

  &__profile_group {
    display: grid;
    gap: 60px;

    @include pc {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__profile {
    @include pc_l {
      display: grid;
      grid-template-columns: 200px 1fr;
      gap: 30px;
    }
  }

  &__profile_img_area {
    text-align: center;

    img {
      max-width: 200px;
      margin: 0 auto;
    }
  }

  &__profile_job {
    margin-top: 15px;
    letter-spacing: $letter_spacing_small;
    margin-bottom: 0;
    line-height: 1.6;

    @include pc_l {
      text-align: left;
    }
  }

  &__profile_name {
    font-size: 22px;
    letter-spacing: $letter_spacing_default;
    font-weight: bold;

    @include pc_l {
      text-align: left;
    }
  }

  &__profile_txt_area {
    margin-top: 16px;

    @include pc_l {
      margin-top: -8px;
    }
  }

  &__entry {
    text-align: center;
  }

  &__entry_desc {
    font-size: 16px;
    letter-spacing: $letter_spacing_default;
    margin: 0;
    text-align: left;

    @include pc {
      text-align: center;
    }
  }

  &__entry_box {
    z-index: 1;
    max-width: 1002px;
    margin: 64px -20px 0;
    background-color: $pale_yellow;
    padding: 50px 42px;
    position: relative;

    @include pc {
      padding: 44px 20px 42px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__entry_box_title {
    position: absolute;
    top: -19px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $light_black;
    padding: 6px;
    width: calc(100% - 40px);
    line-height: $lab_lh_default;
    letter-spacing: $letter_spacing_default;
    margin: 0;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 15px;

    @include pc {
      top: -13px;
      width: 285px;
      height: 29px;
      line-height: 29px;
      padding: 0;
      font-size: 14px;
    }
  }

  &__entry_txt_group {
    max-width: 765px;
    margin: 0 auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 29px;

    h4, p, ul {
      margin: 0;
    }
  }

  &__entry_btn_area {
    background-image: url(../../assets/img/bg_lab_entry.png);
    background-size: cover;
    height: 250px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
    margin-right: -20px;

    @include pc {
      background-image: url(../../assets/img/bg_lab_entry@pc.png);
      margin-top: -61px;
      padding-top: 50px;
      padding-left: 0;
      padding-right: 0;
      height: 300px;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(#000, .5);
    }

    p {
      margin: 0;
    }
  }

  &__entry_btn_desc {
    z-index: 1;
    color: #fff;
    font-weight: bold;
    font-size: 17px;
    line-height: $lab_lh_large;
    letter-spacing: $letter_spacing_default;

    @include pc {
      font-size: 20px;
    }
  }

  &__entry_btn {
    z-index: 1;
    margin-top: 20px;
  }

  &__entry_withdrawal {
    padding: 34px 20px 0;
    text-align: center;

    @include pc {
      padding-top: 50px;
    }

    h3, p {
      margin: 0;
    }

    h3 {
      color: $ttl_color;
      font-weight: bold;
      font-size: 20px;
      letter-spacing: .1em;
    }

    p {
      margin-top: 12px;
      letter-spacing: $letter_spacing_default;
    }
  }
}

.js_ttl {
  overflow: hidden;
  display: inline-block;

  .__bar {
    overflow: hidden;
    display: inline-block;
  }

  &.js-ttl-animate {
    animation-name: slideTextX100;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;

    .__txt {
      animation-name: slideTextX-100;
      animation-duration: 1000ms;
      animation-fill-mode: forwards;
    }

    .__bar {
      animation-name: slideTextX100;
      animation-duration: 500ms;
      animation-fill-mode: forwards;
      animation-delay: 800ms;
    }

    .__bar_inner {
      animation-name: slideTextX-100;
      animation-duration: 500ms;
      animation-fill-mode: forwards;
      animation-delay: 800ms;
    }
  }
  .__txt, .__bar_inner {
    opacity: 0;
  }

  .__txt {
    display: inline-block;
    width: 100%;
  }
}

@keyframes slideTextX100 {
  from {
  transform: translateX(-100%); /*要素を左の枠外に移動*/
  opacity: 0;
  }

  to {
  transform: translateX(0);/*要素を元の位置に移動*/
    opacity: 1;
  }
}

@keyframes slideTextX-100 {
  from {
  transform: translateX(100%);/*要素を右の枠外に移動*/
    opacity: 0;
  }

  to {
  transform: translateX(0);/*要素を元の位置に移動*/
    opacity: 1;
  }
}

.p_labBlock {
  position: relative;
  isolation: isolate;
  @include pc {
    padding: 70px 40px;
    background-image: url(../img/bg_labBlock_01@pc.jpg);
    background-size: cover;
    background-position: center;
  }

  &::before, &::after {
    content: '';
    display: block;
    background-size: cover;
    background-position: center;
    aspect-ratio: 750 / 240;
    @include pc {
      display: none;
    }
  }

  &::before {
    background-image: url(../img/bg_labBlock_01@sp.jpg);
  }

  &::after {
    background-image: url(../img/bg_labBlock_02@sp.jpg);
  }

  &__inner {
    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      z-index: 0;
      background-color: rgba(#000, .5);
    }
  }

  &__box {
    position: relative;
    margin: 0 20px;
    padding: 34px 22px 40px;
    background-color: #fff;
    font-weight: bold;
    @include pc {
      width: 744px;
      margin: 0 auto;
      padding: 40px 64px 44px;
    }
  }

  &__ttl {
    margin: 0 10px 36px;
    text-align: center;
  }

  &__lead {
    margin-left: -22px;
    margin-right: -22px;
    line-height: 1.8;
    font-size: 1.0625rem;
    text-align: center;
    @include pc {
      font-size: 1.25rem;
    }
  }

  .c_btn__wrap {
    margin-top: 42px;
  }
}
