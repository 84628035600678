.l_nav {
  @include sp {
    opacity: 0;
    transition: opacity 150ms 300ms;
    .js-nav-show & {
      opacity: 1;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: flex-end;
    margin: 0;
    @include sp {
      display: block;
    }
  }

  &__listItem {
    &:not(:first-child) {
      margin-left: 28px;
      @include sp {
        margin-left: 0;
        border-bottom: 1px solid #333;
      }
    }
    &:first-child {
      @include pc {
        margin-left: 1.5em;
      }
      @include sp {
        border-top: 1px solid #333;
        border-bottom: 1px solid #333;
      }
    }
    a {
      display: flex;
      align-items: center;
      height: $header_height_pc;
      color: #333333;
      font-size: 1rem;
      font-weight: bold;
      text-decoration: none;
      @include sp {
        height: 63px;
        justify-content: center;
        color: #ffffff;
      }
      &:hover {
        color: inherit;
      }

      &[target="_blank"]::after {
        @include ico_blank;
      }
    }
  }

  &__bar {
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 12px;
    width: 100px;
    border-bottom: 2px solid $color09;
    opacity: 0;
  }

  &__toggler {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
    width: $header_height_sp;
    height: $header_height_sp;
    padding: 0;
    background-color: #000000;
    border: none;
    outline: none;
    cursor: pointer;
    @include pc {
      display: none;
    }

    .__ico {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 17px;
      height: 17px;
      margin: auto;
      b {
        position: absolute;
        display: block;
        width: 17px;
        height: 3px;
        border-radius: 2px;
        background-color: #fff;
        transition: transform 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
          opacity 150ms;
        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 7px;
        }
        &:nth-child(3) {
          bottom: 0;
        }
        .js-nav-show & {
          &:nth-child(1) {
            transform: translateY(7px) rotate(225deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translateY(-7px) rotate(-225deg);
          }
        }
      }
    }
    .__txt {
      display: none;
    }
  }
}
