// -----------------------------------------------------------------
.p-career_about_hero {
  box-sizing: initial;
  max-inline-size: 800px;
  margin-inline: auto;
  padding-inline: 20px;
  padding-block-start: 60px;
  @include mq-up(768) {
    padding-block-start: 110px;
  }

  // &__head {
  // }

  &__lead {
    margin-block-start: 30px;
  }

  &__body {
    margin-block-start: 50px;
    @include mq-up(768) {
      margin-block-start: 70px;
    }
  }
}

// -----------------------------------------------------------------
.p-career_about_section {
  margin-block-start: 70px;
  @include mq-up(768) {
    margin-block-start: 100px;
  }

  &__head {
    box-sizing: initial;
    max-inline-size: 800px;
    margin-inline: auto;
    padding-inline: 20px;
  }

  &__lead {
    margin-block-start: 30px;
    @include mq-up(768) {
      margin-block-start: 50px;
    }
  }

  &__body {
    box-sizing: initial;
    max-inline-size: 1068px;
    margin-inline: auto;
    margin-block-start: 35px;
    padding-inline: 20px;
    @include mq-up(768) {
      margin-block-start: 60px;
    }
  }

  &__figure {
    inline-size: fit-content;
    margin-inline: auto;
  }

  &__cols {
    display: block grid;
    row-gap: 40px;
    @include mq-up(768) {
      grid-template-columns: repeat(3, 1fr);
      gap: 0 40px;
    }
  }
}

// -----------------------------------------------------------------
.p-career_about_cta {
  margin-block-start: 80px;
  margin-block-end: 80px;
  @include mq-up(768) {
    margin-block-start: 100px;
    margin-block-end: 100px;
  }

  &__lead {
    inline-size: fit-content;
    margin-inline: auto;
    margin-block-start: 30px;
    margin-block-end: 50px;
    padding-inline: 20px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.8;
    text-align: center;
  }
}
