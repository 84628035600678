// -----------------------------------------------------------------
.c-career_talk_hero_hdg_group {
  position: relative;
  margin-inline: auto;
  @include mq-up(768) {
    inline-size: 585px;
    padding-block-end: 30px;
  }

  &__bg {
    @include mq-up(768) {
      position: absolute;
      inset: 0;
      z-index: -1;
      border: 2px solid #fff;
      background-color: #000;
      opacity: 0.3;
    }
  }

  &__label {
    position: relative;
    @include mq-up(768) {
      inset-block-start: -14px;
    }
  }

  &__subtitle {
    margin-block: 0;
    margin-inline: auto;
    margin-block-start: 20px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: #000;
    @include mq-up(768) {
      margin-block-start: 0;
      font-size: 20px;
      color: #fff;
    }
  }

  &__title {
    margin-block-start: 5px;
    @include mq-up(768) {
      margin-block-start: 0;
    }

    .c-career_hdg_variable {
      @include mq-up(768) {
        color: #fff;
      }
    }

    .c-career_dots {
      margin-block-start: 20px;
      @include mq-up(768) {
        display: none;
      }
    }
  }
}

// -----------------------------------------------------------------
.c-career_talk_hero_person {
  position: relative;
  display: block grid;
  grid-template-columns: 1fr auto 1fr;
  align-content: end;
  block-size: 300px;
  padding-block-end: 16px;
  @include mq-up(480) {
    block-size: 420px;
  }
  @include mq-up(768) {
    column-gap: 5px;
    block-size: auto;
  }

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;
    @include mq-up(768) {
      display: none;
    }

    &::before {
      content: "";
      position: absolute;
      inset-block-end: 0;
      inline-size: 100%;
      block-size: 50%;
      background: linear-gradient(transparent 10%, rgba(#000, 0.2) 50%);
    }

    img {
      @include objFitCover;
      object-position: center 10%;
    }
  }

  &__item {
    display: block grid;
    row-gap: 10px;
    // @include mq-up(768) {
    //   row-gap: 15px;
    // }

    &._start {
      text-align: end;
    }

    dt {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.3;
      color: #fff;
      @include mq-up(768) {
        font-size: 30px;
      }
    }
    dd {
      font-size: 11px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0.03em;
      color: #fff;
      @include mq-up(768) {
        font-size: 13px;
      }
    }
  }

  &__cross {
    position: relative;
    display: block flex;
    inline-size: 56px;
    aspect-ratio: 1;
    rotate: 45deg;
    translate: 0 -12px;
    @include mq-up(768) {
      inline-size: 110px;
      translate: 0 -5px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      inset-inline-start: 50%;
      inline-size: 2px;
      block-size: 56px;
      background-color: #fff;
      translate: 0-50%;
      @include mq-up(768) {
        block-size: 110px;
      }
    }
    &::after {
      rotate: 90deg;
    }
  }
}

// -----------------------------------------------------------------
.c-career_talk_person_info {
  &__head {
    display: block flex;
    align-items: center;
    column-gap: 8px;
    margin-block: 0;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.25;
    color: #999;

    span {
      font-weight: 400;
    }

    &::after {
      content: "";
      display: block flex;
      inline-size: 100%;
      block-size: 1px;
      border-block-start: 1px solid #000;
      opacity: 0.2;
    }
  }

  &__profile {
    display: block grid;
    grid-template-columns: 120px 1fr;
    align-items: center;
    column-gap: 20px;
    margin-block-start: 20px;
    @include mq-up(768) {
      grid-template-columns: 150px 1fr;
      column-gap: 25px;
      margin-block-start: 30px;
    }

    img {
      border-radius: 999vmax;
    }

    dl {
      display: block grid;
      row-gap: 10px;

      dt {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.5;
        @include mq-up(768) {
          font-size: 22px;
        }
      }
      dd {
        font-size: 13px;
        line-height: 1.4;
      }
    }
  }

  &__view {
    margin-block-start: 30px;
  }

  &__text {
    margin-block: 0;
    font-size: 13px;
    letter-spacing: 0.05em;
    line-height: 1.8;
  }
}

// -----------------------------------------------------------------
.c-career_talk_unit {
  --side_size: 54px;
  @include mq-up(768) {
    --side_size: 70px;
  }

  display: grid;
  grid-template-columns: var(--side_size) 1fr;
  // column-gap: 20px;
  // @include mq-up(768) {
  //   column-gap: 36px;
  // }

  &__person {
    margin-block-end: 0;
    font-size: 16px;
    font-weight: 700;
  }

  &__info {
    display: grid;
    row-gap: 40px;
    margin-block-end: 0;
  }

  figure {
    display: grid;
    row-gap: 10px;
    @include mq-down(768) {
      margin-inline-start: calc(var(--side_size) * -1);
    }

    img {
      inline-size: 100%;
      block-size: auto;
      filter: drop-shadow(0 3px 10px rgba(#000, 0.16));
    }

    figcaption {
      font-size: 11px;
      line-height: 1.5;
      color: #666;
    }
  }
}
