// -----------------------------------------------------------------
.c-career_dots {
  --bg_color: #3e9dac;
  --duration: 0.5s;
  --base_delay: 0.7s;
  --delay: calc(var(--group_delay, 0s) + var(--base_delay));
  --opacity: 0;
  --scale: 0;
  --translate: 0 16px;
  --rotate: 360deg;

  &._no_animation {
    --opacity: 1;
    --scale: 1;
    --translate: 0;
    --rotate: 0deg;
  }

  .is-show_active & {
    --opacity: 1;
    --scale: 1;
    --translate: 0;
    --rotate: 0deg;
  }

  display: flex;
  justify-content: center;
  column-gap: 10px;
  span {
    inline-size: 8px;
    aspect-ratio: 1;
    background-color: var(--bg_color);
    opacity: var(--opacity);
    scale: var(--scale);
    translate: var(--translate);
    rotate: var(--rotate);
    transition: opacity var(--duration) $easeOutCubic var(--delay),
      scale var(--duration) $easeOutBack var(--delay),
      translate var(--duration) $easeOutBack var(--delay),
      rotate var(--duration) $easeOutCubic var(--delay);

    &.__dot2 {
      --bg_color: #5f528f;
      --delay: calc(var(--group_delay, 0s) + var(--base_delay) + 0.1s);
    }
    &.__dot3 {
      --bg_color: #9caa00;
      --delay: calc(var(--group_delay, 0s) + var(--base_delay) + 0.2s);
    }
    &.__dot4 {
      --bg_color: #d5890d;
      --delay: calc(var(--group_delay, 0s) + var(--base_delay) + 0.3s);
    }
  }
}
