.c_bdbox {
  @include margin-root;
  position: relative;
  margin: 60px 0;
  border: 5px solid #E5E5E5;
  @include sp {

  }

  &:first-child { margin-top: 0;}
  &:last-child { margin-bottom: 0;}

  &__ttl {
    display: inline-block;
    position: absolute;
    top: -5px;
    left: -5px;
    padding: 0 1em;
    background-color: $color02;
    color: #ffffff;
    font-size: 0.875rem;
    text-align: center;
    transform: translateY(-50%);
    @include pc {
      padding: 0 3em;
    }
  }

  &__inner {
    padding: 40px 40px 30px;
    font-size: .875rem;
    @include sp {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 0.8125rem;
    }
  }
}
