html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.wf-loading {
    opacity: 0;
    pointer-events: none;
  }

  &.wf-active {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 150ms ease;
  }

  &.layout-fixed {
    min-width: $base-width;
  }
}

body {
  line-height: 2;
  color: #444;
  // font-family: 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif;
  font-size: 0.875rem;
  letter-spacing: .07em;
  @include en {
    font-family: "acumin-pro", sans-serif;
    line-height: (28/15);
  }

  // &.js-nav-show {
  //   overflow: hidden; // メニュー展開時、本文を固定
  // }
}

[lang="en"] {
  font-family: "acumin-pro", sans-serif;
  line-height: (28/15);
}

.icon-newwin { margin-left: .35em; }
.icon-global-color { margin-right: .35em; }




ul, ol {
  list-style: none;
  margin: 0 0 1.5em;
  padding-left: 0;

  &:last-child {
    margin-bottom: 0;
  }

  ul, ol {
    margin: 0;
  }
}

dl, dt, dd {
  margin: 0;
}

p {
  margin: 0 0 1.5em;
  word-wrap: break-word;

  &:last-child {
    margin-bottom: 0;
  }
}

em {
  font-style: normal;
  font-weight: normal;
}

strong {
  font-weight: bold;
}

i {
  font-style: normal;
}

img {
  max-width: 100%;
  height: auto;
}

svg {
  height: auto;
}

figure {
  margin: 0;
}

sup, sub {
  font-size: .625rem;
}

fieldset {
  border: none;
}

legend {
  display: none;
}

a {
  word-wrap: break-word;

  img {
  }

  &.nohover:hover {
    color: inherit;
    img {
    }
  }
}

::selection {
  // background: $color01;
  // color: #fff;
}

.ico_facebook01 {
  .cls-1 {
    fill: #315096;
  }
  .cls-2 {
    fill: #fff;
  }
}

.ico_twitter01 {
  .cls-1 {
    fill: #3596d4;
  }
  .cls-2 {
    fill: #fff;
  }
}
