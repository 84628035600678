.c_discList {

  > li {
    position: relative;
    padding-left: 1em;
    line-height: 1.75;

    &:not(:first-child) {
      margin-top: .5em;
    }

    &::before {
      content: '･';
      position: absolute;
      top: 0;
      left: 0;
      width: 1em;
      height: 1.5em;
      text-align: center;
    }
  }

  &.pad1 {
    > li {
      margin-left: 1.25em;
    }
  }

  .c_linkList & {
    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }
}

.c_markList {

  > li {
    display: flex;
    line-height: 1.75;

    &:not(:first-child) {
      margin-top: .25em;
    }

    i {
      display: inline-block;
      flex: 0 0 1em;
    }
  }

  @include pc {
    &.center {
      > li {
        justify-content: center;
      }
    }
  }
  @include sp {
    font-size: 0.75rem;
  }

  &.smaller {
    @include pc {
      font-size: 0.875rem;
    }
  }

  &--free {
    > li {
      line-height: 2;
      i {
        flex: 0 0 auto;
        margin-right: 0.5em;
      }
      span {
        flex: 1 1 auto;
      }
    }
  }
}

.c_ol {

  > li {
    position: relative;
    list-style-type: decimal;
    margin-left: 1.5em;
    line-height: 1.75;

    &:not(:first-child) {
      margin-top: .375em;
    }
  }
}
