.l_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  transition: top 300ms ease;
  z-index: $z-index-header;
  @include pc {
    min-width: $base_width + 20;
  }
  @include sp {
    // position: absolute;
    height: 60px;
    // background-color: #000000;
    color: #ffffff;
    font-size: 0;
    overflow: auto;
    .js-nav-show & {
      height: 100%;
    }
  }

  a:hover {
    text-decoration: none;
  }

  &__inner {
    @include pc {
      display: block grid !important;
      grid-template-columns: 1fr auto;
      column-gap: 50px;
    }

    @include sp {
      display: none;
      // position: fixed;
      position: absolute;
      width: 100%;
      height: 0;
      padding: 60px 20px;
      background-color: #000000;
      transition: height 300ms;
      .js-nav-show & {
        // height: 453px; // caution
        // height: 100vh;
        height: auto;
        min-height: 100vh;
      }
    }
  }

  &__logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 235px;
    margin: 0;
    @include sp {
      right: $header_height_sp;
      width: auto;
    }
    &[data-logo="tanimoku"] {
      inline-size: 180px;
      a {
        padding: 0 0 0 20px;
        @include sp {
          padding: 0 0 0 20px;
        }

        img,
        svg {
          inline-size: 140px;
          @include sp {
            inline-size: 140px;
          }
        }
        &:hover {
          @include pc {
            background-color: revert;
          }
        }
      }
    }

    a {
      display: block grid;
      align-content: center;
      height: $header_height_pc;
      // padding: 10px 10px 0;
      padding: 8px 10px 0;
      background-color: #ffffff;
      color: transparent; // ie
      font-size: 0;
      transition: background-color 300ms;
      @include sp {
        height: $header_height_sp;
        // padding-top: 14px;
        padding-top: 8px;
      }
      img,
      svg {
        @include sp {
          width: 168px;
        }
        .b,
        .c {
          transition: fill 450ms;
        }
        .b {
          fill: #97999b;
        }
        .c {
          fill: #53565a;
        }
      }
      &:hover {
        @include pc {
          background-color: #000000;
          color: transparent; // ie
          img,
          svg {
            .b,
            .c {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  &__sns {
    // position: absolute;
    // top: 0;
    // right: 0;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    height: $header_height_pc;
    padding-right: 16px;
    @include sp {
      position: static;
      display: block;
      height: auto;
      padding: 20px 0;
      opacity: 0;
      transition: opacity 150ms 300ms;
      .js-nav-show & {
        opacity: 1;
      }
    }
    .__list {
      display: flex;
      margin: 0 0 0 10px;
      @include sp {
        justify-content: center;
        margin-left: 0;
      }
      > li {
        width: 34px;
        height: 34px; // ie
        @include sp {
          width: 32px;
          height: 32px; // ie
        }
        &:not(:first-child) {
          margin-left: 13px;
          @include sp {
            margin-left: 18px;
          }
        }
        a {
          .cls-1 {
            transition: 300ms;
          }
          &:hover {
            opacity: 0.75;
            .cls-1 {
              fill: #000000;
            }
          }
        }
      }
    }
    .__txt {
      font-size: 0.75rem;
      @include sp {
        margin-top: 5px;
        text-align: center;
      }
    }

    .icon_facebook {
      inline-size: 100%;

      &__base {
        fill: #315096;
        transition: fill 0.4s $easeOutQuart;
        @include mq-up(768) {
          @include group-hover {
            fill: lighten(#315096, 20%);
          }
        }
      }

      &__ico {
        fill: #fff;
      }
    }

    .icon_twitter {
      inline-size: 100%;

      &__base {
        // fill: #3596d4;
        fill: #000;
        transition: fill 0.4s $easeOutQuart;
        @include mq-down(768) {
          fill: #fff;
        }
        @include mq-up(768) {
          @include group-hover {
            fill: lighten(#000, 30%);
          }
        }
      }

      &__ico {
        fill: #fff;
        @include mq-down(768) {
          fill: #000;
        }
      }
    }
  }

  &--corporate {
    .l_header__inner {
      @include sp {
        padding-top: 30px;
        padding-bottom: 0;
      }
    }

    .l_nav__list {
      justify-content: flex-end;
      padding-right: 20px;
    }
  }

  &__corporate {
    &__logo__sp {
      text-align: center;
      @include pc {
        display: none;
      }
    }

    &__totop {
      margin: 0 -20px;
      background-color: #fff;
      text-align: center;
      @include pc {
        display: none;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 23px;
        background-color: #fff;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 40px;
          width: 12px;
          height: 12px;
          margin: auto;
          border-top: 2px solid #000;
          border-left: 2px solid #000;
          transform-origin: center;
          transform: rotate(-45deg);
          transition: 300ms;
        }

        img {
          width: 198px;
        }
      }
    }
  }

  &__to_career {
    display: none;
    @include sp {
      display: block;
      opacity: 0;
      transition: opacity 150ms 300ms;
      .js-nav-show & {
        opacity: 1;
      }
    }
  }
}
