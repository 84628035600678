.p_labTable {
  $table_border_radius: 5px !default;
  margin: 60px -16px 0;

  @include pc_l {
    margin: 80px 0 0;
  }

  &__head_group, &__tr {
    display: grid;
    grid-template-columns: 1fr 72px 72px;
    gap: 6px;

    @include pc {
      grid-template-columns: 1fr 134px 190px;
      gap: 10px;
    }
  }

  &__head {
    background-color: $table_bg_color;
    margin: 0;
    padding: 10px 20px;
    border-radius: $table_border_radius;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: $lab_lh_default;
    letter-spacing: $letter_spacing_default;
    text-align: left;

    @include pc_l {
      line-height: 48px;
      height: 48px;
      font-size: 18px;
      padding: 0;
      text-align: center;
    }

    &:nth-child(2), &:nth-child(3) {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:nth-child(2) {
      background-color: #868686;
      font-weight: normal;
    }
  }

  &__tr {
    margin-top: 6px;
    text-align: left;

    @include pc_l {
      margin-top: 10px;
    }
  }

  &__td {
    background-color: #fff;
    border-radius: $table_border_radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 14px 14px 20px 20px;

    @include pc_l {
      flex-direction: row;
      padding: 12px;
    }

    &:first-child {
      justify-content: space-between;
      gap: 24px;

      @include pc_l {
        padding-left: 30px;
        gap: 36px;
      }
    }

    &:nth-child(2),&:nth-child(3) {
      font-size: 20px;
    }

    &:nth-child(3) {
      font-weight: bold;
      color: $table_txt_color;
      -webkit-text-stroke: 1px $table_txt_color;
    }
  }

  &__td_desc {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: $letter_spacing_default;
    line-height: $lab_lh_default;
    margin: 0;

    @include pc_l {
      font-size: 18px;
    }
  }

  &__td_box {
    min-height: 80px;
    height: 100%;
    width: 100%;
    background-color: #000;
    border-radius: $table_border_radius;
    padding: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    line-height: $lab_lh_default;
    letter-spacing: .03em;

    @include pc_l {
      flex-direction: row;
      width: 260px;
      padding: 8px 16px 8px 0;
    }

    &:before {
      content: "";
      display: inline-block;
      background-image: url(../../assets/img/ico_hand.svg);
      width: 64px;
      height: 64px;
      flex-shrink: 0;
      margin-top: -40px;

      @include pc_l {
        margin-top: 0;
      }
    }

    &.--data {
      background-color: rgba($color08, .4);
    }

    &.--limited {
      background-color: rgba($color11, .4);
    }

    &.--knowledge {
      background-color: rgba($color10, .4);
    }

    p {
      @include pc_l {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }
}