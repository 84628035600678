// -----------------------------------------------------------------
.a-career_fade_hdg {
  --duration: 1s;
  --base_delay: 0.25s;
  --delay: calc(var(--group_delay, 0s) + var(--base_delay));
  --opacity: 0;
  --clip_path: polygon(0 0, 0 0, 0 0);
  --clip_path: inset(0 100% 0 0);

  &._no_animation {
    --opacity: 1;
    --clip_path: polygon(0 0, 200% 0, 0 200%);
    --clip_path: inset(0);
  }

  .is-show_active & {
    --opacity: 1;
    --clip_path: polygon(0 0, 200% 0, 0 200%);
    --clip_path: inset(0);
  }

  inline-size: fit-content;
  opacity: var(--opacity);
  // translate: var(--translate);
  clip-path: var(--clip_path);
  transition: opacity var(--duration) $easeInOutQuart var(--delay),
    clip-path var(--duration) $easeInOutQuart var(--delay);
}

// -----------------------------------------------------------------
.a-career_fade_block_end_to_start {
  --duration: 0.8s;
  --base_delay: 0s;
  --delay: calc(var(--group_delay, 0s) + var(--base_delay));
  --opacity: 0;
  --translate: 0 40px;

  &._no_animation {
    --opacity: 1;
    --translate: 0 0;
  }

  .is-show_active & {
    --opacity: 1;
    --translate: 0 0;
  }

  opacity: var(--opacity);
  translate: var(--translate);
  transition: opacity var(--duration) $easeOutCubic var(--delay),
    translate var(--duration) $easeOutCubic var(--delay);
}
