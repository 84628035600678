.c_galleryBox {
  display: flex;
  flex-wrap: wrap;
  @include sp {
    display: block;
  }

  .c_galleryBox__item {
    width: 100%;
  }

  @for $i from 2 through 4 {
    &.col#{$i} {
      .c_galleryBox__item {
        width: calc(100% / #{$i});
        @include sp {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
