// -----------------------------------------------------------------
.p-career_shapes {
  position: relative;
  z-index: -1;
  overflow-x: clip;

  &__inner {
    position: absolute;
    inline-size: 100%;
    @include mq-up(768) {
      min-inline-size: 1366px;
      inset-inline-start: calc((1366px - 100%) * -0.5);
    }
    @include mq-up(1366) {
      inset-inline-start: 0;
    }
  }

  img {
    position: absolute;
  }

  &__shape1 {
    inset-block-start: -30px;
    inset-inline-start: -90px;
    inline-size: 366px;
    @include mq-up(768) {
      inset-block-start: -38px;
      inset-inline-start: -8px;
      inline-size: 566px;
    }
  }

  &__shape2 {
    // inset-block-start: 210px;
    inset-block-start: 180px;
    inset-inline-start: -280px;
    inline-size: 392px;
    @include mq-up(768) {
      inset-block-start: 256px;
      inset-inline-start: -334px;
      inline-size: 695px;
    }
  }

  &__shape3 {
    // inset-block-start: 70px;
    inset-block-start: 40px;
    inset-inline-end: -260px;
    inline-size: 420px;
    @include mq-up(768) {
      // inset-block-start: -140px;
      inset-block-start: -180px;
      inset-inline-end: -422px;
      inline-size: 745px;
    }
  }
}
