.c_new {
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 18px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: $color07;
  margin-left: 8px;
  line-height: 1;
}