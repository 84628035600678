.l_footer {
  width: 100%;
  min-height: 0%; // ie
  margin-top: auto;
  padding: 35px 0;
  background-color: #444444;
  color: #ffffff;
  @include sp {
    padding: 35px 20px 40px;
  }

  a {
    &:hover {
      color: inherit;
    }
    &:not(:hover){
      text-decoration: none;
    }
  }

  &__inner {
    position: relative;
    max-width: $base_width;
    margin: auto;
    @include sp {
      display: block;
    }
  }

  &__layout {
    display: inline-block;
    margin-bottom: 39px;
  }

  &__logo {
    margin-bottom: 35px;
    padding-bottom: 5px;
    border-bottom: 1px solid #707070;
    @include sp {
      margin-bottom: 22px;
    }
    a {
      color: transparent; // ie
      &:hover {
        color: transparent; // ie
      }
      img, svg {
        width: 216px;
        height: 53px; // ie
        @include sp {
          width: 168px;
          height: 41px; // ie
        }
        .b, .c {
          fill: #fff;
        }
      }
    }
  }

  &__sns {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    @include sp {
      display: block;
    }
    .__list {
      display: flex;
      align-items: center;
      margin: 0;
      li {
        &:not(:first-child) {
          margin-left: 12px;
        }
        a {
          display: inline-block;
          width: 40px;
          height: 40px; // ie
          @include sp {
            width: 43px;
            height: 43px; // ie
          }
          path {
            transition: 300ms;
          }
          &:hover {
            .cls-1 {
              fill: #fff;
            }
            .cls-2 {
              fill: #444;
            }
          }
        }
      }
    }
    .__txt {
      margin-left: 44px;
      @include sp {
        margin: 25px 0 0;
      }
    }
  }

  &__lower {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @include sp {
      display: block;
    }
    .__list {
      display: flex;
      margin: 0;
      font-size: 0.75rem;
      @include sp {
        display: block;
        margin-bottom: 30px;
      }
      > li {
        &:not(:first-child) {
          margin-left: 26px;
          @include sp {
            margin: .5em 0 0;
          }
        }
      }
    }
    .__copyright {
      small {
        display: block;
        color: $color02;
        font-size: 0.625rem;
      }
    }
  }

  &__pagetop {
    position: absolute;
    top: 5px;
    right: 0;
    @include sp {
      transform-origin: top right;
      transform: scale(.8);
    }

    a {
      display: block;
      color: #ffffff;
      text-decoration: none;

      i {
        display: block;
        position: relative;
        width: 58px;
        height: 52px;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 50%;
          display: inline-block;
          width: 40px;
          height: 40px;
          margin: auto;
          border-top: 2px solid #ffffff;
          border-right: 2px solid #ffffff;
          transform-origin: top right;
          transform: rotate(-45deg);
        }
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 0;
          height: 52px;
          margin: auto;
          border-left: 2px solid #ffffff;
        }
      }

      .__txt {
        display: block;
        margin-top: 0.5em;
        font-size: 0.625rem;
        font-weight: bold;
        text-align: center;
      }

      &:hover {
        color: #ffffff;
        text-decoration: none;
        i {
          @include pc {
            animation: pagetopAnime 600ms alternate infinite;
          }
        }
      }
    }
  }

  @keyframes pagetopAnime {
    0% {
      transform: translateY(0px) rotateY(0deg);
    }
    100% {
      transform: translateY(-10px) rotateY(180deg);
    }
  }
}
