a {
  color: inherit;
  text-decoration: underline;
  &:hover {
    color: $color04;
    text-decoration: underline;
  }
  &:visited {
  }
}

.c_note {
  font-size: .75rem;
}
