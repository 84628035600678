.p-loop-box {
  position: relative;
  &:after {
    bottom: 0;
    content: "";
    display: inline-block;
    position: absolute;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("../../assets/img/bg_pattern01.png");
    background-repeat: repeat;
    animation: sectionBg 30000ms linear infinite;
    z-index: 0;

    @include sp {
      background-size: 256px auto;
    }
  }

  &.--sp-only {
    &:after {
      display: none;
      @include sp {
        display: block;
      }
    }
  }

  &.--pc-only {
    @include sp {
      &:after {
        display: none;
      }
    }
  }
}