// -----------------------------------------------------------------
.l_footer {
  padding: 45px 20px;
  &__sns {
    row-gap: 20px;
    column-gap: 44px;
    @include sp {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .__note {
      display: flex;
      align-items: center;
      column-gap: 16px;
      a {
        display: flex;
        inline-size: 92px;
      }
    }
  }

  .icon_facebook {
    inline-size: 40px;
    aspect-ratio: 1;

    &__base {
      fill: #315096;
      transition: fill 0.4s $easeOutQuart;
      @include group-hover {
        fill: #fff;
      }
    }

    &__ico {
      fill: #fff;
      transition: fill 0.4s $easeOutQuart;
      @include group-hover {
        fill: #000;
      }
    }
  }

  .icon_twitter {
    inline-size: 40px;
    aspect-ratio: 1;

    &__base {
      // fill: #3596d4;
      fill: #000;
      transition: fill 0.4s $easeOutQuart;
      @include group-hover {
        fill: #fff;
      }
    }

    &__ico {
      fill: #fff;
      transition: fill 0.4s $easeOutQuart;
      @include group-hover {
        fill: #000;
      }
    }
  }

  .icon_note {
    inline-size: 100%;

    &__ico {
      fill: #41c9b4;
      transition: fill 0.4s $easeOutQuart;
      @include group-hover {
        fill: #fff;
      }
    }

    &__text {
      fill: #fff;
    }
  }
}
