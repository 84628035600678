.p_faqWrapper {
  margin-bottom: 80px;
  .c_section {
    @include sp {
      padding-bottom: 0px;
    }
  }
  a[target="_blank"]::after {
    @include ico_blank;
  }

  &__inner {
    max-inline-size: 850px;
    margin-inline: auto;
    margin-block-end: 60px;
  }
}

.p_faqBox {
  margin-bottom: 40px;
  border: 3px solid #c0add1;
  @include sp {
    margin-left: -20px;
    margin-right: -20px;
    border: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__que {
    display: flex;
    align-items: flex-start;
    padding: 20px 40px;
    background-color: #eeeeee;
    @include sp {
      padding: 20px;
      border-top: 3px solid #c0add1;
      border-bottom: 3px solid #c0add1;
    }
    .__ico {
      margin-right: 20px;
      line-height: 1.1538461538461537; // 30px
      color: #9a7cb6;
      font-family: "Muli", sans-serif;
      font-size: 1.625rem;
      font-weight: 600;
    }
    .__txt {
      flex: 1 1 0%;
      line-height: 1.875;
      color: #000000;
      font-size: 1rem;
      font-weight: bold;
    }
  }

  &__ans {
    display: flex;
    align-items: flex-start;
    padding: 20px 40px;
    @include sp {
      padding: 20px;
    }
    .__ico {
      margin-right: 20px;
      line-height: 1.0769230769230769; // 28px
      color: #9a7cb6;
      font-family: "Muli", sans-serif;
      font-size: 1.625rem;
      font-weight: 600;
    }
    .__txt {
      flex: 1 1 0%;
    }
  }
}
