// -----------------------------------------------------------------
.c-career_index_effect_item {
  display: block grid;
  grid-template-rows: subgrid;
  grid-row: span 3;
  row-gap: 0;

  &__image {
    img {
      inline-size: 100%;
      block-size: auto;
    }
  }

  &__title {
    margin-block-start: 20px;
    margin-block-end: 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
  }

  &__text {
    margin-block-start: 12px;
    font-size: 15px;
    font-weight: 500;
  }
}

// -----------------------------------------------------------------
.c-career_index_diverse_item {
  display: block grid;
  align-content: start;
  row-gap: 20px;

  &__image {
    border-radius: 100vmax;
    contain: paint;
    img {
      inline-size: 100%;
      block-size: auto;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    @include mq-up(768) {
      font-size: 18px;
    }
  }
}

// -----------------------------------------------------------------
.c-career_index_possibility_item {
  position: relative;
  display: block grid;
  place-items: center;
  inline-size: 100%;
  aspect-ratio: 1;
  border-radius: 999vmax;

  background: linear-gradient(#3ca3fc, #3fddea);
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 999vmax;
    background-color: rgba(#000, 0.1);
  }

  &__inner {
    position: relative;
    padding-block-start: 20px;
    text-align: center;
    color: #fff;

    p {
      margin-block-end: 0;
    }
  }

  &__flag {
    position: absolute;
    inset-block-start: -24px;
    inset-inline-start: 50%;
    inline-size: 40px;
    @include mq-up(768) {
      inset-block-start: -30px;
      inline-size: 50px;
    }

    img {
      inline-size: 100%;
      block-size: auto;
    }

    opacity: 0;
    .is-show_active & {
      animation: logoFlag 360ms 0.5s forwards
        cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }

  &__text1 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 1.5;
    @include mq-up(768) {
      font-size: 22px;
    }
  }

  &__text2 {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 1.5;
    @include mq-up(768) {
      font-size: 18px;
    }
  }

  &__text3 {
    position: relative;
    inset-block-start: -6px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 1.5;
    @include mq-up(768) {
      inset-block-start: -6px;
      font-size: 19px;
    }
  }

  &__text4 {
    margin-block-end: -20px;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.5;
    @include mq-up(768) {
      font-size: 11px;
    }
  }

  &__info1 {
    margin-block-start: 15px;
    margin-inline-start: 10px;
    @include mq-up(768) {
      margin-block-start: 15px;
    }
  }

  &__info3 {
    display: inline flex;
    align-items: baseline;
    column-gap: 4px;
    margin-block-start: 5px;
    margin-block-end: -15px;
    @include mq-up(768) {
      column-gap: 8px;
      margin-block-start: 10px;
      margin-block-end: 2px;
    }
  }

  &__num {
    display: inline flex;
    align-items: baseline;
    font-family: "Hind", sans-serif;
    font-size: 45px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1;
    color: #ffdb6e;
    @include mq-up(768) {
      font-size: 48px;
    }

    small {
      margin-inline-end: -8px;
      margin-inline-start: 4px;
      font-size: 20px;
      @include mq-up(768) {
        font-size: 27px;
      }
    }
  }
}
