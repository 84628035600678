// -----------------------------------------------------------------
.c-career_list_disc {
  --row_gap: 6px;
  --font_size: 16px;
  --line_height: 1.75;

  display: block grid;
  row-gap: var(--row_gap);
  margin-block: 0;

  li {
    display: flex;
    column-gap: 5px;
    font-size: var(--font_size);
    line-height: var(--line_height);
    &::before {
      content: "・";
    }
  }
}
// -----------------------------------------------------------------
.c-career_list_num {
  --row_gap: 6px;
  --font_size: 16px;
  --font_weight: 500;
  --line_height: 1.75;

  &[data-font-weight="bold"] {
    --font_weight: 700;
  }

  display: block grid;
  row-gap: var(--row_gap);
  margin-block: 0;

  li {
    display: flex;
    column-gap: 10px;
    font-size: var(--font_size);
    font-weight: var(--font_weight);
    line-height: var(--line_height);

    > span {
      &:first-child {
        flex-shrink: 0;
      }
    }
  }
}
