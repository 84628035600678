.l-career_header {
  position: sticky;
  inset-block-start: 0;
  z-index: 100;

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: 24px;
  block-size: var(--header-block-size);
  padding-inline-start: 20px;
  background-color: #fff;
  // background-color: beige;
  @include mq-up(1024) {
    grid-template-columns: auto 1fr;
    padding-inline: 20px;
  }

  &__logo {
    inline-size: fit-content;
    a {
      display: block grid;
      align-items: end;
      grid-template-columns: 122px 78px;
      @include mq-up(1024) {
        grid-template-columns: 138px 88px;
        padding-block-end: 2px;
      }
      align-content: end;
      column-gap: 6px;
      text-decoration: none;
      @include hover {
        text-decoration: none;
      }

      .__text {
        margin-block-end: 3px;
      }
    }
  }

  &__nav {
    opacity: 1;
    visibility: visible;
    @include mq-down(1024) {
      // display: none;
      position: absolute;
      inset-block-start: 0;
      inline-size: 100%;
      padding-block-end: 10px;
      background-color: #000;
      // background-color: rgba(red, 0.5);
      opacity: 0;
      pointer-events: none;
      .is-show_mobile_menu & {
        pointer-events: auto;
      }

      &::after {
        content: "";
        position: absolute;
        inset-block-start: 0;
        inline-size: 100%;
        block-size: calc(var(--header-block-size) + 10px);
        background: linear-gradient(#000 calc(100% - 10px), transparent);
      }
    }
  }

  &__menu {
    position: relative;
    block-size: 100dvb;
    padding-block-end: 40px;
    overflow-y: auto;
    @include mq-up(1024) {
      display: flex;
      justify-content: flex-end;
      column-gap: unquote("min(calc(36/1280*100vw), 36px)");
      block-size: auto;
      padding-block-end: 0;
    }
  }

  &__group {
    position: relative;
    // background-color: rgba(red, 0.2);
  }

  &__list {
    position: relative;
    margin-block-end: 0;
    margin-block-start: calc(var(--header-block-size) + 10px);

    @include mq-up(1024) {
      display: inline-flex;
      column-gap: unquote("min(calc(30/1280*100vw), 30px)");
      margin-block-start: 0;
    }

    li {
      @include mq-down(1024) {
        &:first-child a {
          border-block-start: 1px solid #333;
        }
      }
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      block-size: 64px;
      margin-inline: 20px;
      text-decoration: none;
      border-block-end: 1px solid #333;
      @include mq-up(1024) {
        display: inline flex;
        justify-content: stretch;
        margin-inline: 0;
        border-block-end: none;
        &::before {
          content: "";
          box-sizing: initial;
          position: absolute;
          inset: 0;
          inset-inline-start: calc(min(calc(15 / 1280 * 100vw), 15px) * -1);
          inline-size: 100%;
          padding-inline: unquote("min(calc(15/1280*100vw), 15px)");
          // background-color: rgba(green, 0.2);
        }
      }
      @include hover {
        text-decoration: none !important;
      }

      span {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
        letter-spacing: 0.03em;
        text-decoration: none;
        color: #fff;
        @include mq-up(1024) {
          font-size: 14px;
          letter-spacing: 0.05em;
          color: #333;
        }
        @include mq-up(1280) {
          font-size: clamp(13px, calc(14 / 1366 * 100vw), 14px);
        }

        br {
          @include mq-down(1024) {
            display: none;
          }
          @include mq-up(1280) {
            display: none;
          }
        }
      }
    }
  }

  &__sns {
    display: block grid;
    justify-content: center;
    column-gap: 16px;
    row-gap: 5px;
    margin-block-start: 20px;
    margin-block-end: 10px;
    font-size: 12px;
    font-weight: 500;
    @include mq-up(1024) {
      display: block flex;
      align-items: center;
      column-gap: 10px;
      margin-block: 0;
      font-size: 11px;
      color: #444;
    }

    li {
      @include mq-down(1024) {
        &:nth-child(1) {
          grid-area: 2/1/3/3;
          text-align: center;
          color: #fff;
        }
        &:nth-child(2) {
          grid-area: 1/1/2/2;
        }
        &:nth-child(3) {
          grid-area: 1/2/3/3;
        }
      }
    }

    a {
      display: block flex;
      align-items: center;
      inline-size: 38px;
      aspect-ratio: 1;
      @include mq-up(1024) {
        inline-size: 34px;
      }
      text-decoration: none;
      @include hover {
        text-decoration: none;
      }
    }

    .icon_facebook {
      inline-size: 100%;

      &__base {
        fill: #315096;
        transition: fill 0.4s $easeOutQuart;
        @include mq-up(1024) {
          @include group-hover {
            fill: lighten(#315096, 20%);
          }
        }
      }

      &__ico {
        fill: #fff;
      }
    }

    .icon_twitter {
      inline-size: 100%;

      &__base {
        // fill: #3596d4;
        fill: #000;
        transition: fill 0.4s $easeOutQuart;
        @include mq-down(1024) {
          fill: #fff;
        }
        @include mq-up(1024) {
          @include group-hover {
            fill: lighten(#000, 30%);
          }
        }
      }

      &__ico {
        fill: #fff;
        @include mq-down(1024) {
          fill: #000;
        }
      }
    }
  }

  &__back {
    @include mq-up(1024) {
      display: none;
    }

    a {
      --block_size: 60px;
      --txt_color: #fff;
      --txt_hover_color: #fff;
      --chevron_color: #fff;
      --chevron_hover_color: #fff;
      --bg_color: transparent;

      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      min-block-size: var(--block_size);
      padding-inline-start: 20px;
      padding-inline-end: 32px;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 0.04em;
      text-decoration: none;
      color: var(--txt_color);
      background-color: var(--bg_color);
      contain: paint;
      transition: color 0.4s $easeOutQuart, background-color 0.4s $easeOutQuart;
      &:hover {
        color: var(--txt_hover_color);
      }

      &::before {
        content: "";
        inline-size: 12px;
        aspect-ratio: 1;
        background-color: var(--chevron_color);
        mask-image: url("/assets/img/career/common/icon/chevron.svg");
        scale: -1 1;
        transition: background-color 0.4s $easeOutQuart,
          translate 0.4s $easeOutBack;
      }
    }
  }
}
