// -----------------------------------------------------------------
.c-career_comment {
  $_self: &;

  padding-block-start: 15px;

  &__inner {
    padding: 0 25px 30px;
    border: dashed 1px #909090;
    border-radius: 10px;
    background: linear-gradient(#cde3ef, #e7f4e8);
    @include mq-up(768) {
      padding: 0 54px 40px;
    }
  }

  &__label {
    margin-block-start: -15px;

    + #{$_self}__text {
      margin-block-start: 20px;
    }
  }

  &__title {
    margin-block-start: 25px;
  }

  &__text {
    margin-block-start: 15px;
  }
}
