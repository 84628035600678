.c_linkList {

  > li {
    &:not(:first-child) {
      margin-top: 0.5em;
    }
    a, > span {
      display: inline-block;
      position: relative;
      padding-left: 1.25em;
      line-height: 1.8571428;
      color: #000;
      font-size: 0.875rem;
      font-weight: bold;
      text-decoration: none;
      @include sp {
        text-decoration: underline;
      }

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: calc(.8125rem - 5.656854px);
        left: 0;
        width: 8px;
        height: 8px;
        border-top: 2px solid $color09;
        border-right: 2px solid $color09;
        transform-origin: left top;
        transform: rotate(45deg);
      }

      &[target="_blank"]::after,
      &._blank::after {
        @include ico_blank;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .c_linkList {
      margin-top: 0.5em;
      margin-left: 1.25em;
    }
  }

  &.inline {
    display: flex;
    flex-wrap: wrap;
    margin-top: -0.5em;
    margin-left: -56px;
    > li {
      margin-top: 0.5em;
      margin-left: 56px;
    }
  }
}

// ボックス全体リンクの時
a {
  .c_linkList li > span {
    color: #000;
  }
  &:hover {
    @include pc {
      .c_linkList li > span {
        text-decoration: underline;
      }
    }
  }
}

.c_linkText {
  $this: &;
  text-decoration: none;
  @include sp {
    text-decoration: underline;
  }

  &[target="_blank"]::after,
  &._blank::after {
    @include ico_blank;
    margin-right: 5px;
  }

  &:hover {
    text-decoration: underline;
  }

  &--bdr {
    @extend #{$this};
    text-decoration: underline;
  }
}

.c_link {
  &[target="_blank"]::after {
    @include ico_blank;
    margin-right: 5px;
  }

  &.--cyan {
    text-decoration: none;
    color: $color09;
  }
}