// -----------------------------------------------------------------
.c-career_round_label {
  --duration: 0.8s;
  --delay: calc(var(--group_delay, 0s));
  --opacity: 0;
  // --clip_path: circle(0% at 50% 50%);
  --clip_path: inset(0 50% 0 50% round 40px);
  --font_size: 15px;
  --bg_color: #333;

  @include mq-up(768) {
    --font_size: 16px;
  }

  &._center {
    margin-inline: auto;
  }

  &._taCenter {
    text-align: center;
  }

  &._primary {
    --bg_color: #1cb2c6;
  }
  &._small {
    --font_size: 14px;
  }
  &._no_animation {
    --opacity: 1;
    --clip_path: inset(0 0 0 0 round 40px);
  }

  .is-show_active & {
    --opacity: 1;
    // --clip_path: circle(75% at 50% 50%);
    --clip_path: inset(0 0 0 0 round 40px);
  }

  inline-size: fit-content;
  margin-block: 0;
  padding: 3px 15px;
  font-size: var(--font_size);
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
  border-radius: 100vmax;
  background-color: var(--bg_color);
  opacity: var(--opacity);
  clip-path: var(--clip_path);
  transition: opacity var(--duration) $easeOutCubic var(--delay),
    clip-path var(--duration) $easeInOutQuart var(--delay);

  @include mq-up(768) {
    padding: 3px 20px;
  }

  &[data-line-mobile="2"] {
    @include mq-down(768) {
      padding: 6px 24px;
    }
  }
}
