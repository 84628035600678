
.c_dlBtn {
  background-color: $color02;
  color: #fff;
  font-weight: bold;
  width: 100%;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: none;
  border: none;
  font-size: 14px;
  text-decoration: none;
  transition: background-color .3s;

  @include pc {
    width: 207px;
  }

  &:after {
    content: "";
    display: inline-block;
    width: 12.64px;
    height: 11.55px;
    background-image: url("../img/ico_download.svg");
    background-size: contain;
  }

  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: rgba($color02,.7);
  }
}