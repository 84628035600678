// -----------------------------------------------------------------
.p-career_talk_hero {
  position: relative;
  @include mq-down(768) {
    padding-block-start: 60px;
  }

  &__bg {
    display: none;
    @include mq-up(768) {
      position: absolute;
      inset: 0;
      z-index: -1;
      display: block;
    }

    img {
      @include objFitCover;
      object-position: center 10%;
    }
  }

  &__head {
    @include mq-up(768) {
      position: relative;
      block-size: 560px;
      padding-block-start: 125px;
    }
  }

  &__heading {
    position: relative;
    display: block grid;
    row-gap: 40px;
    @include mq-up(768) {
      row-gap: 50px;
    }
  }

  &__body {
    box-sizing: initial;
    max-inline-size: 900px;
    margin-inline: auto;
    padding-inline: 20px;
    margin-block-start: 40px;
    @include mq-up(768) {
      margin-block-start: 60px;
    }
  }

  &__persons {
    display: block grid;
    row-gap: 40px;
    margin-block-start: 60px;
    @include mq-up(768) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 40px;
    }
  }

  &__nav {
    margin-block-start: 70px;
  }
}

// -----------------------------------------------------------------
.p-career_talk_wrap {
  display: block grid;
  row-gap: 70px;
  @include mq-up(768) {
    row-gap: 100px;
  }
}

// -----------------------------------------------------------------
.p-career_talk_section {
  // &__head {
  // }

  &__image {
    margin-block-start: 10px;
    &[data-margin-block-start="50"] {
      margin-block-start: 40px;
      @include mq-up(768) {
        margin-block-start: 50px;
      }
    }
  }

  &__body {
    display: block grid;
    row-gap: 30px;
    margin-block-start: 30px;
    @include mq-up(768) {
      row-gap: 40px;
      margin-block-start: 50px;
    }
  }
}

// -----------------------------------------------------------------
.p-career_talk_summary {
  position: relative;
  margin-block-start: 80px;
  @include mq-up(768) {
    margin-block-start: 130px;
  }
}

// -----------------------------------------------------------------
.p-career_talk_cta {
  display: block grid;
  row-gap: 60px;
  margin-block-start: 80px;
  margin-block-end: 80px;
  margin-inline: -20px;
  @include mq-up(768) {
    margin-block-end: 100px;
    margin-inline: 0;
  }
}
