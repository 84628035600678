/**
 * タニモク
 */

@import url("https://fonts.googleapis.com/css?family=Muli:300,600");

// ==========================================================================
// Foundation
// ==========================================================================

@import "foundation/_sanitize";
@import "foundation/_vars";
@import "foundation/_mixins";
@import "foundation/_base";
@import "foundation/_easing";

// ==========================================================================
// Layout
// ==========================================================================

@import "layout/_wrapper";
@import "layout/_header";
@import "layout/_nav";
@import "layout/_main";
@import "layout/_footer";
@import "layout/_container";

//
@import "layout/_career_wrapper";
@import "layout/_career_header";
@import "layout/_career_main";
@import "layout/_career_footer";
@import "layout/_cta_footer";

// ==========================================================================
// Object
// ==========================================================================

// -----------------------------------------------------------------
// Component
// -----------------------------------------------------------------

// 本文
@import "object/component/_paragraph";
@import "object/component/_list";

// セクション
@import "object/component/_section";

// グリッド
@import "object/component/_grid";

// 見出し
@import "object/component/_headings";

// コラムボックス
@import "object/component/_colbox";

// ボタン
@import "object/component/_button";

// リンク
@import "object/component/_link";

// フォームパーツ
@import "object/component/_form";

// その他
@import "object/component/_galleryBox";
@import "object/component/_localNav";
@import "object/component/_toNote";
@import "object/component/_bdbox";
@import "object/component/_labBtn";
@import "object/component/_labLink";
@import "object/component/_new";
@import "object/component/_dlBtn";
@import "object/component/_section-ttl";
@import "object/component/_movie";

// career
@import "object/component/_career_about";
@import "object/component/_career_button";
@import "object/component/_career_comment";
@import "object/component/_career_cta";
@import "object/component/_career_dots";
@import "object/component/_career_fade";
@import "object/component/_career_hdg";
@import "object/component/_career_header";
@import "object/component/_career_index";
@import "object/component/_career_list";
@import "object/component/_career_knowledge";
@import "object/component/_career_paragraph";
@import "object/component/_career_round_label";
@import "object/component/_career_talk";
@import "object/component/_career_text";
@import "object/component/_career_view_all";

// -----------------------------------------------------------------
// Project
// -----------------------------------------------------------------

@import "object/project/_index";
@import "object/project/_manual";
@import "object/project/_event";
@import "object/project/_lc";
@import "object/project/_ninkai";
@import "object/project/_faq";
@import "object/project/_case";
@import "object/project/_fanmeeting";
@import "object/project/_fes2021";
@import "object/project/_entry";
@import "object/project/_loop-box";

@import "object/project/corporate";
@import "object/project/_form";
@import "object/project/_lab";
@import "object/project/_labHeader";
@import "object/project/_labFooter";
@import "object/project/_labTable";
@import "object/project/_labFaq";

@import "object/project/_terms";
@import "object/project/_download";
@import "object/project/_fixed-button";

// career
@import "object/project/_career_index";
@import "object/project/_career_about";
@import "object/project/_career_message";
@import "object/project/_career_talk";
@import "object/project/_career_knowledge";
@import "object/project/_career_anchor";
@import "object/project/_career_cta";
@import "object/project/_career_shapes";

// -----------------------------------------------------------------
// Utility
// -----------------------------------------------------------------

@import "object/utility/_width";
@import "object/utility/_align";
@import "object/utility/_margin";
@import "object/utility/_text";
@import "object/utility/_br";
@import "object/utility/_only";
@import "object/utility/_display";
