// -----------------------------------------------------------------
.c-career_header_trigger {
  position: relative;
  z-index: 2;
  inline-size: var(--header-block-size);
  aspect-ratio: 1;
  justify-self: end;
  padding: 0;
  background-color: #000;
  border: none;
  outline: none;
  cursor: pointer;
  @include noOutlineWithoutFocus;
  @include mq-up(1024) {
    display: none;
  }

  .__ico {
    display: block;
    position: absolute;
    inset: 0;
    width: 17px;
    height: 17px;
    margin: auto;
    b {
      position: absolute;
      display: block;
      width: 17px;
      height: 3px;
      border-radius: 2px;
      background-color: #fff;
      transition: transform 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
        opacity 150ms;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 7px;
      }
      &:nth-child(3) {
        bottom: 0;
      }
      .is-show_mobile_menu & {
        &:nth-child(1) {
          transform: translateY(7px) rotate(225deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translateY(-7px) rotate(-225deg);
        }
      }
    }
  }
  .__txt {
    display: none;
  }
}

// -----------------------------------------------------------------
.c-career_header_menu_line {
  --inline_size: 100px;
  --inset_inline_start: 0;
  --inset_block_end: 0;
  --opacity: 0;

  @include mq-up(1280) {
    --inset_block_end: 10px;
  }

  @include mq-up(1024) {
    position: absolute;
    inset-inline-start: var(--inset_inline_start);
    inset-block-end: var(--inset_block_end);
    inline-size: var(--inline_size);
    block-size: 2px;
    border-radius: 999vmax;
    background-color: #67beca;
    opacity: var(--opacity);
    pointer-events: none;
    transition: inline-size 0.45s $easeOutQuart,
      inset-inline-start 0.45s $easeOutQuart, opacity 0.45s $easeOutQuart;
  }
}
