.p-entry {
  position: relative;
  .c_btn{
    @include sp {
      width: 100%;
    }

    >span {
      font-size: 20px;

      @include sp {
        font-size: 16px;
      }
    }

    .__label {
      border: 1px solid rgba(#fff, 0.5);
      width: 80px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border-radius: 12px;
      margin-left: 15px;
      letter-spacing: .05em;
    }
  }

  &__hero {
    height: 566px;
    width: 100%;
    padding: 0 20px;
    background-image: url("../img/entry/mv01@pc.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include sp {
      background-image: url("../img/entry/mv01.jpg");
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(#000, .2);
      width: 100%;
      height: 100%;
    }
  }

  &__ttl-box {
    position: relative;
    background-color: #fff;
    max-width: 523px;
    width: 100%;
    padding: 48px 32px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include sp {
      padding: 40px 32px 24px;
    }
  }

  &__comments {
    position: absolute;
    top: -86px;
    left: 70px;
    width: 350px;

    @include sp {
      width: 326px;
      top: -80px;
      left: 50%;
      transform: translateX(-50%);
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__ttl {
    width: 100%;
    max-width: 412px;
    margin: 0;
  }

  &__intro {
    padding-bottom: 135px;

    @include sp {
      padding-bottom: 80px;
    }
  }

  &__dl {
    font-size: 13px;
    margin-top: 40px;

    @include sp {
      font-size: 11px;
      margin-top: 16px;
    }

    >div {
      letter-spacing: .03em;
      text-align: center;
      font-weight: bold;

      @include sp {
        font-size: 11px;
      }

      dt, dd {
        display: inline;
      }
    }
  }

  &__intro-btn-wrap {
    margin-top: -30px;
    text-align: center;
  }

  &__section__inner {
    max-width: 1070px;
    margin: 0 auto;
  }

  &__intro-lead {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-top: 32px;
    margin-bottom: 100px;
    letter-spacing: .07em;

    @include sp {
      font-size: 16px;
      line-height: calc(36/16);
      margin-bottom: 48px;
    }
  }

  &__intro-list-wrap {
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 210px;
      left: 0;
      background-color: $color03;
      height: calc(100% - 185px - 250px);
      width: 100%;

      @include sp {
        top: 185px;
        height: calc(100% - 185px - 250px);
      }
    }
  }

  &__intro-list {
    display: grid;
    gap: 50px 40px;
    margin-top: 88px;
    grid-template-columns: repeat(2, 1fr);

    @include sp {
      grid-template-columns: auto;
    }

    li {
      border: 3px dotted $color09;
      position: relative;
      border-radius: 10px;
      padding: 50px 40px 40px;
      text-align: center;
      background-color: #fff;

      @include sp {
        padding-left: 16px;
        padding-right: 16px;
      }

      >p {
        color: #fff;
        background-color: $color09;
        font-size: 28px;
        font-weight: 500;
        height: 36px;
        width: 120px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);

        @include sp {
          height: 40px;
          width: 130px;
        }

        img {
          height: 24.5px;
          object-fit: contain;
          margin-top: 4px;
        }
      }

      h3 {
        font-weight: bold;
        font-size: 20px;
        margin: 0;
        height: 94px;

        @include sp {
          height: auto;
          margin-bottom: 16px;
        }
      }
      >div {
        font-size: 16px;

        img {
          max-width: 308px;
          width: 100%;
          margin-top: 16px;

          @include sp {
            max-width: 260px;
          }
        }
      }
    }
  }

  &__intro-list-txt-bottom {
    margin-top: 20px;
    line-height: calc(28/16);
    font-weight: bold;
  }

  &__intro-list-txt {
    text-align: left;
    max-width: 423px;
    margin: 0 auto;

    @include sp {
      max-width: 260px;
    }
  }

  &__bg-sec {
    background-color: $color03;
    padding-top: 56px;
    padding-bottom: 80px;

    @include sp {
      padding-top: 32px;
      padding-bottom: 40px;
    }

    &.--merit {
      &.p-loop-box:after {
        height: 300px;
        bottom: 80px;
      }

      .p-loop-box:after {
        @include sp {
          bottom: 20px;
          height: 208px;
          width: calc(100% + 20px * 2);
        }
      }
    }

    &.--comment {
      padding-bottom: 160px;

      @include sp {
        padding-bottom: 80px;
      }

      .p-loop-box:after {
        height: 300px;
        bottom: -80px;

        @include sp {
          bottom: 150px;
          height: 756px;
        }
      }
    }
  }

  &__merit-list {
    display: flex;
    justify-content: space-between;
    max-width: 1040px;
    margin: 0 auto;
    position: relative;

    @include sp {
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }

    li {
      width: calc(100% / 3);

      @include sp {
        width: 100%;
      }

      >div {
        --size: 300px;
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        margin: 0 auto;
        color: #fff;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-weight: bold;
        position: relative;
        z-index: 1;
        line-height: calc(38/22);

        @include sp {
          --size: 250px;
          font-size: 19px;
        }
      }

      &:first-child>div {
        background-color: $color11;
      }

      &:nth-child(2)>div {
        background-color: $color10;
      }

      &:last-child>div {
        background-color: $color08;
      }
    }

    .p-loop-box:after {
      width: 100%;
      height: 100%;
    }
  }

  &__recommend {
    padding-top: 100px;

    @include sp {
      padding-top: 64px;
    }
  }

  &__recommend-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @include sp {
      grid-template-columns: auto;
      gap: 90px;
    }

    li {
      display: flex;
      text-align: center;
      flex-direction: column;
      background-color: $color09;
      border-radius: 10px;
      padding: 34px 16px 22px;

      img {
        width: 190px;
        height: 190px;
        border-radius: 50%;
      }

      >div {
        margin-top: 16px;

        @include sp {
          margin-top: 24px;
          margin-bottom: -75px;
        }
      }
    }

    h3 {
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      line-height: calc(32/18);
      flex-grow: 1;
      margin: 0;
    }
  }

  &__data {
    padding-top: 90px;
    padding-bottom: 120px;

    @include sp {
      padding-top: 160px;
      padding-bottom: 48px;
    }
  }

  &__data-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "a b" "c c";
    gap: 20px;

    @include sp {
      gap: 30px;
      grid-template-columns: auto;
      grid-template-areas: "a" "b" "c";
    }

    >div {
      text-align: center;
      padding: 24px;
      background-color: #fff;
      border-radius: 10px;
      filter: drop-shadow(5px 8px 8px rgba(0,0,0,0.12));

      @include sp {
        padding-top: 16px;
        padding-bottom: 40px;
      }

      img {
        margin-top: 16px;
        width: 100%;
      }

      &:nth-of-type(1) {
        grid-area: a;
      }
      &:nth-of-type(2) {
        grid-area: b;
      }
      &:nth-of-type(3) {
        grid-area: c;
      }

      dt {
        font-weight: bold;
        font-size: 22px;
      }

      dd {
        @include sp {
          margin-top: -16px;
        }
      }
    }
  }

  &__data-bottom-txt {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 48px;

    @include sp {
      font-size: 18px;
      margin-top: 32px;
    }
  }

  &__comment-list {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @include sp {
      grid-template-columns: auto;
    }

    li {
      z-index: 1;
      font-size: 16px;
      line-height: calc(28/16);
      background-color: #fff;
      padding: 32px 28px;
      border: 6px solid #C0ADD1;

      &:before {
        content: "";
        display: block;
        margin: 0 auto 16px;
        width: 38.75px;
        height: 51.56px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../../assets/img/entry/ico_comment.svg");
      }
    }
  }

  &__event {
    padding-top: 100px;
    padding-bottom: 132px;

    @include sp {
      padding-top: 64px;
      padding-bottom: 80px;
    }
  }


  &__event-box {
    background-color: $color03;
    padding: 40px 24px;
    border-radius: 10px;

    @include sp {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }

  &__event-box-inner {
    text-align: center;
    max-width: 866px;
    margin: 0 auto;

    >h3 {
      font-weight: bold;
      font-size: 20px;
      margin: 0;

      @include sp {
        font-size: 17px;
      }
    }

    >p {
      margin-top: 10px;

      @include sp {
        text-align: left;
      }
    }

    >img {
      margin-top: 12px;
      filter: drop-shadow(5px 8px 8px rgba(0,0,0,0.12));
    }
  }

  &__contact {
    background-color: #eee;
    text-align: center;
    font-size: 16px;
    overflow: hidden;

    .p-loop-box {
      padding: 70px 0 100px;

      @include sp {
        padding: 56px 0;
      }
    }

    .p-loop-box:after {
      height: 300px;
      bottom: 0;

      @include sp {
        height: calc(100% - 20px);
      }
    }
  }

  &__contact-txt {
    margin: 0;
    font-size: 16px;

    @include sp {
      font-size: 15px;
    }
  }

  &__contact-txt, &__contact-btn {
    z-index: 1;
    position: relative;
  }

  &__contact-btn {
    margin-top: 32px;
  }

  &__fixed-btn-area {
    position: fixed;
    padding: 10px 20px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $light_black;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;

    @include sp {
      height: auto;
    }

    &.is-visible {
      opacity: 1;
      pointer-events: auto;
    }
  }
}